button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-weight:normal;
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }
  input,
textarea {
  font: inherit;
  letter-spacing: inherit;
  word-spacing: inherit; }
  .gr-input {
    display: block;
    margin: 4px 0;
    padding: 0 12px;
    height: 44px;
    color: var(--color-text-light);
    border: 1px solid var(--color-border);
    background: var(--color-white);
    border-radius: var(--input-corner, 4px);
    font-size: 1rem;
    width: 100%;
    max-width: 100%; }
  
  .gr-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-input:disabled {
    color: var(--color-border);
    background-color: var(--color-light-grey); }
  
  .gr-input:read-only {
    border: 0; }
  

  .gr-input + .error-state, .gr-textarea + .error-state, .gr-checkbox + .error-state, .gr-radio + .error-state, .gr-select-container + .error-state, .gr-select-container + .error-state {
    display: none; }
  
  .gr-input.error, .gr-textarea.error, .gr-checkbox.error, .gr-radio.error, .gr-select-container.error, .gr-select-container.error {
    display: flex;
    align-items: center;
    border-color: #DB0020; }
    .gr-input.error + .error-state, .gr-textarea.error + .error-state, .gr-checkbox.error + .error-state, .gr-radio.error + .error-state, .gr-select-container.error + .error-state, .gr-select-container.error + .error-state {
      display: flex;
      align-items: center; }
      .gr-input.error + .error-state svg, .gr-textarea.error + .error-state svg, .gr-checkbox.error + .error-state svg, .gr-radio.error + .error-state svg, .gr-select-container.error + .error-state svg, .gr-select-container.error + .error-state svg {
        fill: #DB0020; }
      .gr-input.error + .error-state span, .gr-textarea.error + .error-state span, .gr-checkbox.error + .error-state span, .gr-radio.error + .error-state span, .gr-select-container.error + .error-state span, .gr-select-container.error + .error-state span {
        margin-left: 8px;
        color: #DB0020; }
  
  .gr-textarea {
    display: block;
    margin: 4px 0;
    padding: 12px;
    color: var(--color-text-light);
    border: 1px solid var(--color-border);
    background: var(--color-white);
    border-radius: var(--input-corner);
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    min-height:80px;
    line-height: 1.5; }

  
  .gr-textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-textarea:disabled {
    color: var(--color-border);
    background-color: #E9E9E9; }
  
  .gr-textarea:read-only {
    border: 0; }
  
  .gr-checkbox {
    margin-bottom: 14px;
    min-height: 16px;
    position: relative;
    display: flex;
    align-items: center; }
  
  .gr-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    cursor: pointer; }
  
  .gr-checkbox input[type=checkbox] ~ label {
    display: inline-block;
    line-height: 1.5;
    min-height: 24px;
    padding-left: 2.5em;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-size: 14px; }
  
  .gr-checkbox input[type=checkbox] ~ span {
    height: 22px;
    line-height: 1.5;
    text-align: center;
    width: 22px;
    border: 2px solid var(--color-border);
    background: var(--color-white);
    border-radius: 2px;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 0; }
  
  .gr-checkbox input[type=checkbox]:disabled ~ span {
    height: 22px;
    line-height: 1.5;
    text-align: center;
    width: 22px;
    border: 2px solid var(--color-border);
    border-radius: 2px;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 0;
    background-color: #E9E9E9; }
  
  .gr-checkbox input[type=checkbox]:focus ~ span {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-checkbox input[type=checkbox] ~ span svg {
    height: 24px;
    opacity: 0;
    width: 24px;
    top: -3px;
    position: relative;
    left: -3px;
    fill: #007FA3; }
  
  .gr-checkbox input[type=checkbox]:disabled ~ span svg {
    fill: var(--color-border); }
  
  .gr-checkbox input[type=checkbox]:checked ~ span svg {
    opacity: 1; }
  
  .gr-fieldset {
    border: none;
    margin:0;
    padding: 0; }
  
  .gr-fieldset legend, .legend {
    // font-family: "TT Commons", sans-serif;
    padding: 0px;
    position: relative;
    color: rgb(2, 9, 23);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-bottom: 8px;
   }
   .recommended {
    margin-top:8px;
   }
  .recommended .secondary-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.24px;
    color: var(--text-muted, #60646D);
    margin-bottom:8px;

  }
  .recommended {
   
  }
  .chip-list {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    gap:4px;
    // margin-left: -4px;
    // margin-right:-4px;
    
    .chip-btn {
      margin: 0 4px;
    }
  }
  .chip-btn {
    display:inline-flex;
    gap:8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid transparent;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.10);
    padding: 6px 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    color:var(--text-default);
    margin: 0 4px;
    text-decoration:none;
    &button {
      cursor: pointer;
    }
    &.search-filter-chip {
      padding: 4px 8px;
      font-size:14px;
      line-height:24px;
      font-weight:600;
      color:#60646D;
      gap:4px;
      border-radius:4px;
      &.with-remove {
        padding: 4px 4px 4px 8px;
      }
      .chip-btn-toggle-text {
        background:transparent;
        border:none;
        padding:0 4px;
        margin:0;
        font-size:14px;
      line-height:24px;
      font-weight:600;
      color:#60646D;
      border-radius:4px;
      // &:active {
      //   text-decoration: none;
      // }
        &:hover {
          text-decoration: underline;
        }
        
      }
      .icon-btn {
        min-width:24px;
        min-height:24px;
        color:#60646D;
        &:hover, &:focus {
          background: #02091714;

        }
      }
      &.selected {
        background-color: #05112A;
        border-color: #05112A;
        color:var(--color-white);
        font-weight:700;
        .chip-btn-toggle-text {
          color:var(--color-white);
          font-weight:700;
        }
        .icon-btn {
          color:var(--color-white);
          &:hover, &:focus {
            background: #FEFEFE33;
          }
        }
      }

    }
    
    &.dark {
      background: rgba(255, 255, 255, 0.10);
      color:#FEFEFE;
      // border-color:var(--background-dark-theme-bg-level-2, #272D39);

      button, span {
        //remove btn
        color:#FEFEFE;
        
      }
      svg {
        vertical-align: text-bottom;

      }
      &:hover, &:focus {
        background:#DDE3EE;
        color:#020917;
        span {
          color:#020917;
        }
      }
    }
    &:hover, &:focus {
      background:#DDE3EE;
      color:#020917;
      span {
        color:#020917;
      }
    }
    &[aria-pressed=true], &.selected {
      // background: #CAA0FF;
      background:#FEFEFE;
      color:#020917;
    }
}
  .gr-radio {
    margin-bottom: 14px;
    min-height: 16px;
    position: relative;
    display: flex;
    align-items: center; }
  
  .gr-radio input[type=radio] {
    opacity: 0;
    position: absolute; }
  
  .gr-radio input[type=radio]:focus ~ span {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-radio input[type=radio] + label {
    display: inline-block;
    line-height: 18px;
    padding-left: 28px;
    font-size: 14px;
    cursor: pointer; }
  
  .gr-radio input[type=radio] ~ span {
    -webkit-box-sizing: content-box;
    border: 2px solid var(--checkbox-border);
    background: var(--color-white);
    border-radius: 50%;
    box-sizing: content-box;
    color: var(--checkbox-checked);
    display: block;
    height: 5px;
    left: 0;
    padding: 3px 6px 6px 3px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 5px; }
  
  .gr-radio input[type=radio] ~ span svg {
    height: 18px;
    opacity: 0;
    width: 18px; }
  
  .gr-radio input[type=radio]:checked ~ span svg {
    opacity: 1;
    top: -5px;
    position: relative;
    left: -5px;
    fill: var(--checkbox-checked); }
  
  .gr-radio input[type=radio]:disabled ~ span svg {
    opacity: 1;
    fill: var(--color-border);
    top: -5px;
    left: -5px;
    position: relative; }

    .radio-option {
      position:relative;
      background: rgba(255, 255, 255, 0.10);
      // padding: 16px;
      margin-top: 16px;
      border-radius:8px;
      text-align:left;

      &:first-of-type {
        margin-top:0;
      }
      input[type=radio] {
          opacity:0;
          position:absolute;
          ~ span {
              border: 2px solid rgba(255, 255, 255, 0.40);
              background: transparent;
              border-radius: 50%;
              // box-sizing: content-box;
              color: #E5067C;
              display: block;
              height: 18px;
              width: 18px;
              // padding:2px;
              pointer-events: none;
              position: absolute;
              top: 18px;
              left: 16px;

              svg {
                  position:absolute;
                  left:-2px;
                  top:-2px;
                  fill:currentColor;
                  opacity:0;
              }
          }
          &:focus ~ span {
              outline: 1px solid var(--color-white);
              outline-offset: 2px;
              // box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #E5067C; 
          }
          &:checked ~ span{
              border-color:#E5067C;
          }
          &:checked ~span svg {
              opacity:1;
          }
          &:hover {
            ~ span {
              &:after {
                content:'';
                width: 36px;
                height:36px;
                background-color: rgba(255, 255, 255, 0.10);
                border-radius:50%;
                position:absolute;
                left:50%;
                top:50%;
                transform:translate(-50%,-50%);
              }
            }
          }
      }
      label {
        font-size: 18px;
        line-height: 24px;
        padding:16px;
        padding-left:48px;
        cursor: pointer;
        width:100%;
        display:block;
    }
  }
  
  
  .gr-select-container {
    position: relative; }
  
  .gr-select-container svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 9px);
    fill: var(--color-text-light); }
    
  .gr-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    margin: 4px 0;
    padding: 0 12px;
    padding-right: 42px;
    height: 44px;
    color: var(--color-text-light);
    border: 1px solid var(--color-border);
    background: var(--color-white);
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;
    max-width: 100%; }
  
  .gr-select:focus {
    outline: 0;
    box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4; }
  
  .gr-select:disabled {
    color: var(--color-border);
    background-color: #E9E9E9; }
  
  .gr-select:disabled + svg {
    fill: var(--color-border); }
  
  .gr-select[multiple] {
    height: auto; }
    .gr-select[multiple] option {
      cursor: pointer; }
  



      /* HED styles */

/* check-box-list.component.less */
.checkbox-group {
  margin-left:8px;
}
.checkbox  {
  padding: 6px 0;
  // &:first-of-type {padding-top:0;}
}
.check-box-list label,
.checkbox label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.24px;
  // color: rgb(2, 9, 23);
  color: var(--text-default);
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
.check-box-list label:not([class*="-inline"]) + label,
.checkbox label:not([class*="-inline"]) + label {
  margin-top: 1rem;
}
.check-box-list label[class*="-inline"],
.checkbox label[class*="-inline"] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 0.625rem 0.625rem 0;
}
.check-box-list label[class*="-inline"]:only-of-type,
.checkbox label[class*="-inline"]:only-of-type,
.check-box-list label[class*="-inline"]:last-of-type,
.checkbox label[class*="-inline"]:last-of-type {
  margin-bottom: 0;
}
.small-controls.check-box-list label,
.small-controls.checkbox label {
  font-size: var(--typesize-minor);
}
.form-inverse .check-box-list label,
.form-inverse .checkbox label {
  color: var(--color-white);
}
.check-box-list [type="checkbox"],
.checkbox [type="checkbox"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 1em;
  height: 1em;
  aspect-ratio: 1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: var(--color-white);
  outline-width: 0.125em;
  outline-style: solid;
  outline-color: var(--checkbox-border);
  margin: 0 0.8125rem 0 0;
  position: relative;
  -webkit-transition: all 0.125s ease-in-out;
  -o-transition: all 0.125s ease-in-out;
  transition: all 0.125s ease-in-out;
  border-radius: 0.125em;
  transform:translate(2px, 2px);
}
.check-box-list [type="checkbox"]::before,
.checkbox [type="checkbox"]::before {
  // content: "";
  display: block;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-color: var(--color-light-300);
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  margin: auto;
  position: absolute;
  z-index: -1;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  outline-color: transparent;
  outline-style: solid;
  outline-width: 0.1875em;
  outline-offset: -0.1875em;
}
.small-controls.check-box-list [type="checkbox"]::before,
.small-controls.checkbox [type="checkbox"]::before {
  width: 2em;
  height: 2em;
}
.form-inverse .check-box-list [type="checkbox"]::before,
.form-inverse .checkbox [type="checkbox"]::before {
  background-color: var(--color--ui-04);
}
.check-box-list [type="checkbox"]:hover:not([disabled]),
.checkbox [type="checkbox"]:hover:not([disabled]) {
  outline-color: var(--text-default);
}
.check-box-list [type="checkbox"]:hover:not([disabled])::before,
.checkbox [type="checkbox"]:hover:not([disabled])::before {
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.check-box-list [type="checkbox"]:focus:not([disabled]),
.checkbox [type="checkbox"]:focus:not([disabled]) {
  outline-color: var(--checkbox-border);
}
.check-box-list [type="checkbox"]:focus:not([disabled])::before,
.checkbox [type="checkbox"]:focus:not([disabled])::before {
  background-color: transparent;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  outline-color: var(--color--interactive-focus-field);
}
.small-controls.check-box-list [type="checkbox"],
.small-controls.checkbox [type="checkbox"] {
  width: 1rem;
  height: 1rem;
  outline-width: 0.125rem;
  outline-offset: -0.125rem;
}
.small-controls.check-box-list [type="checkbox"]:focus,
.small-controls.checkbox [type="checkbox"]:focus,
.small-controls.check-box-list [type="checkbox"]:focus-visible,
.small-controls.checkbox [type="checkbox"]:focus-visible {
  outline-offset: -0.125rem;
}
.check-box-list [type="checkbox"]:checked,
.checkbox [type="checkbox"]:checked {
  outline-color: var(--checkbox-checked);
}
.check-box-list [type="checkbox"]:checked:hover,
.checkbox [type="checkbox"]:checked:hover {
  outline-color: var(--checkbox-border);
}
.check-box-list [type="checkbox"]:focus,
.checkbox [type="checkbox"]:focus,
.check-box-list [type="checkbox"]:focus-visible,
.checkbox [type="checkbox"]:focus-visible {
  outline-color: var(--color--interactive-focus-field);
  outline-offset: -0.0525em;
}
.check-box-list [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
.checkbox [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
.has-error.check-box-list [type="checkbox"],
.has-error.checkbox [type="checkbox"],
.has-error .check-box-list [type="checkbox"],
.has-error .checkbox [type="checkbox"] {
  outline-color: var(--color--alert-error);
}
.check-box-list [type="checkbox"]:focus,
.checkbox [type="checkbox"]:focus {
  outline-offset: unset;
}
.check-box-list [type="checkbox"]:checked:not([disabled]),
.checkbox [type="checkbox"]:checked:not([disabled]) {
  background: var(--checkbox-checked);
}
.has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
.has-error.checkbox [type="checkbox"]:checked:not([disabled]),
.has-error .check-box-list [type="checkbox"]:checked:not([disabled]),
.has-error .checkbox [type="checkbox"]:checked:not([disabled]) {
  background: var(--color--alert-error);
}
.form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
.form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]),
.form-inverse .has-error .check-box-list [type="checkbox"]:checked:not([disabled]),
.form-inverse .has-error .checkbox [type="checkbox"]:checked:not([disabled]) {
  background: var(--color--alert-error-inverse);
}
.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.checkbox [type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--checkbox-checked);
}
.has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus,
.has-error .check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.has-error .checkbox [type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error);
}
.form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus,
.form-inverse .has-error .check-box-list [type="checkbox"]:checked:not([disabled]):focus,
.form-inverse .has-error .checkbox [type="checkbox"]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error-inverse);
}
.check-box-list [type="checkbox"]:checked::after,
.checkbox [type="checkbox"]:checked::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  // background: currentColor;
  width: 14px;
  aspect-ratio: 1;
  -webkit-mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  width: 0.875em;
  background: var(--color-white);
  margin: auto;
  position: absolute;
}
.check-box-list [type="checkbox"][disabled],
.checkbox [type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none;
}
.check-box-list [type="checkbox"][disabled]::-webkit-input-placeholder, .checkbox [type="checkbox"][disabled]::-webkit-input-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]::-moz-placeholder, .checkbox [type="checkbox"][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]:-ms-input-placeholder, .checkbox [type="checkbox"][disabled]:-ms-input-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]::-ms-input-placeholder, .checkbox [type="checkbox"][disabled]::-ms-input-placeholder {
  color: var(--color--interactive-disabled-02);
}
.check-box-list [type="checkbox"][disabled]::placeholder,
.checkbox [type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled],
.form-inverse .checkbox [type="checkbox"][disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::-webkit-input-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]::-webkit-input-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::-moz-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]:-ms-input-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]:-ms-input-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::-ms-input-placeholder, .form-inverse .checkbox [type="checkbox"][disabled]::-ms-input-placeholder {
  color: var(--color--interactive-disabled-01);
}
.form-inverse .check-box-list [type="checkbox"][disabled]::placeholder,
.form-inverse .checkbox [type="checkbox"][disabled]::placeholder {
  color: var(--color--interactive-disabled-01);
}
.check-box-list [type="checkbox"][disabled]:checked,
.checkbox [type="checkbox"][disabled]:checked {
  outline-color: var(--color--ui-01);
}
.check-box-list [type="checkbox"][disabled]:checked::after,
.checkbox [type="checkbox"][disabled]:checked::after {
  background-color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled],
.form-inverse .checkbox [type="checkbox"][disabled] {
  outline-color: var(--color--interactive-disabled-03);
}
.form-inverse .check-box-list [type="checkbox"][disabled]:checked,
.form-inverse .checkbox [type="checkbox"][disabled]:checked {
  outline-color: var(--color--interactive-disabled-02);
}
.form-inverse .check-box-list [type="checkbox"][disabled]:checked::after,
.form-inverse .checkbox [type="checkbox"][disabled]:checked::after {
  background: var(--color--interactive-disabled-03);
}
.small-controls.check-box-list [type="checkbox"]:checked::after,
.small-controls.checkbox [type="checkbox"]:checked::after {
  width: 0.75rem;
  height: 0.75rem;
}
@supports selector(:focus-visible) {
  .check-box-list [type="checkbox"],
  .checkbox [type="checkbox"] {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    outline-color: var(--checkbox-border);
  }
  .check-box-list [type="checkbox"]:hover:not([disabled]),
  .checkbox [type="checkbox"]:hover:not([disabled]) {
    outline-color: var(--text-default);
  }
  .check-box-list [type="checkbox"]:focus:not([disabled]),
  .checkbox [type="checkbox"]:focus:not([disabled]) {
    outline-color: var(--color--border-02);
  }
  .check-box-list [type="checkbox"]:focus:not([disabled])::before,
  .checkbox [type="checkbox"]:focus:not([disabled])::before {
    border-style: solid;
    border-color: transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .check-box-list [type="checkbox"]:checked:not([disabled]),
  .checkbox [type="checkbox"]:checked:not([disabled]) {
    outline-color: var(--checkbox-checked);
  }
  .has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
  .has-error.checkbox [type="checkbox"]:checked:not([disabled]) {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]),
  .form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]) {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type="checkbox"]:checked:not([disabled]):focus,
  .checkbox [type="checkbox"]:checked:not([disabled]):focus {
    outline-color: var(--checkbox-checked);
  }
  .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
  .has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):focus,
  .form-inverse .has-error.checkbox [type="checkbox"]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type="checkbox"]:checked:not([disabled]):hover,
  .checkbox [type="checkbox"]:checked:not([disabled]):hover {
    outline-color: var(--color--interactive-primary);
  }
  .has-error.check-box-list [type="checkbox"]:checked:not([disabled]):hover,
  .has-error.checkbox [type="checkbox"]:checked:not([disabled]):hover,
  .has-error .check-box-list [type="checkbox"]:checked:not([disabled]):hover,
  .has-error .checkbox [type="checkbox"]:checked:not([disabled]):hover {
    outline-color: var(--color--alert-error);
  }
  .check-box-list [type="checkbox"][disabled],
  .checkbox [type="checkbox"][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-02);
  }
  .form-inverse .check-box-list [type="checkbox"][disabled],
  .form-inverse .checkbox [type="checkbox"][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-03);
  }
  .small-controls.check-box-list [type="checkbox"],
  .small-controls.checkbox [type="checkbox"] {
    width: 1rem;
    height: 1rem;
    outline: unset;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-width: 0.14285714em;
  }
  .small-controls.check-box-list [type="checkbox"]:focus,
  .small-controls.checkbox [type="checkbox"]:focus,
  .small-controls.check-box-list [type="checkbox"]:focus-visible,
  .small-controls.checkbox [type="checkbox"]:focus-visible {
    outline: unset;
  }
  .small-controls.check-box-list [type="checkbox"]:checked::after,
  .small-controls.checkbox [type="checkbox"]:checked::after {
    -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
            transform: scale(1.15);
  }
  .check-box-list [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
  .checkbox [type="checkbox"][required][aria-checked="false"][aria-invalid="false"],
  .has-error.check-box-list [type="checkbox"],
  .has-error.checkbox [type="checkbox"],
  .has-error .check-box-list [type="checkbox"],
  .has-error .checkbox [type="checkbox"] {
    border-color: var(--color--alert-error);
  }
}

.filter-menu {
  position: relative;
  z-index: 12;
  button {
    border-radius:8px;
    padding: 4px 12px;
  }

  button[aria-expanded=true] {
    background-color: black;
    color:white;
   
  }

  .menu {
    position:absolute;
    z-index: 1111;
    right:0;
    top:calc(100% + 4px);
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding:24px;
    box-shadow: 1px 2px 5px rgba(0,0,0,.2);
    min-width:200px;

    .form-input {
      margin: 1em 0;
    }
  }
  
  &.career-fields-dropdown {
    width:100%;
    text-align:right;

    > .button {
      margin-left:auto;
    }
    .menu {
      // left:0;
      right:0;
      max-height:300px;
      overflow-y:auto;
      display:flex;
      flex-direction: column;
      align-items: flex-start;
      gap:8px;
      width:100%;
      max-width:calc(100vw - 80px);
      button {text-align: left;}
    }
  }
}
.dropdown {
  position: relative;
  z-index: 12;

  &.open {
    z-index: 13;
  }
  
  .menu {
    position:absolute;
    z-index: 1111;
    left:0;
    top:calc(100% + 6px);
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 4px;
    padding:16px;
    // box-shadow: 1px 2px 5px rgba(0,0,0,.2);
    box-shadow: 0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033;

    min-width:200px;
    color: var(--text-default);
    font-size:18px;

    span, label {
      font-size:1rem;
    }
    .form-input {
      margin: 1em 0;
    }
    .menu-footer {
      display:flex;
      align-items: center;
      justify-content: center;
      gap:12px;
      margin-top:24px;
      // background: #F7F9FD;


      button {
        white-space: nowrap;
      }
    }
  }
}
.search-container, .search-input {
  position: relative;
  width:100%;

  .gr-input {
    border: 1px solid var(--color-border);
    color: var(--text-default);
    border-radius:24px;
    padding: 8px 16px 8px 44px;
    max-width:490px;
    height:44px;
    // &::placeholder {
    //   color: var(--text-default);
    // }
    &::placeholder {
      color: var(--text-muted);
    }
  }
  .search-icon {
    position:absolute;
    color: var(--text-default);
    top:50%;
    left:12px;
    transform: translateY(-50%);
    height:24px;
    width:24px;
    
  }
  .clear-icon {
    position:absolute;
    color: var(--text-default);
    top:50%;
    right:12px;
    transform: translateY(-50%);
    height:24px;
    width:24px;
  }

}

.autocomplete-container {
  position:relative;
  .gr-input {
    max-width:100%;
    text-overflow: ellipsis;
  padding-right: 48px;

  }
} 
.autocomplete-list {
  position:absolute;
  top:calc(100% + 2px);
  left:0;
  right:0;
  background:white;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  z-index: 111;
  max-height:280px;
  overflow-y:auto;
  ul {
    list-style: none;
    margin:0;
    padding:0;

    button {
      padding: 16px;
      text-align:left;
      text-transform: capitalize;
      background:transparent;
      border:none;
      width:100%;
      color:var(--text-default);
      &:hover, &:focus {
        background: var(--bg-level2);
      }
    }
    li {
      &:not(:last-of-type) {
        border-bottom:1px solid var(--color-border);
      }
    }
  }
}
.company-list {
  max-width:570px;
  width:100%;
  .autocomplete-list {
    button {
      text-transform: uppercase;
    }
  }
}
.form-input.password {
  position:relative;

  .show-password {
    position:absolute;
    right:12px;
    bottom:14px;
  }
  input {
    padding-right:40px;
  }
}

.slider-container {
  position:relative;
  padding-bottom:0.5em;
  width:100%;
  label {
    display:block;
    font-size:1.125rem !important;
    margin-bottom:4px;
  }
  .slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width:100%;
    height: 4px; /* Specified height */
    background: #B2B6BF; /* Grey background */
    outline: none; /* Remove outline */
    border-radius:4px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 16px; /* Set a specific slider handle width */
      height: 16px; /* Slider handle height */
      // background: #342C47; /* Green background */
      border-radius: 24px;
      border: 1px solid var(--Swatch-Light-600, #BCC1CB);
      background: var(--background-light-theme-bg-level-0, #FEFEFE);
      cursor: pointer; /* Cursor on hover */
    }
    &::-moz-range-thumb {
      width: 16px; /* Set a specific slider handle width */
      height: 16px; /* Slider handle height */
      // background: #342C47; /* Green background */
      border-radius: 24px;
      border: 1px solid var(--Swatch-Light-600, #BCC1CB);
      background: var(--background-light-theme-bg-level-0, #FEFEFE);
      cursor: pointer; /* Cursor on hover */
    }
    
//#342C47
  }
  .min {
    position:absolute;
    bottom:0;
    left:0;
    font-size:1rem;
  }
  .max {
    position:absolute;
    bottom:0;
    right:0;
    font-size:1rem;
  }
}