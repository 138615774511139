/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/
button {
  cursor: pointer;
}
button[disabled] {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  background-color: inherit;
}
.button {
  display:flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: "TT Commons", sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  background:transparent;
  border:none;
  position: relative;
  // margin:2px;
  border-radius:50px;
  transition:background 0.3s ease;
  text-decoration:none;
  
  &.large {
    min-height:56px;
    min-width:56px;
    padding: 8px 40px;
    font-size: 24px;
    line-height: 40px; /* 166.667% */
    gap: 12px;
    &.icon-left {
      padding-left: 32px;
    }
    &.icon-right {
      padding-right: 32px;
    }
    svg {
      width:32px;
      height:32px;
    }
  }
  &.medium {
    min-height:48px;
    min-width:48px;
    padding: 8px 32px;
    font-size: 20px;
    line-height: 32px; /* 160% */
    gap:12px;
    border-radius:24px;
    &.icon-left {
      padding-left: 24px;
    }
    &.icon-right {
      padding-right: 24px;
    }
    svg {
      width:24px;
      height:24px;
    }
  }
  &.small {
    min-height:40px;
    min-width:40px;
    padding: 8px 24px;
    font-size:18px;
    line-height: 18px;
    gap:8px;
    &.icon-left {
      padding-left: 16px;
    }
    &.icon-right {
      padding-right: 16px;
    }
    svg {
      width:16px;
      height:16px;
    }
  }
  &.xsmall {
    min-height:32px;
    min-width:32px;
    padding: 8px 16px;
    font-size:14px;
    line-height: 16px;
    gap:4px;
    &.icon-left {
      padding-left: 12px;
    }
    &.icon-right {
      padding-right: 12px;
    }
    svg {
      width:16px;
      height:16px;
    }
  }
  &.icon-btn {
    padding:0;
    background-color: transparent;
    min-width:24px;
    min-height:24px;
    // width:56px;
    // height:56px;
    &.white {
      color:var(--color-white);
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
    
  }
  &:disabled {
    opacity:0.32;
  }
  
  // Colors
  &.primary {
    background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
    color: var(--color-white);
    &:not(:disabled):hover {
      background:linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
    }
    &:not(:disabled):focus {
      outline:0;
      // margin:-2px;
      background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
      
      box-shadow: 0px 0px 0px 2px #B41863;
      &:after {
        position:absolute;
        content:'';
        left:0px;
        top:0px;
        width:calc(100% - 4px);
        height:calc(100% - 4px);
        border: 2px dashed var(--color-border, #DDE3EE);
        border-radius:50px;
      }
    }
  }
  &.light {
    &.filled {
      color: var(--swatch-dark-800, #272D39);
      background-color: var(--color-white, #fefefe);
      &:not(:disabled):hover {
        // background: var(--swatch-dark-700, #393F4A);
        color: var(--color-white, #fefefe);
       
      }
    }
    &.outline {
      // color: var(--swatch-light-400, #D2D7E2);
      // outline: 2px solid var(--swatch-light-400, #D2D7E2)
      outline: 2px solid rgba(255, 255, 255, 0.25);
      outline-offset:-2px;
      // color: var(--swatch-dark-800, #272D39);
      color: var(--color-white, #fefefe);
      background:transparent;
      // placeholder
      &:not(:disabled):hover {
        background: var(--swatch-dark-700, #393F4A);
        color: var(--color-white, #fefefe);
        &.outline {
          outline: 2px solid var(--swatch-dark-700, #393F4A);
        }
      }
      &:not(:disabled):focus {
        background: var(--swatch-dark-700, #393F4A);
        color: var(--color-white, #fefefe);
        box-shadow: 0px 0px 0px 2px #393F4A;
        outline:0;
        &.outline {
          outline: 2px solid var(--swatch-dark-700, #393F4A);
        }
        
        &:after {
          position:absolute;
          content:'';
          left:0px;
          top:0px;
          width:calc(100% - 4px);
          height:calc(100% - 4px);
          border: 2px dashed var(--color-border, #DDE3EE);
          border-radius:50px;
        }
      }
    }
  }
  &.dark {
    &.filled {
      background-color: var(--swatch-dark-800, #272D39);
      color: var(--color-white, #fefefe);
    }
    &.outline {
      color: var(--color-default, #020917);
      outline: 2px solid var(--swatch-dark-800, #272D39);
      outline-offset:-2px;
      
    }
    &.clear {
      color: var(--color-default, #020917);
    }
   
    &:not(:disabled):hover {
      background: var(--swatch-dark-700, #393F4A);
      color: var(--color-white, #fefefe);
      &.outline {
        outline: 2px solid var(--swatch-dark-700, #393F4A);
      }
    }
    &:not(:disabled):focus {
      background: var(--swatch-dark-700, #393F4A);
      color: var(--color-white, #fefefe);
      box-shadow: 0px 0px 0px 2px #393F4A;
      outline:0;
      &.outline {
        outline: 2px solid var(--swatch-dark-700, #393F4A);
      }
      
      &:after {
        position:absolute;
        content:'';
        left:0px;
        top:0px;
        width:calc(100% - 4px);
        height:calc(100% - 4px);
        border: 2px dashed var(--color-border, #DDE3EE);
        border-radius:50px;
      }
    }
  }
}

/*------------------------------------*\
  #UNSTYLED
\*------------------------------------*/
.button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}
.button-unstyled::before {
  display: none;
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
/*------------------------------------*\
  #text button
\*------------------------------------*/
.button-link, .link-btn {
  font-size:18px;
  font-weight:700;
  background-color: transparent;
  border: none;
  color: var(--text-light-theme-link, #655591);
    text-decoration:underline;
    padding:0;
}
.button-link.with-icon, .link-btn.with-icon {
  text-decoration: none;
  font-weight:600;
  white-space: nowrap;
  
  svg {
    margin-right:8px;
  }
}
.button-link svg, .link-btn svg {
  vertical-align: bottom;
}
.button-link:hover, .link-btn:hover {
  color: var(--text-light-theme-link-hover, #4C406B);
    text-decoration:none;
}


.circle-btn {
  width:48px;
  height:48px;
  padding: 12px;
  border-radius:50%;
  background: #e8eefa;
  border: 1px solid var(--color-border);
}

.btn-centered {
  margin: 0 auto;
  width: 100%;
}

a {
  color: var(--text-light-theme-link, #655591);
  text-decoration:underline;
  font-weight: 700;

  &.white {
    color:var(--color-white);
    
  }

}

.slider-nav-button {
  background: #020917;
  border: 1px solid #393F4A;
  color:var(--color-white);
  width:48px;
  height:48px;
  padding:12px;
  border-radius:40px;

  &:hover, &:active {
    box-shadow: 0px 0px 16px 0px #05112A;
    border: 1px solid #F7F9FD;
    background: #05112A;
    // backdrop-filter: blur(12px);
  }
  &:focus {
    border: 1px solid #05112A;
    background: #05112A;
    box-shadow: 0px 0px 16px 0px #05112A;
    // backdrop-filter: blur(12px);
    outline: 2px dashed var(--swatch-light-100, #F7F9FD);
    outline-offset:-2px;
  }

}

.back-to-top {
  position:fixed;
  bottom:40px;
  right:40px;
  background-color: var(--color-dark-blue);
  color:white;
  opacity:0.8;
  border:1px solid white;
  border-radius:8px;
  z-index: 99999;
  width:48px;
  height:48px;
  padding:11px;
  opacity: 0;
  &.show {
    opacity:1;
  }
}

.rating-row {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap:wrap;
  gap:16px;
  // margin:auto;
  margin-top:16px;
  width:100%;
  
  > button {
    // flex: 1 0 120px;
  }
}
.button.rating-btn {
  color: #fff;
  // border: 1px solid #fff;
  // border-radius:16px;
  background: var(--Transparent-fill, rgba(0, 0, 0, 0.25));
  padding: 8px 20px;
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content:center;
  // border-radius: 4px;
// border: 1px solid transparent;
// background: rgba(255, 255, 255, 0.10);
// max-width:120px;
// font-weight:normal;
  gap:12px;

  svg {
    width:16px;
    height:16px;
  }

  &:hover {
    filter: background-blur(100%);
    background: rgba(255,255,255, .1);
    // border: 1px solid #fefefe;
  }
  &.selected, &[aria-checked=true] {
    // border: 1px solid #fefefe;
    // border: 1px solid var(--Text-darkTheme--Link, #9B81E2);
    // outline:2px solid var(--Text-darkTheme--Link, #9B81E2);
    color: var(--swatch-dark-800, #272D39);
      background-color: var(--color-white, #fefefe);

  }

  .icon {
    margin-left:8px; //offset scale
  }
  .icon svg {
    width:24px;
    height:24px;
    // for now
    margin: 0 2px;
    fill: #fff;

    &.thumbs {
      transform:scale(0.75) translate(-5px, 10px);
    }
  }
}

.nav-button {
  // background: var(--purple-bubble-gradient,linear-gradient(218deg, #7041ee, #8d41ee, #d641ee ), radial-gradient(#52b6ffCC,#8f41ee00), radial-gradient(#9dd6ffCC,#8f41ee00), radial-gradient(#5263ffCC,#8f41ee00));
  background:linear-gradient(-218deg, #9dd6ff 0%, #8d41ee00 50% ),linear-gradient(218deg, #7041ee, #8d41ee, #d641ee ), #8d41ee ;
  box-shadow: 0px -1.812px 10.875px 0px rgba(255, 255, 255, 0.24) inset;
  color:var(--color-white);
  width:58px;
  height:58px;
  border-radius: 50%;
  // padding:9px;
  padding:0;
  border:none;
  // border:1px solid transparent;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
  position:relative;
  .active {
    //svg g
    display:none;
  }
  .default {
    filter:drop-shadow(0 4px 4px rgba(0,0,0,0.25));
  }
  svg {
    position: relative;
    z-index: 2;
  }
  img {
    width:54px;
      // height:54px;
    // position: relative;
    z-index: 2;
    position:absolute;
    bottom:2px;
    left:2px;
    right:2px;
    border-radius:50%;
  }

  &.active {
    
    &:before {
      content:'';
      position:absolute;
      background: var(--Color-Gray-White, #FFF);
      border-radius:50%;
      // outline:1px solid #98BBFC;
      // outline-offset:-1px;
      box-shadow: 0px -1.812px 10.875px 0px rgba(255, 255, 255, 0.24) inset;
      // z-index: 0;
      width:54px;
      height:54px;
      margin:2px;
    }
    

    &.achievements {
      // background:#05112A;
    }

    // &:after {
    //   content:'';
    //   position:absolute;
    //   left:-2px;
    //   top:-2px;
    //   right:-2px;
    //   bottom:-2px;
    //   border-radius:50%;
    //   display:block;
    //   z-index: -1;
    //   background:linear-gradient(to bottom, #98bbfc 0%, #804EF1 56%, #a75DF1 100%);
    // }

    .active {
      //svg g
      display:unset;
    }
    .default {display:none;}
  }
}

.achievements-btn {
  background-color: transparent;
  border:none;
  color:var(--color-white);
  font-size:1rem;
  font-weight:700;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  // padding: 2px;
  // border-radius:8px;
  
  &:hover, &:focus {
    // background-color: rgba(254, 254, 254, 0.24);
    svg {
      filter: drop-shadow(0 2px 10px #FEFEFE);
    }
  }
}
.quest-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:4px;
  background-color: transparent;
  border:none;
  color:var(--color-white);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
  letter-spacing: 0.24px;
  max-width: 72px;
  min-height: 60px;
  padding: 4px 2px 2px 2px;
  border-radius:8px;

  &:hover, &:focus {
    background-color: rgba(254, 254, 254, 0.24);
  }

  &.highlighted {
    // filter: drop-shadow(7px 4px 16px #FE42FF) drop-shadow(-6px -6px 13.9px #FD38FF);
    .image {
      box-shadow: -6px -6px 25px 0px #FD38FF, 7px 4px 25px 0px #FE42FF;
    }
  }

  
}
.cluster-image {
  display: block;
  border-radius: 8px;
  background: var(--transparent-light-24, rgba(254, 254, 254, 0.24));
  width:30px;
  height: 30px;
  overflow: hidden;

    img {
      display:block;
      width: 24px;
      margin:3px auto 0 auto;
      object-fit: contain;
  }
  &.CC1, &.CC5, &.CC9 {
    background: var(--grad---blue, linear-gradient(149deg, #0CD6CB 20.97%, #597FFF 81.59%));
  }
  &.CC4, &.CC8 {
      background: var(--grad-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
  }
  &.CC2, &.CC6, &.CC13 {
      background: var(--grad---pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));
  }
  &.CC3, &.CC7, &.CC15 {
      background: var(--grad---yellow, linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%));
  }
}