@import "responsive";
.card {
    display: block;
    position: relative;
    // background: var(--bg-level1);
    // border: 1px solid var(--color-border);
    // border-radius: var(--card-corner-default);
    padding:var(--card-padding);
    // padding-bottom: 13px;
    height: 100%;
    text-align:left;

    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(255, 255, 255, 0.05);

/* frosted-glass */
box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
backdrop-filter: blur(50px);
color:var(--color-white);

    .bookmark-btn {
        position:absolute;
        right:12px;
        top:12px;
        opacity:0;
        color:var(--color-white);
        z-index: 2;
        width:24px;
        height:24px;
    }
    &:hover {
       > .bookmark-btn {
            opacity: 1;
        }
    }
    h2, h3 {
        margin:0;

        + p {margin:0;}
    }

    > a {
        color: var(--color-white);
        text-decoration: inherit;
        
    }
    p {
        a, .link-btn {
            font-size:inherit;
            line-height:inherit;
        }
    }
    // &:hover {
    //     // outline: 2px var(--color-border) solid;
    //     // outline-offset: -2px;
    // }
    .card-label {
        flex:none;
        display:flex;
        gap:4px;
        align-items: center;
        background: #020917B8;
        color:var(--color-white);
        padding:8px;
        border-radius:4px;
        font-weight:600;
        font-size:0.875rem;
        line-height: 1;
        position:absolute;
        left:12px;
        top:12px;

        &.completed {
            padding:12px;
        }
    }
    &.user-info:hover {
        outline: none;
    }

    &.no-padding {
        padding:0;
    }
    &.transparent {
        border:none;
        background-color: transparent;
    }

    .progress-ring {
        margin-left: auto;
        margin-right: auto;
    }
    .progress-label {
        margin-top: 5px;
        font-weight: bold;
    }

    // .card {
    //     background-color: var(--bg-level0);
    //     border: 1px solid var(--color-border);
    //     border-radius: var(--card-corner-default);

    //     .card {
    //         background-color: var(--bg-level1);
    //         border: 1px solid var(--color-border);
    //     }
    // }

    &.horizontal-card {
        display: flex;
        align-items: center;
        gap:16px;
        padding:16px;
        border-radius:16px;
        .avatar {
            width: 64px;
            height:64px;
        }
        .image {
            max-width:180px;
            img {width:100%;}
        }
        .card-body {
            // display:flex;
            // align-items: center;
            gap:var(--card-gap);
            flex:1;
            h3 {margin-bottom:8px;}
           .card-text {
            flex:1;
            p + p {
                margin-top:4px;
                margin-bottom:0;
            }
           }
        }
        .card-actions {
            align-self:center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // height:100%;
        }
    }
    
    .card-header {
        margin-bottom:12px;
        .flex-row {
            display:flex;
            flex-direction:column;
            align-items: flex-start;
            justify-content: space-between;
            
            @media (min-width: ($breakpointS + 1) ) {
                flex-direction:row;
                gap:10px;
            }
        }
        h2, .link-btn {
            margin-bottom:12px;
        }
        .link-btn {
            // white-space: nowrap;
            font-size:20px;
        }
    
        p {
            margin:0;
            .link-btn {margin:0;}
        }
    }
    .card-title {
        // display: block;
        // font-weight: 700;
        // font-size: 20px;
        // line-height: 24px;
        // margin:20px 0 0 0;
        margin:0;
        .icon {
            vertical-align: middle;
            margin-right:12px;
        }
        
    }
    &.edited {
        --icon-width: 0.8em;
        .card-title {
            &:after {
                content: '';
                background-image:  url('../icons/Completion.svg');
                margin-left: 0.2em;
                padding-left: var(--icon-width);
                background-size: var(--icon-width) var(--icon-width);
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }
    .card-subtitle {
        font-size:16px;
        line-height:20px;
        margin:0;
        // color:var(--text-muted);
        // color:rgba(2, 9, 23, 1);
        color: var(--text-default);

        
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        
    }
    .card-meta {
        font-size:14px;
        line-height:18px;
        color:var(--text-muted);
        display:flex;
        flex-wrap:wrap;
        margin: 8px 0 0 0;
        // span {white-space: nowrap;}
        span:not(:last-of-type) {
            &:after {
                content: "•";
                margin: 0 5px;
            }
        }
    }
    .image {
        img {
            width:100%;
            max-width:100%;
        }
    }
   .card-footer {
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        gap:12px;
        margin-top:32px;
        width:100%;

        button, a {
            button {white-space: nowrap;}
        }
        .link-btn {
            // white-space: nowrap;
            font-size:20px;
        }
        
   }
    .card-text {
        display: block;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-text-light);
        margin:0;
        &:last-child {margin-bottom:0;}
    }
    .card-link {
        font-size: 18px;
        line-height: 24px;
        color: var(--color-teal);
    }
    .feature-list {
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap:10px;
        grid-row-gap:16px;
        list-style:none;
        margin:0;
        padding:0;

        li {
            // white-space: nowrap; 
            hyphens: auto;
            vertical-align: middle;
            white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow:hidden;
            span {
                vertical-align: bottom;  
            }
            .circle-icon {
                color: var(--color-text-light);
            }
        }
    }
    .circle-icon {
        display: inline-block;
        width:29px;
        height:29px;
        background-color: var(--color-light-200);
        text-align:center;
        border-radius:50%;
        color: var(--color-text-muted);
        line-height:32px;
        margin-right:8px;
        padding:2px;
    }
    &.dark {
        // background-color: var(--color-dark-card);
        // border-color: var(--color-dark-card);
        color:var(--color-white);
        border-radius: 24px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        background: rgba(0, 0, 0, 0.20);
        * {
            color:var(--color-white);
        }
        .image {
            border-radius:12px;
        }

        a:not(.button), .link-btn, .card-link {
            color: var(--text-light-dark-link, #9B81E2);
        }
        .card {
            background-color: #272D39;
            border-color: #272D39;
        }

    }
    &.achievement-card {
        max-width:120px;
        flex: 0 0 120px;
        padding: 12px 14px;
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: flex-start;
        gap:12px;
        text-align:center;
        border-radius: 16px;
        height:auto;
        p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin:0;
            &.card-title {
                font-weight: 700;
                font-size: 14px;
            }
        }
        .image {
            width:42px;
            height:42px;
            margin:0 auto;
            background:transparent;
            padding:0;
            display: flex;
            align-items: center;
            justify-content:center;
        }
        &.locked {
            .card-info {
                opacity:0.4;
            }
        }
    }
    &.quest-card {
        background: var(--Transparent-40, rgba(0, 0, 0, 0.40));
        color:var(--color-white);
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px; /* 120% */
        letter-spacing: 0.24px;
        gap:4px;
        border-radius: 16px;
        border-color:transparent;
        justify-content: flex-start;
        .card-title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; 
            margin: 0 0 8px 0;
        }

        .image {
            display: block;
            border-radius: 8px;
            background: var(--transparent-light-24, rgba(254, 254, 254, 0.24));
            width:30px;
            height: 30px;
            overflow: hidden;

            img {
                display:block;
                width: 24px;
                margin:3px auto 0 auto;
                object-fit: contain;
            }

            &.CC1, &.CC5, &.CC9 {
            background: var(--grad---blue, linear-gradient(149deg, #0CD6CB 20.97%, #597FFF 81.59%));
            }
            &.CC4, &.CC8 {
                background: var(--grad-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
            }
            &.CC2, &.CC6, &.CC13 {
                background: var(--grad---pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));
            }
            &.CC3, &.CC7, &.CC15 {
                background: var(--grad---yellow, linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%));
            }
        }
    }
    &.snapshot {
        display:flex;
        flex-direction:column;
        text-align:left;
        .number {
            font-size: 64px;
            line-height: 72px;
            font-weight:600;
        }
        .label {
            font-size: 24px;
            line-height: 28px;
        }
    }
    
    .info-btn {
        position: absolute;
        right:8px;
        top:10px;
        .info-icon {
            display: inline-block;
            width:22px;
            height:22px;
            background-color: var(--color-light-200);
            text-align:center;
            border-radius:50%;
            color: var(--color-text-muted);
            padding:2px;
        }
    }
    &.overview {
        position: relative;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 24px;
        
        .label {
            font-size: 18px;
            line-height: 24px;
            font-weight:500;
            padding-right:40px;
            margin-bottom:8px;
        }
        .number {
            font-size:48px;
            line-height:52px;
            font-weight:600;
            margin-bottom:auto;

            // @media (min-width:1025px) {
            //     font-size:48px;
            // line-height:52px;
            // }
        }
        .footnote {
            font-size: 14px;
            line-height: 18px;
            margin-top:20px;
        }
        .button-link {
            margin-top:40px;
        }
        .graph-container {
            margin-top: 12px;
            & > div {
                margin-bottom: 12px;
            }
            & > svg {
                width: 100%;
                height: auto;
            }
            &.trend-up {
                .chip-highlight {
                    svg {
                        fill: var(--color-plum);
                        position: relative;
                        top: 3px;
                        left: 2px;
                    }
                }
            }
            &.trend-down {
                .chip-highlight {
                    svg {
                        fill: var(--color-state-orange-dark);
                        position: relative;
                        top: 3px;
                        left: 2px;
                    }
                }
            }
        }
    }

    &.video-card, &.skill-card {
        display:flex;
        flex-direction:column;
        max-width:320px;
        min-width: 0;
        flex: 1 1 320px;
        // padding:var(--card-padding-skills);
        padding:24px;
        height:auto;
        text-decoration: none;
        color:var(--color-white);
        font-weight:normal;
        gap:16px;
        
        .video.image {
            padding:0;
            margin:0;
            &:after {
                // content: '';
                width:48px;
                height:48px;
                display: block;
                background-image: url("../icons/play.svg");
                background-size:100% 100%;
                position:absolute;
                transform:translate(-50%, -50%);
                left:50%;
                top:50%;
            }
            .docked {
                opacity:0;
                pointer-events: none;
            }
            iframe {
                width:100%;
                height:100%;
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                
            }
            .play-icon {
                width:48px;
                height:48px;
                position:absolute;
                transform:translate(-50%, -50%);
                left:50%;
                top:50%;
                border-radius:50%;
                border:none;
                cursor: pointer;
                // width: 62px;
                // height: 62px;
                // border-radius: 50%;
                color: rgb(245, 245, 245);
                background-color: rgba(0, 0, 0, 0.5);
                transition: all 0.3s ease 0s;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                z-index: 1;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.8);
                    box-shadow: rgb(1, 203, 183) 0px 0px 10px;
                    text-shadow: rgb(1, 203, 183) 0px 0px 10px;
                }
            }
        }
        
        .image {
            position: relative;
            overflow:hidden;
            // border-radius: calc(0.5 * var(--card-corner-default));
            border-radius:12px;
            max-height:190px;
            width:100%;
            // top:0;
            // background: rgba(2, 9, 23, 0.45);
            background: rgba(2, 9, 23);
            
            img {
                display:block;
                width:100%;
                object-fit: cover;
                transition: all 0.3s ease-in-out 0s;
                height:140px;
                max-width:100%;
                // opacity: 0;
            }
            
        }
        .avatar {
            // border: 2px solid var(--color-border);
            background-color: var(--bg-level1);
            color:var(--text-muted);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            span {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
            }
        }

        .card-body {
            display:flex;
            gap:16px;
            align-items: flex-start;
            // padding: var(--card-padding-skills) calc(2* var(--card-padding-skills));
        }
        .card-title {
            margin:0 0 4px 0;
            // padding-right:24px;
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            color:inherit;
            &:only-child {margin:0;}
        }
        .card-meta {
            white-space:auto;
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
       
        .bookmark-btn {
            top:16px;
        }
        &:hover {
            .image {
                
            }
            .image img {
                opacity:0.55;
                transform: scale(1.1);
            }
        }
    }
    &.study-card {
        .image.video {
            background:transparent;
            text-align:center;
            img {
                width:auto;
                margin:auto;
            }
        }
        .card-body {
            .avatar {
                display:flex;
                align-items: center;
                justify-content: center;
                border:1px solid var(--color-border);
                background:var(--color-white);
                // img {vertical-align: middle;}
            }
        }
    }
    &.forage-card {
        height:100%;
        .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--color-border);
        }

        .forage-card-inner {
            background:transparent;
            border:none;
            padding:0;
            text-align:left;
        }

        &:before {
            position:absolute;
            left:16px;
            top:16px;
            content:'';
            width:32px;
            height:32px;
            border-radius:8px;
            border: 1px solid var(--color-border);
            background-color: var(--color-white);
            background-image: url("../icons/forage.png");
            background-repeat: no-repeat;
            background-position: 8px center;
            z-index: 11;
            background-size: 16px 16px;
            font-size:14px;
            transition: width 0.15s ease-in-out;
            box-sizing: border-box;
            overflow:hidden;
        }

        &:hover {
            &:before {
                content: "Forage";
                width: 80px;
                padding-left: 32px;
                line-height: 32px;
                padding-right: 8px;
            }
        }

    }

    &.saved {
        .image img {
            opacity:0.55;
        }
        .bookmark-btn {opacity:1;}
    }

    &.text-card {
        border-radius:16px;
        padding: 16px 24px;
        position: relative;
        .card-title {
            margin:0;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 116.667% */
            letter-spacing: 0.4px;
            display:flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
        &:hover, &:focus {
            outline:none;
            // z-index: 1;
            // background-clip: padding-box; /* !importanté */
            // margin: -1px;
            // border-image-source: var(--gradient-01-hover);
            // border-image-slice: 1;
            // border: 2px solid ;
            
            &:after {
                content:'';
                top: 0; right: 0; bottom: 0; left: 0;
                // z-index: -1;
                // margin: -2px; /* !importanté */
                border-radius: inherit; /* !importanté */
                // background: var(--gradient-01-hover);
                position:absolute;
                border-radius: 16px; /*1*/
                border: 2px solid transparent; /*2*/
                background: var(--gradient-01-hover) border-box; /*3*/
                -webkit-mask: /*4*/
                    linear-gradient(#fff 0 0) padding-box, 
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor; /*5'*/
          mask-composite: exclude; /*5*/
            }
        }
    }
    &.mini-card {
        // min-width:309px;
        // max-width:343px;
        min-width:0;
        width:100%;
        padding: var(--card-padding-mini);
        position: relative;
        text-decoration:none;
        color:var(--color-white);
        font-weight:normal;
        border-radius:16px;
        .bookmark-btn {
            width:40px;
            height:40px;
            top:0;
            right:4px;
            color:var(--text-default);
        }
        .avatar {
            width:41px;
            height:41px;
            flex:none;
        }
        .card-title {
            font-size:20px;
            line-height: 24px;
            margin-top:0;
            margin-bottom:8px;
            overflow:hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right:20px;
        }
        
        .job-details {
            display:flex;
            gap:16px;
            align-items: center;
        }
        p {margin:0;}
        .job-company {
            font-size:14px;
            line-height:18px;
        }
        p.meta {
            font-size:12px;
            line-height:18px;
            display:flex;
            flex-wrap:wrap;
            span:not(:last-of-type) {
                &:after {
                    content: "•";
                    margin: 0 5px;
                }
            }
        }

        &.saved {
            .card-title {
                padding-right:24px;
                overflow:hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .bookmark-btn {opacity:1;}
        }

        &:not(.no-results):hover, &:focus {
            outline:none;
            // z-index: 1;
            // background-clip: padding-box; /* !importanté */
            // margin: -1px;
            // border-image-source: var(--gradient-01-hover);
            // border-image-slice: 1;
            // border: 2px solid ;
            
            &:after {
                content:'';
                top: 0; right: 0; bottom: 0; left: 0;
                // z-index: -1;
                // margin: -2px; /* !importanté */
                border-radius: inherit; /* !importanté */
                // background: var(--gradient-01-hover);
                position:absolute;
                border-radius: 16px; /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: /*4*/
     linear-gradient(#fff 0 0) padding-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
          mask-composite: exclude; /*5*/
            }
            .bookmark-btn {opacity:1;}
            .card-title {text-decoration:underline;}
        }
    }

    .avatar {
        width:48px;
        height:48px;
        border-radius:50%;
        overflow:hidden;
        flex:none;
        background-color: var(--bg-level2);
            color:var(--text-muted);
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            span {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                vertical-align: middle;
                text-align:center;
                width:100%;
                height:100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;

            }
        img {width:100%;}

        &.Aplus {
            img {
                transform: scale(1.25);
            }
        }
    }
}



.card-carousel {
    position: relative;
    overflow:hidden;
    width:100%;

    .prev-btn {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        left:8px;
        z-index: 11;
        display: none;;
    }
    .next-btn {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right:8px;
        z-index: 11;
        display: none;;
    }

    .card-container {
        width:100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
       > ul {
            list-style-type: none;
            margin:0;
            padding:0;
            display:flex;
            gap:var(--column-gap);
            transition:transform 0.3s ease;

            > li {
                scroll-snap-align: start;
                flex-shrink: 0;
                flex:none;
            }
        }
    }

    @media (min-width:1025px) {

        &:not(.firstSlide) {
            &:before {
                content:'';
                position:absolute;
                width:80px;
                height:100%;
                left:0;
                top:0;
                bottom:0;
                background: linear-gradient(to right, var(--color-light-bg), rgba(255,255,255,0));
                z-index: 1;
            }
        }
        &:not(.lastSlide) {
            &:after {
                content:'';
                position:absolute;
                width:80px;
                height:100%;
                right:0;
                top:0;
                bottom:0;
                background:linear-gradient(to left, var(--color-light-bg), rgba(255,255,255,0));
                z-index: 1;
            }
        }
        
        .prev-btn, .next-btn {
            display: block;
            &:disabled {
                opacity: 0;
            }
        }
        .card-container {
            position: relative;
            width:100%;
            overflow: hidden;
            ul {
                width:max-content;
                li {
                    .card {height:100%;}
                }
            }
        }

         // For Dark Blue background
         &.dark-blue {
            &:not(.firstSlide) {
                &:before {
                    background: linear-gradient(to right, var(--color-dark-blue), rgba(255,255,255,0));
                }
            }
            &:not(.lastSlide) {
                &:after {
                    background:linear-gradient(to left, var(--color-dark-blue), rgba(255,255,255,0));
                }
            }

            .prev-btn, .next-btn {
                color: var(--color-black);
            }
         }
    }
}
.card.skills {
    .card-row {
        display: flex;
        justify-content: space-between;
        gap:8px;
    }
}
.split-card {
    padding:0;
    display:flex;
    flex-direction:column;
    background-color: var(--bg-level0);
    border: 1px solid var(--color-border);
    border-radius:var(--card-corner-default);
    h3 {margin:0 0 24px 0;}
    .recommended-side {
        flex:1;
        padding:var(--card-padding);
        border-top-left-radius: var(--card-corner-default);
        border-bottom-left-radius: var(--card-corner-default);
        .card-row {
            // display:grid;
            // grid-template-columns: 1fr;
            // grid-gap:16px;
            display:flex;
            flex-direction:row;
            gap:16px;
            flex-wrap:wrap;
            // margin-left:-8px;
            // margin-right:-8px;
        }
        .card {
            max-width:100%;
            flex: 1 1 calc(50% - 16px);
            min-width:180px;
            // margin:8px;
            .image {
                display:flex;
                align-items: center;
                justify-content: center;
                flex:none;
               max-height:90px; 
               img {
                height:90px;
                
                // transform: translateY(-25%);
               }
            }
            .card-info {
                min-width: 0;
            }
            .card-title {
                padding-right:0; //unless can't be bookmarked
                overflow:hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-width: 0;

            }
            .card-meta {display:none}
        }
    }
    .resume-side {
        flex:1;
        display:flex;
        flex-direction:column;
        padding:var(--card-padding);
        border-radius:0;
        border-bottom-left-radius: var(--card-corner-default);
        border-bottom-right-radius: var(--card-corner-default);
        background: var(--bg-level2);
        // border: 1px solid var(--color-border);
        .card.large {
            background-color: var(--bg-level1);
            border: 1px solid var(--color-border);
            flex:none;
            // flex:1;
            // display:flex;
            // flex-direction:column;
            max-width:100%;
            border-radius:var(--card-corner-default);
            .image {
                max-height:200px;
                img {
                    height: 200px;
                }
            }
            .progress-bar-container {
                padding: 8px 16px;
            }
           
            .progress-bar-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
                color: var(--text-muted);
            }
        }
        .card-footer {
            display:flex;
            align-items: center;
            justify-content: center;
            margin-top:auto;
            padding-bottom:24px;
        }
    }
    @media (max-width: $breakpointXS) {
         .card.skill-card {
            .avatar {
                width:24px;
                height:24px;
            }
            .card-title {
                display: -webkit-box;
                font-size:18px;
                hyphens: auto;
                word-break: break-word;
                overflow-wrap: break-word;
                max-height: 50px; /* two lines*/
                overflow:hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical; 
                text-overflow: ellipsis;
            }
        }
    }
    @media (min-width: ($breakpointMobile + 1)) {
        
        .recommended-side {
            .card-row {
                // display:grid;
                // grid-template-columns: repeat(2, minmax(120px, 1fr));
                // grid-column-gap:8px;
                // grid-row-gap:16px;
                .card {
                    .image {
                        
                       max-height:60px; 
                       img {
                        height:60px;
                        
                        // transform: translateY(-25%);
                       }
                    }
                }
    
            }
        }
    }
    @media (min-width: ($breakpointXS + 1) ) {
        // medium sizes
        flex-direction:row;

        .recommended-side {
            .card-row {
                grid-gap:16px;

                .card {
                    .image {
                        max-height:90px;
                        img {
                            height:90px;
                        }
                    }
                }
            }
        }

        .resume-side {
            border-radius:0;
            border-top-right-radius: var(--card-corner-default);
            border-bottom-right-radius: var(--card-corner-default);
        }
    }
}
.card .jobs-carousel {
    margin-top: 20px;
}
.card-row {
    display: flex;
    // justify-content: space-between;
    flex-direction:column;
    gap:var(--column-gap);
    margin-top: 20px;
    padding-bottom:12px;
    margin-bottom:-12px;

    &.one-two {

    }

    &.gap-24 {
        gap:24px;
    }

    &.overflow-scroll {
        max-width:100%;
        overflow-x: auto;
        // .card {flex:none}
    }
    &.overflow-wrap {
        flex-wrap:wrap;
            padding-bottom:0px;
        // overflow-x: scroll;
        // flex-direction:row;
        // padding-bottom:8px;
        // @media (min-width: ($breakpointM + 1)) {
        //     flex-wrap:wrap;
        //     padding-bottom:0px;
        // }
    }
    &.study-group {
        flex-wrap:wrap;
        margin-top:0;
        .card {
            flex: 1 1 360px;
        }
    }
    .card {flex:1;}
//    &.skills-group {
//     .card {
//         flex: 0 1 320px;
//     }
//    }

    @media (min-width:($breakpointS + 1)) {
        flex-direction:row;

        &.one-two {
            .card {height:auto}
        }
        .three-col {
            flex:3;
        }
        .one-col {
            flex:1;
        }
        .two-col {
            flex:2;
        }
    }
}

.card {
    &.dark {
        // background-color: var(--color-indigo);
        // border-color:transparent;

        .avatar {
            background-color: var(--color-black);
            color:var(--bg-level0);


        }
    }
    
    &.dark-gradient, &.dark.action-card {
        background:linear-gradient(209deg, rgba(118, 14, 1, 0.40) 0%, rgba(109, 1, 118, 0.00) 71.12%), linear-gradient(0deg, rgba(37, 23, 78, 0.60) 0%, rgba(37, 23, 78, 0.60) 100%), #000;
        border-color:transparent;

        .avatar {
            background-color: var(--color-black);
            color:var(--bg-level0);
        }

        &.edited {
            background: linear-gradient(209deg, rgba(118, 14, 1, 0.40) 0%, rgba(109, 1, 118, 0.00) 71.12%), linear-gradient(0deg, rgba(61, 74, 194, 0.60) 0%, rgba(61, 74, 194, 0.60) 100%), #000;
        }
        .card {
            border-color:transparent;
            background: rgba(49, 20, 85, 0.80);
            color:var(--color-white);
            .card-title {
                color:var(--color-white);
            }
    
            .job-company {color:#E0E7F0;}
            p.meta {color: #A7BAD0;}
            .bookmark-btn {color:var(--color-white);}
    
        
        }
    }
    
   
    &.action-card {
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        // max-width:calc((200vw - 172px)/3);
        min-width:0;
        min-height:360px;
        .card-header {
            margin-bottom:0;
            // margin-bottom:16px;
            // h2 {margin-bottom:0;}
            .card-title, .link-btn {
                margin-bottom:16px;
            }
            .link-btn {
                font-size: 22px;
                // font-weight: 500;
                line-height: 24px;
            }
        }
        &.video-card {
            padding: var(--card-padding);
            max-width:100%;
            color: var(--color-white);
            .card-info {
                padding:0;
                margin-bottom:16px;
                display:flex;
                gap:16px;
                align-items: center;
            }
            
            .card-subtitle.author-name {color:inherit;}
            .progress-bar .bar {
                background: var(--gradient-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
            }
        }
        &.docked-image {
            .card-body {
                // margin-bottom:calc(-1*var(--card-padding));
                // padding-left:10%;
                .image {
                    max-width:350px;
                    width:100%;
                    
                    img {
                        display: block;
                    }
                }
                
            }
            &.wide {
                .card-body {
                    .image {
                        max-width:100%;
                    }
                }
                
            }
        }
        &.wide {
            .card-body {
                .image {
                    max-width: 800px;
                }
            }
        }
        &.full-width {
            .card-body {
                max-width:100%;
                .image {
                    max-width:100%;
                }
            }
        }
        .overflow-scroll {
            padding-bottom:8px;
            scrollbar-color: #B070FF rgba(49, 20, 85, 0.80); 
        }
        
        .card-body {
            flex:1;
            display:flex;
            flex-direction:column;
            justify-content: space-between;
            margin-top:12px;

            .image {
                max-width:100%;
                width:100%;
                
                img {
                    display: block;
                }
            }
        }
        .card-footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap:wrap;
            width:auto;
            margin: 32px auto 0 auto;
            button {
                // white-space:nowrap;
                // flex:1;
                width:100%;
            }
        }
        
    }
    .progress-bar-container {
        margin:0 0 16px 0;
        max-width:600px;
    }
    // .flex-row {
    //     flex-direction:column;
    //     align-items: flex-end;
    //     gap:var(--column-gap);
    //     // flex-wrap:wrap;
    //     margin-top:auto;
    //     .image {
    //         flex:1;
    //         margin:auto;
    //     }
    //     button {white-space: nowrap;}
    // }
    .card-header {
        .card-title, .link-btn {
            margin-bottom:12px;
        }
        // .flex-row {
        //     align-items: flex-start;
        //     gap:0px;
        // }
        
    }
}
.stacked-card {
    display:flex;
    flex-direction:column;

    .card-body {
        flex:1;
    }
    &.one-col {
        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .image {
                max-width:160px;
                margin:0 auto;
            }
        }
    }
}
.career-card {
    height:auto;
    .card-header {
        margin-bottom:24px;
    }
    h4 {
        margin:0;
    }
   > .card-body {
        
        gap:24px;
        // height:100%;
    }
    .one-col {
        .card-header {
            margin-bottom:24px;
        }
    }
    .image {
        position: relative;
        width:100%;
                height:auto;
        
        overflow: hidden;
        margin-bottom: 28px;
        border-radius:8px;
    
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .outlook-item {
        display: block;
        h5 {
            margin-top:16px;
            margin-bottom:4px;
            &:first-of-type {
                margin-top:0;
            }
        }
        p {
            margin:0;
        }
    }

    @media (min-width:($breakpointS + 1)) {
        > .card-body {
            display:flex;
            align-items: stretch;
            .image {
                height: 100%;
                margin-bottom:0;
            }
        }
        .career-info {
            display:flex;
            flex-direction:column;
            .card-body {
                flex:1;
            }
        }
    }
}



.flex-row {
    display: flex;
    justify-content: space-between;

    .image {
        img {width:100%;max-width:100%;}
    }
}
@media (min-width:($breakpointS + 1)) {
// 414 and up
.card .card-header .link-btn {
    white-space: nowrap;
}
    .card.action-card {
        .card-body {
            .flex-row {
                flex-direction: row;
                align-items: flex-end;
                gap:var(--column-gap);
                // flex-wrap:wrap;
                margin-top:auto;
                .image {
                    flex:1;
                   
                    
                }
                button {white-space: nowrap;}
            }
        }
    }
    .intro .card.dark .card{
        min-width:343px;
        // min-width:309px;
        max-width:343px;
    }

}
// @media (max-width:($breakpointXS)) {
//     // 414 and up
//         .intro .card.dark.action-card {
//             .card-body {
//                 .flex-row {
//                     flex-direction: column;
//                     // align-items: flex-end;
//                     gap:var(--column-gap);
//                     // flex-wrap:wrap;
//                     margin-top:12px;
//                     .image {
//                         margin:auto;
//                     }
//                     button {white-space: nowrap;}
//                 }
//             }
//         }
    
//     }
@media (max-width: $breakpointXS) {
    .card.horizontal-card {
        flex-direction: column;
        align-items: center;
        
        .card-body {flex-direction: column;}
        .card-text * {text-align: center;}
    }
    .career-switcher {
        &.tab-container .tab-list {
            overflow-x: unset;
        }
    }
    .career-fast-facts .facts-row {
        flex-direction: column;
        h6 {
            text-align: left;
        }

    }
    .intro {
        > .card-row {
            display:flex;
            flex-direction: column;
        }
    }
    .card.skill-card {
        max-width:100%;
        .card-title {
            padding-right:0; //unless can't be bookmarked
            overflow:hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            min-width: 0;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display:-webkit-box;
        }
    }
}
@media (min-width: ($breakpointXS + 1)) and (max-width:$breakpointS) {
    .card.horizontal-card {
        flex-direction: column;
        align-items: center;
        .card-text * {text-align: center;}
    }
    .career-switcher {
        &.tab-container .tab-list {
            overflow-x: unset;
        }
    }
    .career-fast-facts .facts-row {
        flex-direction: column;
         h6 {
            text-align: left;
        }
    }
   .intro {
    > .card-row {
        display:grid;
        grid-template-columns: 3fr 5fr;
        grid-gap:var(--column-gap);
        // .one-col {
        //     flex:3;
        // }
        // .two-col {
        //     flex:5;
        // }
    }
   }
   .card.video-card, .card.skill-card {
    .image {
        max-height:100px;
        img {
            height:100px;
        }
    }
   }
   .card.action-card {
    .card-footer {
        flex-direction: row;
        justify-content: flex-end;
        width:100%;
        button {
            width:auto;
        }
    }
   }
   .jobs-group.card-row, .card-row.skills-group {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-gap:var(--column-gap);
        overflow:hidden;
        // grid-template-rows: auto auto auto;
        // grid-auto-rows: 0px;
        // margin-bottom: calc(-1* var(--column-gap));

        .card {
            &:nth-child(n+7) {
                display: none;
            }
        }
    }
//     .card-row.skills-group {
//         // flex-direction:row;
//         display:grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: auto;
//     grid-gap:var(--column-gap);
//     .card {
//         // flex:1 1 240px;
//     }
//    }
}
@media (min-width:($breakpointS + 1)) {
    .jobs-group.card-row {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap:var(--column-gap);
        overflow:hidden;
        grid-template-rows: auto auto;
        grid-auto-rows: 0px;
        margin-bottom: calc(-1* var(--column-gap));
        &.single-row {
            grid-template-rows: auto;   
        }
       
   }
   .card-row.skills-group {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:var(--column-gap);
    overflow:hidden;
    grid-template-rows: auto;
    grid-auto-rows: 0px;
    // margin-bottom: calc(-1* var(--column-gap));

    &.show-all {
        // display: block;
        grid-auto-rows: auto;
        .card {
            height:100%;
        }
        // > div {
        //     display:grid;
        //     grid-template-columns: repeat(3, 1fr);
        //     grid-gap:var(--column-gap);
        //     overflow:hidden;
        //     grid-template-rows: auto;
        //     grid-auto-rows: auto;
        // }
        
    }


    // &:not(.show-all) .card {
    //     max-width: 100%;
    //     &:nth-child(n+4) {
    //         display: none;
    //     }
    // }
   
}
  .card.action-card {
    padding-bottom:calc(var(--card-padding) + 48px + 32px);
    .card-body {
        // margin-bottom:calc(-1 * (var(--card-padding) + 48px));
        // .image {
           
        // }
    }
    &.padding-even {
        padding:var(--card-padding);
    }
    &.docked-image {
        .card-body {
           justify-content: flex-end;
           align-items: center;
            max-width:50%;
            margin-bottom:calc(-1 * (var(--card-padding) + 48px + 32px));
            
            
        }
        &.wide {
            .card-body {
                align-items: flex-start;
                max-width:75%;
                margin-bottom:calc(-1 * (var(--card-padding) + 48px));
                .image {
                    max-width:1200px;
                }
            }
           
        }
        &.full-width {
            .card-body {
                max-width:calc(100% + var(--card-padding) + var(--card-padding));
                width:calc(100% + var(--card-padding) + var(--card-padding));
                margin-left:calc(-1 * var(--card-padding));
                margin-right:calc(-1 * var(--card-padding));
            }
        }
    }
    .card-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap:wrap;
        position:absolute;
        bottom:var(--card-padding);
        // left:0;
        right:var(--card-padding);
        width:calc(100% - 2*var(--card-padding));
        button {
            width:auto;
        }
    }
   }
}
@media (min-width: ($breakpointS + 1)) and (max-width: $breakpointM) {
    .card-row.skills-group {
        &:not(.show-all) .card {
            max-width: 100%;
            &:nth-child(n+4) {
                display: none;
            }
        }
    }
}
@media (min-width:($breakpointM + 1)) {
    .jobs-group.card-row,  .card-row.skills-group {
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap:var(--column-gap);
        margin-bottom:0;

    
       
   }
   .card.dark .card-header .flex-row {
        display:flex;
        gap:24px;
   }
   
}

.sandbox section > .card {margin-bottom:40px}

.image.skeleton {
    max-width:455px !important;
    height:175px;
    border-radius: 8px;
    opacity: 0.8;
    background: #D9D9D9;
    display:flex;
    align-items: center;
    justify-content: center;
    color:var(--text-default);
    font-size:2em;
    flex:none;
    span {text-align:center}
}

.chip-label {
    background: #02091714;
    border:none;
    padding: 6px 12px;
    border-radius:4px;
    color:var(--text-default);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.24px;
    text-align: left;
    display: inline-block;
    transition:background-color 0.3s ease;
    &.dark {
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.10);
        color:#FEFEFE;
        // border-color: rgba(255, 255, 255, 0.10);

        &:hover {
            background: var(--Swatch-Light-300, #DDE3EE);
            color:var(--text-default);
        }
        &.selected {
            color:var(--text-default);
            background-color: #FEFEFE;
        }

    }
    &.light {
        color:var(--text-default);
        background-color: #FEFEFE;
    }

}
.card {
    .chip-label {
        // margin-bottom:8px;
    }
    &.job-result-card:not(.active) {
        .chip-label {
            background: #020917B8;
        color:var(--color-white);
        }
    }
}

/* starting ENTER animation */
.ctaCard-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    opacity: 0;
    z-index: 11;
    // transform: translateY(0%) scale(0.01);

    * {
        opacity:0;
        transition: opacity 1000ms ease-in-out;
        transition-delay: 200ms;
    }
    &.with-delay {
        transition-delay: 800ms;
    }
  }
  
  /* ending ENTER animation */
  .ctaCard-enter-active {
    opacity: 1;
    // transform: translateY(0%) scale(1);
    transition: opacity 1000ms ease-in-out;
    * {
        opacity:1;
    }
  }
  
  /* starting EXIT animation */
  .ctaCard-exit {
    opacity: 1;
    // transform: scale(1.01);
  }
  
  /* ending EXIT animation */
  .ctaCard-exit-active {
    opacity: 0;
    // transform: scale(4);
    transition: opacity 1000ms ease-in-out;
  }

  /* starting ENTER animation */
.jobsCard-enter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    opacity: 0;
    z-index: 11;
    // transform: translateY(0%) scale(0.01);
   
  }
  
  /* ending ENTER animation */
  .jobsCard-enter-active {
    opacity: 1;
    // transform: translateY(0%) scale(1);
    transition: opacity 1000ms ease-in-out;
    transition-delay: 250ms;
    
  }
  
  /* starting EXIT animation */
  .jobsCard-exit {
    opacity: 1;
    // transform: scale(1.01);
  }
  
  /* ending EXIT animation */
  .jobsCard-exit-active {
    opacity: 0;
    // transform: scale(4);
    transition: opacity 1000ms ease-in-out;
    transition-delay: 250ms;
  }

  .card:not(.dark) {
    .card-footer .button.filled.light {
        outline: 1px solid var(--border-dark-theme-border, #393F4A);
        // outline-offset: -1;
    }
  }

  .progress-bar-container {
    padding: 0 8px;
    
    .progress-bar {
        position:relative;
        width:100%;
        height:9px;
        border-radius: 30px;
        background: #969696;
        margin-bottom:8px;
        .bar {
            height:100%;
            background:linear-gradient(175deg, #9dd6ff 0%, #8d41ee00 50% ),linear-gradient(218deg, #7041ee, #8d41ee, #d641ee ), #8d41ee ;
            border-radius: 30px;
        }
    }
    .progress-bar-label {
        display:block;
        font-weight:500;
        margin-top:6px;
    }
}