@charset "UTF-8";
@import url("https://static.studychannel-prd.pearsonprd.tech/fonts/tt-commons.css");
:root, .light-mode {
  --text-default: #020917;
  --text-muted: #60646D;
  --color-border: #DDE3EE;
  --bg-level0: #fefefe;
  --bg-level1: #F7F9FD;
  --bg-level2: #E8EEFA;
  --color-dark-blue: #05112A;
  --color-dark-purple: #6D0176;
  --color-medium-blue: #122142;
  --color-indigo: #1D3058;
  --grey-light1: #E0E7F0;
  --color-light-300: #DDE3EE;
  --color-dark-600: #4C515B;
  --color-plum: #6D0176;
  --color-success: #19a292;
  --color-success-500: #1F897C;
  --color-bright-teal: #0CC0AD;
  --text-light-theme-link: #655591;
  --text-dark-theme-link: #9B81E2;
  --border-radius: 16px;
  --card-corner-default: var(--border-radius);
  --color-white: #fefefe;
  --color-black: #020917;
  --gradient-01: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
  --gradient-01-hover: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
  --checkbox-border: #60646D;
  --checkbox-checked: #1D3058B2;
  --column-gap: 24px;
  --color-dark-card: #122142;
}

.dark-mode {
  --bg-level0: #020917;
  --bg-level1: #161C29;
  --text-default: #fefefe;
  --text-muted: #A7BAD0;
  --color-border: #393F4A;
}
@font-face {
  font-family: "TT Commons";
  font-display: block;
  src: url("../fonts/ttcommons-medium.woff2") format("woff2"), url("../fonts/ttcommons-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  font-style: normal;
  font-display: block;
  src: url("../fonts/ttcommons-mediumitalic.woff2") format("woff2"), url("../fonts/ttcommons-mediumitalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  font-display: block;
  src: url("../fonts/ttcommons-demibold.woff2") format("woff2"), url("../fonts/ttcommons-demibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  font-style: normal;
  font-display: block;
  src: url("../fonts/ttcommons-demibolditalic.woff2") format("woff2"), url("../fonts/ttcommons-demibolditalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "TT Commons";
  font-display: block;
  src: url("../fonts/ttcommons-bold.woff2") format("woff2"), url("../fonts/ttcommons-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "TT Commons";
  font-style: normal;
  font-display: block;
  src: url("../fonts/ttcommons-bolditalic.woff2") format("woff2"), url("../fonts/ttcommons-bolditalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
body {
  font-family: "TT Commons", sans-serif;
  color: var(--text-default);
}

.desktop-h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
}

.desktop-h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
}

.desktop-h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.desktop-h3-regular {
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.mobile-h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.400000006px;
  text-align: left;
}

.desktop-h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.4px;
}
.desktop-h4.bold {
  font-weight: 700;
}

.desktop-h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  text-align: left;
}
.desktop-h5.bold {
  font-weight: 700;
}

.desktop-h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.4px;
}
.desktop-h6.bold {
  font-weight: 700;
}

.mobile-h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.400000006px;
  text-align: left;
}

.body-text {
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.24px;
}
.body-text.bold {
  font-weight: bold;
}

.body-text.small {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2399999946px;
  text-align: left;
}

.body-text.caption {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2399999946px;
  text-align: left;
}

/* Web/Body Text/Paragraph--Link */
.body-text.link {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.24px;
  text-decoration-line: underline;
  color: var(--color-plum);
}

.subtitle-1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
}

.muted {
  color: var(--text-dark-theme-muted, #989BA3);
}

.page-title.level1 {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 12px;
}
.page-title.level1:after {
  content: "";
  height: 4px;
  width: 100%;
  display: block;
  background: var(--gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
  position: absolute;
  bottom: 0;
}

/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/
button {
  cursor: pointer;
}

button[disabled] {
  pointer-events: none;
  color: var(--color--interactive-disabled-02);
  background-color: inherit;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: "TT Commons", sans-serif;
  font-weight: 700;
  letter-spacing: 0.4px;
  background: transparent;
  border: none;
  position: relative;
  border-radius: 50px;
  transition: background 0.3s ease;
  text-decoration: none;
}
.button.large {
  min-height: 56px;
  min-width: 56px;
  padding: 8px 40px;
  font-size: 24px;
  line-height: 40px; /* 166.667% */
  gap: 12px;
}
.button.large.icon-left {
  padding-left: 32px;
}
.button.large.icon-right {
  padding-right: 32px;
}
.button.large svg {
  width: 32px;
  height: 32px;
}
.button.medium {
  min-height: 48px;
  min-width: 48px;
  padding: 8px 32px;
  font-size: 20px;
  line-height: 32px; /* 160% */
  gap: 12px;
  border-radius: 24px;
}
.button.medium.icon-left {
  padding-left: 24px;
}
.button.medium.icon-right {
  padding-right: 24px;
}
.button.medium svg {
  width: 24px;
  height: 24px;
}
.button.small {
  min-height: 40px;
  min-width: 40px;
  padding: 8px 24px;
  font-size: 18px;
  line-height: 18px;
  gap: 8px;
}
.button.small.icon-left {
  padding-left: 16px;
}
.button.small.icon-right {
  padding-right: 16px;
}
.button.small svg {
  width: 16px;
  height: 16px;
}
.button.xsmall {
  min-height: 32px;
  min-width: 32px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 16px;
  gap: 4px;
}
.button.xsmall.icon-left {
  padding-left: 12px;
}
.button.xsmall.icon-right {
  padding-right: 12px;
}
.button.xsmall svg {
  width: 16px;
  height: 16px;
}
.button.icon-btn {
  padding: 0;
  background-color: transparent;
  min-width: 24px;
  min-height: 24px;
}
.button.icon-btn.white {
  color: var(--color-white);
}
.button.icon-btn:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.button:disabled {
  opacity: 0.32;
}
.button.primary {
  background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
  color: var(--color-white);
}
.button.primary:not(:disabled):hover {
  background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
}
.button.primary:not(:disabled):focus {
  outline: 0;
  background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
  box-shadow: 0px 0px 0px 2px #B41863;
}
.button.primary:not(:disabled):focus:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px dashed var(--color-border, #DDE3EE);
  border-radius: 50px;
}
.button.light.filled {
  color: var(--swatch-dark-800, #272D39);
  background-color: var(--color-white, #fefefe);
}
.button.light.filled:not(:disabled):hover {
  color: var(--color-white, #fefefe);
}
.button.light.outline {
  outline: 2px solid rgba(255, 255, 255, 0.25);
  outline-offset: -2px;
  color: var(--color-white, #fefefe);
  background: transparent;
}
.button.light.outline:not(:disabled):hover {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
}
.button.light.outline:not(:disabled):hover.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.light.outline:not(:disabled):focus {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
  box-shadow: 0px 0px 0px 2px #393F4A;
  outline: 0;
}
.button.light.outline:not(:disabled):focus.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.light.outline:not(:disabled):focus:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px dashed var(--color-border, #DDE3EE);
  border-radius: 50px;
}
.button.dark.filled {
  background-color: var(--swatch-dark-800, #272D39);
  color: var(--color-white, #fefefe);
}
.button.dark.outline {
  color: var(--color-default, #020917);
  outline: 2px solid var(--swatch-dark-800, #272D39);
  outline-offset: -2px;
}
.button.dark.clear {
  color: var(--color-default, #020917);
}
.button.dark:not(:disabled):hover {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
}
.button.dark:not(:disabled):hover.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.dark:not(:disabled):focus {
  background: var(--swatch-dark-700, #393F4A);
  color: var(--color-white, #fefefe);
  box-shadow: 0px 0px 0px 2px #393F4A;
  outline: 0;
}
.button.dark:not(:disabled):focus.outline {
  outline: 2px solid var(--swatch-dark-700, #393F4A);
}
.button.dark:not(:disabled):focus:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px dashed var(--color-border, #DDE3EE);
  border-radius: 50px;
}

/*------------------------------------*\
  #UNSTYLED
\*------------------------------------*/
.button-unstyled {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.button-unstyled::before {
  display: none;
  background: transparent;
  border: none;
  box-shadow: none;
}

/*------------------------------------*\
  #text button
\*------------------------------------*/
.button-link, .link-btn {
  font-size: 18px;
  font-weight: 700;
  background-color: transparent;
  border: none;
  color: var(--text-light-theme-link, #655591);
  text-decoration: underline;
  padding: 0;
}

.button-link.with-icon, .link-btn.with-icon {
  text-decoration: none;
  font-weight: 600;
  white-space: nowrap;
}
.button-link.with-icon svg, .link-btn.with-icon svg {
  margin-right: 8px;
}

.button-link svg, .link-btn svg {
  vertical-align: bottom;
}

.button-link:hover, .link-btn:hover {
  color: var(--text-light-theme-link-hover, #4C406B);
  text-decoration: none;
}

.circle-btn {
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 50%;
  background: #e8eefa;
  border: 1px solid var(--color-border);
}

.btn-centered {
  margin: 0 auto;
  width: 100%;
}

a {
  color: var(--text-light-theme-link, #655591);
  text-decoration: underline;
  font-weight: 700;
}
a.white {
  color: var(--color-white);
}

.slider-nav-button {
  background: #020917;
  border: 1px solid #393F4A;
  color: var(--color-white);
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 40px;
}
.slider-nav-button:hover, .slider-nav-button:active {
  box-shadow: 0px 0px 16px 0px #05112A;
  border: 1px solid #F7F9FD;
  background: #05112A;
}
.slider-nav-button:focus {
  border: 1px solid #05112A;
  background: #05112A;
  box-shadow: 0px 0px 16px 0px #05112A;
  outline: 2px dashed var(--swatch-light-100, #F7F9FD);
  outline-offset: -2px;
}

.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: var(--color-dark-blue);
  color: white;
  opacity: 0.8;
  border: 1px solid white;
  border-radius: 8px;
  z-index: 99999;
  width: 48px;
  height: 48px;
  padding: 11px;
  opacity: 0;
}
.back-to-top.show {
  opacity: 1;
}

.rating-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
}
.button.rating-btn {
  color: #fff;
  background: var(--Transparent-fill, rgba(0, 0, 0, 0.25));
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.button.rating-btn svg {
  width: 16px;
  height: 16px;
}
.button.rating-btn:hover {
  filter: background-blur(100%);
  background: rgba(255, 255, 255, 0.1);
}
.button.rating-btn.selected, .button.rating-btn[aria-checked=true] {
  color: var(--swatch-dark-800, #272D39);
  background-color: var(--color-white, #fefefe);
}
.button.rating-btn .icon {
  margin-left: 8px;
}
.button.rating-btn .icon svg {
  width: 24px;
  height: 24px;
  margin: 0 2px;
  fill: #fff;
}
.button.rating-btn .icon svg.thumbs {
  transform: scale(0.75) translate(-5px, 10px);
}

.nav-button {
  background: linear-gradient(-218deg, #9dd6ff 0%, rgba(141, 65, 238, 0) 50%), linear-gradient(218deg, #7041ee, #8d41ee, #d641ee), #8d41ee;
  box-shadow: 0px -1.812px 10.875px 0px rgba(255, 255, 255, 0.24) inset;
  color: var(--color-white);
  width: 58px;
  height: 58px;
  border-radius: 50%;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.nav-button .active {
  display: none;
}
.nav-button .default {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.nav-button svg {
  position: relative;
  z-index: 2;
}
.nav-button img {
  width: 54px;
  z-index: 2;
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  border-radius: 50%;
}
.nav-button.active:before {
  content: "";
  position: absolute;
  background: var(--Color-Gray-White, #FFF);
  border-radius: 50%;
  box-shadow: 0px -1.812px 10.875px 0px rgba(255, 255, 255, 0.24) inset;
  width: 54px;
  height: 54px;
  margin: 2px;
}
.nav-button.active .active {
  display: unset;
}
.nav-button.active .default {
  display: none;
}

.achievements-btn {
  background-color: transparent;
  border: none;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.achievements-btn:hover svg, .achievements-btn:focus svg {
  filter: drop-shadow(0 2px 10px #FEFEFE);
}

.quest-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  border: none;
  color: var(--color-white);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
  letter-spacing: 0.24px;
  max-width: 72px;
  min-height: 60px;
  padding: 4px 2px 2px 2px;
  border-radius: 8px;
}
.quest-btn:hover, .quest-btn:focus {
  background-color: rgba(254, 254, 254, 0.24);
}
.quest-btn.highlighted .image {
  box-shadow: -6px -6px 25px 0px #FD38FF, 7px 4px 25px 0px #FE42FF;
}

.cluster-image {
  display: block;
  border-radius: 8px;
  background: var(--transparent-light-24, rgba(254, 254, 254, 0.24));
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.cluster-image img {
  display: block;
  width: 24px;
  margin: 3px auto 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.cluster-image.CC1, .cluster-image.CC5, .cluster-image.CC9 {
  background: var(--grad---blue, linear-gradient(149deg, #0CD6CB 20.97%, #597FFF 81.59%));
}
.cluster-image.CC4, .cluster-image.CC8 {
  background: var(--grad-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
}
.cluster-image.CC2, .cluster-image.CC6, .cluster-image.CC13 {
  background: var(--grad---pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));
}
.cluster-image.CC3, .cluster-image.CC7, .cluster-image.CC15 {
  background: var(--grad---yellow, linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%));
}

.dark-mode {
  background-color: var(--bg-level0);
  /*buttons */
}
.dark-mode .card {
  background-color: var(--bg-level1);
  border: 1px solid var(--bg-level1);
}
.dark-mode .card.bg-image {
  border: 1px solid var(--bg-level1);
}
.dark-mode .card .card {
  background-color: var(--bg-level0);
  border: 1px solid var(--bg-level0);
}
.dark-mode .card .card.mini-card p.meta {
  color: var(--text-muted);
}
.dark-mode .card .card.video-card .card-subtitle {
  color: var(--text-muted);
}
.dark-mode .card .card.video-card p.card-meta {
  color: var(--text-default);
}
.dark-mode .gr-input {
  background-color: transparent;
  border-color: var(--text-default);
}
.dark-mode .button.dark.filled {
  background-color: var(--swatch-light-400, #D2D7E2);
  color: var(--color-black, #020917);
}
.dark-mode .button.dark.outline {
  color: var(--text-dark-theme-default, #FEFEFE);
  outline: 2px solid var(--swatch-light-400, #D2D7E2);
  outline-offset: -2px;
}
.dark-mode .button.dark.clear {
  color: var(--text-dark-theme-default, #FEFEFE);
}
.dark-mode .button.dark:not(:disabled):hover {
  background: var(--swatch-light-100, #F7F9FD);
  color: var(--color-black, #020917);
}
.dark-mode .button.dark:not(:disabled):hover.outline {
  outline: 2px solid var(--swatch-light-100, #F7F9FD);
}
.dark-mode .button.dark:not(:disabled):focus {
  background: var(--swatch-light-100, #F7F9FD);
  color: var(--color-black, #020917);
  box-shadow: 0px 0px 0px 2px var(--swatch-light-100, #F7F9FD);
  outline: 0;
}
.dark-mode .button.dark:not(:disabled):focus.outline {
  outline: 2px solid var(--swatch-light-100, #F7F9FD);
}
.dark-mode .button.dark:not(:disabled):focus:after {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border: 2px dashed var(--border-dark-theme-border, #393F4A);
  border-radius: 50px;
}
.dark-mode .button-link, .dark-mode .link-btn {
  color: var(--text-dark-theme-link, #9B81E2);
}
.dark-mode .button-link:hover, .dark-mode .link-btn:hover {
  color: var(--text-dark-theme-link-hover, #B79BFF);
}
.dark-mode .circle-btn {
  background-color: transparent;
  color: var(--text-default);
}
.dark-mode .career-switcher .tab-list .tab-item button {
  background: var(--bg-level0);
  border-color: var(--bg-level0);
}

.header-wrapper {
  display: flex;
  align-items: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px var(--outside-padding) 0px var(--outside-padding);
  gap: 24px;
  height: 69px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.1);
  /* frosted-glass */
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
}
.page-header .logo {
  color: var(--color-white);
}
.page-header .logo a {
  color: var(--color-white);
}
.page-header .tab-container .tab-slider {
  bottom: 0;
}
.page-header .header-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
@media (min-width: 508px) {
  .page-header .header-actions .button {
    white-space: nowrap;
  }
}
.page-header .account-menu-container {
  color: var(--color-white);
}
.page-header .account-menu-container .avatar {
  border-color: var(--color-white);
}
.page-header .account-menu-container .icon-18 {
  color: inherit;
}

.account-menu-container {
  position: relative;
  z-index: 11;
}
.account-menu-container .button-unstyled {
  position: relative;
  padding-right: 18px; /*create space for arrow*/
}
.account-menu-container .button-unstyled[aria-expanded=true] .avatar {
  background: var(--color-white);
  color: var(--text-default);
}
.account-menu-container .button-unstyled[aria-expanded=true] svg {
  transform: rotate(180deg);
}
.account-menu-container .avatar {
  border: 2px solid var(--text-default);
  border-radius: 100px;
  padding-top: 0;
  height: 36px;
  width: 36px;
  display: block;
  line-height: 36px;
  font-weight: bold;
  overflow: hidden;
}
.account-menu-container .avatar img {
  width: auto;
  height: 110%;
}
.account-menu-container .icon-18 {
  position: absolute;
  top: 10px;
  right: 0px;
  color: var(--text-default);
}
.account-menu-container .icon-18 svg {
  fill: currentColor;
  transition: transform 0.15s ease;
}
.account-menu-container .account-menu {
  display: block;
  position: absolute;
  background: var(--color-white);
  width: 200px;
  text-align: right;
  right: -13px;
  padding: 10px 0;
  top: 50px;
  border-radius: 5px;
  box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.5);
  color: var(--text-default);
}
.account-menu-container .account-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.account-menu-container .account-menu a {
  display: block;
  padding: 6px 18px;
  text-align: left;
  background: transparent;
  border: none;
  width: 100%;
  color: var(--text-default);
  text-decoration: none;
  font-weight: normal;
}
.account-menu-container .account-menu a:hover, .account-menu-container .account-menu a:focus {
  background: var(--bg-level2);
}
.account-menu-container .account-menu li label {
  margin: 4px 0 16px 0;
  font-size: 0.825rem;
  display: block;
  text-align: left;
  padding-left: 24px;
}
.account-menu-container .account-menu li label input {
  margin-right: 8px;
}
.account-menu-container .account-menu.hidden {
  display: none;
}

.card {
  display: block;
  position: relative;
  padding: var(--card-padding);
  height: 100%;
  text-align: left;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.05);
  /* frosted-glass */
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: var(--color-white);
}
.card .bookmark-btn {
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 0;
  color: var(--color-white);
  z-index: 2;
  width: 24px;
  height: 24px;
}
.card:hover > .bookmark-btn {
  opacity: 1;
}
.card h2, .card h3 {
  margin: 0;
}
.card h2 + p, .card h3 + p {
  margin: 0;
}
.card > a {
  color: var(--color-white);
  text-decoration: inherit;
}
.card p a, .card p .link-btn {
  font-size: inherit;
  line-height: inherit;
}
.card .card-label {
  flex: none;
  display: flex;
  gap: 4px;
  align-items: center;
  background: rgba(2, 9, 23, 0.7215686275);
  color: var(--color-white);
  padding: 8px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
  position: absolute;
  left: 12px;
  top: 12px;
}
.card .card-label.completed {
  padding: 12px;
}
.card.user-info:hover {
  outline: none;
}
.card.no-padding {
  padding: 0;
}
.card.transparent {
  border: none;
  background-color: transparent;
}
.card .progress-ring {
  margin-left: auto;
  margin-right: auto;
}
.card .progress-label {
  margin-top: 5px;
  font-weight: bold;
}
.card.horizontal-card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
}
.card.horizontal-card .avatar {
  width: 64px;
  height: 64px;
}
.card.horizontal-card .image {
  max-width: 180px;
}
.card.horizontal-card .image img {
  width: 100%;
}
.card.horizontal-card .card-body {
  gap: var(--card-gap);
  flex: 1;
}
.card.horizontal-card .card-body h3 {
  margin-bottom: 8px;
}
.card.horizontal-card .card-body .card-text {
  flex: 1;
}
.card.horizontal-card .card-body .card-text p + p {
  margin-top: 4px;
  margin-bottom: 0;
}
.card.horizontal-card .card-actions {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card .card-header {
  margin-bottom: 12px;
}
.card .card-header .flex-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@media (min-width: 808px) {
  .card .card-header .flex-row {
    flex-direction: row;
    gap: 10px;
  }
}
.card .card-header h2, .card .card-header .link-btn {
  margin-bottom: 12px;
}
.card .card-header .link-btn {
  font-size: 20px;
}
.card .card-header p {
  margin: 0;
}
.card .card-header p .link-btn {
  margin: 0;
}
.card .card-title {
  margin: 0;
}
.card .card-title .icon {
  vertical-align: middle;
  margin-right: 12px;
}
.card.edited {
  --icon-width: 0.8em;
}
.card.edited .card-title:after {
  content: "";
  background-image: url("../icons/Completion.svg");
  margin-left: 0.2em;
  padding-left: var(--icon-width);
  background-size: var(--icon-width) var(--icon-width);
  background-position: center center;
  background-repeat: no-repeat;
}
.card .card-subtitle {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: var(--text-default);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.card .card-meta {
  font-size: 14px;
  line-height: 18px;
  color: var(--text-muted);
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 0 0;
}
.card .card-meta span:not(:last-of-type):after {
  content: "•";
  margin: 0 5px;
}
.card .image img {
  width: 100%;
  max-width: 100%;
}
.card .card-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
  width: 100%;
}
.card .card-footer button button, .card .card-footer a button {
  white-space: nowrap;
}
.card .card-footer .link-btn {
  font-size: 20px;
}
.card .card-text {
  display: block;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-text-light);
  margin: 0;
}
.card .card-text:last-child {
  margin-bottom: 0;
}
.card .card-link {
  font-size: 18px;
  line-height: 24px;
  color: var(--color-teal);
}
.card .feature-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.card .feature-list li {
  -webkit-hyphens: auto;
          hyphens: auto;
  vertical-align: middle;
  white-space: nowrap;
}
.card .feature-list li span {
  vertical-align: bottom;
}
.card .feature-list li .circle-icon {
  color: var(--color-text-light);
}
.card .circle-icon {
  display: inline-block;
  width: 29px;
  height: 29px;
  background-color: var(--color-light-200);
  text-align: center;
  border-radius: 50%;
  color: var(--color-text-muted);
  line-height: 32px;
  margin-right: 8px;
  padding: 2px;
}
.card.dark {
  color: var(--color-white);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.2);
}
.card.dark * {
  color: var(--color-white);
}
.card.dark .image {
  border-radius: 12px;
}
.card.dark a:not(.button), .card.dark .link-btn, .card.dark .card-link {
  color: var(--text-light-dark-link, #9B81E2);
}
.card.dark .card {
  background-color: #272D39;
  border-color: #272D39;
}
.card.achievement-card {
  max-width: 120px;
  flex: 0 0 120px;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-align: center;
  border-radius: 16px;
  height: auto;
}
.card.achievement-card p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}
.card.achievement-card p.card-title {
  font-weight: 700;
  font-size: 14px;
}
.card.achievement-card .image {
  width: 42px;
  height: 42px;
  margin: 0 auto;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card.achievement-card.locked .card-info {
  opacity: 0.4;
}
.card.quest-card {
  background: var(--Transparent-40, rgba(0, 0, 0, 0.4));
  color: var(--color-white);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
  letter-spacing: 0.24px;
  gap: 4px;
  border-radius: 16px;
  border-color: transparent;
  justify-content: flex-start;
}
.card.quest-card .card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 8px 0;
}
.card.quest-card .image {
  display: block;
  border-radius: 8px;
  background: var(--transparent-light-24, rgba(254, 254, 254, 0.24));
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.card.quest-card .image img {
  display: block;
  width: 24px;
  margin: 3px auto 0 auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.card.quest-card .image.CC1, .card.quest-card .image.CC5, .card.quest-card .image.CC9 {
  background: var(--grad---blue, linear-gradient(149deg, #0CD6CB 20.97%, #597FFF 81.59%));
}
.card.quest-card .image.CC4, .card.quest-card .image.CC8 {
  background: var(--grad-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
}
.card.quest-card .image.CC2, .card.quest-card .image.CC6, .card.quest-card .image.CC13 {
  background: var(--grad---pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));
}
.card.quest-card .image.CC3, .card.quest-card .image.CC7, .card.quest-card .image.CC15 {
  background: var(--grad---yellow, linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%));
}
.card.snapshot {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.card.snapshot .number {
  font-size: 64px;
  line-height: 72px;
  font-weight: 600;
}
.card.snapshot .label {
  font-size: 24px;
  line-height: 28px;
}
.card .info-btn {
  position: absolute;
  right: 8px;
  top: 10px;
}
.card .info-btn .info-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: var(--color-light-200);
  text-align: center;
  border-radius: 50%;
  color: var(--color-text-muted);
  padding: 2px;
}
.card.overview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 24px;
}
.card.overview .label {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 40px;
  margin-bottom: 8px;
}
.card.overview .number {
  font-size: 48px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: auto;
}
.card.overview .footnote {
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}
.card.overview .button-link {
  margin-top: 40px;
}
.card.overview .graph-container {
  margin-top: 12px;
}
.card.overview .graph-container > div {
  margin-bottom: 12px;
}
.card.overview .graph-container > svg {
  width: 100%;
  height: auto;
}
.card.overview .graph-container.trend-up .chip-highlight svg {
  fill: var(--color-plum);
  position: relative;
  top: 3px;
  left: 2px;
}
.card.overview .graph-container.trend-down .chip-highlight svg {
  fill: var(--color-state-orange-dark);
  position: relative;
  top: 3px;
  left: 2px;
}
.card.video-card, .card.skill-card {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  min-width: 0;
  flex: 1 1 320px;
  padding: 24px;
  height: auto;
  text-decoration: none;
  color: var(--color-white);
  font-weight: normal;
  gap: 16px;
}
.card.video-card .video.image, .card.skill-card .video.image {
  padding: 0;
  margin: 0;
}
.card.video-card .video.image:after, .card.skill-card .video.image:after {
  width: 48px;
  height: 48px;
  display: block;
  background-image: url("../icons/play.svg");
  background-size: 100% 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.card.video-card .video.image .docked, .card.skill-card .video.image .docked {
  opacity: 0;
  pointer-events: none;
}
.card.video-card .video.image iframe, .card.skill-card .video.image iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card.video-card .video.image .play-icon, .card.skill-card .video.image .play-icon {
  width: 48px;
  height: 48px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  color: rgb(245, 245, 245);
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.card.video-card .video.image .play-icon:hover, .card.skill-card .video.image .play-icon:hover {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: rgb(1, 203, 183) 0px 0px 10px;
  text-shadow: rgb(1, 203, 183) 0px 0px 10px;
}
.card.video-card .image, .card.skill-card .image {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  max-height: 190px;
  width: 100%;
  background: rgb(2, 9, 23);
}
.card.video-card .image img, .card.skill-card .image img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s ease-in-out 0s;
  height: 140px;
  max-width: 100%;
}
.card.video-card .avatar, .card.skill-card .avatar {
  background-color: var(--bg-level1);
  color: var(--text-muted);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.card.video-card .avatar span, .card.skill-card .avatar span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.card.video-card .card-body, .card.skill-card .card-body {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}
.card.video-card .card-title, .card.skill-card .card-title {
  margin: 0 0 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: inherit;
}
.card.video-card .card-title:only-child, .card.skill-card .card-title:only-child {
  margin: 0;
}
.card.video-card .card-meta, .card.skill-card .card-meta {
  white-space: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  min-width: 0;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.card.video-card .bookmark-btn, .card.skill-card .bookmark-btn {
  top: 16px;
}
.card.video-card:hover .image img, .card.skill-card:hover .image img {
  opacity: 0.55;
  transform: scale(1.1);
}
.card.study-card .image.video {
  background: transparent;
  text-align: center;
}
.card.study-card .image.video img {
  width: auto;
  margin: auto;
}
.card.study-card .card-body .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border);
  background: var(--color-white);
}
.card.forage-card {
  height: 100%;
}
.card.forage-card .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-border);
}
.card.forage-card .forage-card-inner {
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
}
.card.forage-card:before {
  position: absolute;
  left: 16px;
  top: 16px;
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  background-color: var(--color-white);
  background-image: url("../icons/forage.png");
  background-repeat: no-repeat;
  background-position: 8px center;
  z-index: 11;
  background-size: 16px 16px;
  font-size: 14px;
  transition: width 0.15s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
}
.card.forage-card:hover:before {
  content: "Forage";
  width: 80px;
  padding-left: 32px;
  line-height: 32px;
  padding-right: 8px;
}
.card.saved .image img {
  opacity: 0.55;
}
.card.saved .bookmark-btn {
  opacity: 1;
}
.card.text-card {
  border-radius: 16px;
  padding: 16px 24px;
  position: relative;
}
.card.text-card .card-title {
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.card.text-card:hover, .card.text-card:focus {
  outline: none;
}
.card.text-card:hover:after, .card.text-card:focus:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit; /* !importanté */
  position: absolute;
  border-radius: 16px; /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
.card.mini-card {
  min-width: 0;
  width: 100%;
  padding: var(--card-padding-mini);
  position: relative;
  text-decoration: none;
  color: var(--color-white);
  font-weight: normal;
  border-radius: 16px;
}
.card.mini-card .bookmark-btn {
  width: 40px;
  height: 40px;
  top: 0;
  right: 4px;
  color: var(--text-default);
}
.card.mini-card .avatar {
  width: 41px;
  height: 41px;
  flex: none;
}
.card.mini-card .card-title {
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
}
.card.mini-card .job-details {
  display: flex;
  gap: 16px;
  align-items: center;
}
.card.mini-card p {
  margin: 0;
}
.card.mini-card .job-company {
  font-size: 14px;
  line-height: 18px;
}
.card.mini-card p.meta {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-wrap: wrap;
}
.card.mini-card p.meta span:not(:last-of-type):after {
  content: "•";
  margin: 0 5px;
}
.card.mini-card.saved .card-title {
  padding-right: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card.mini-card.saved .bookmark-btn {
  opacity: 1;
}
.card.mini-card:not(.no-results):hover, .card.mini-card:focus {
  outline: none;
}
.card.mini-card:not(.no-results):hover:after, .card.mini-card:focus:after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit; /* !importanté */
  position: absolute;
  border-radius: 16px; /*1*/
  border: 2px solid transparent; /*2*/
  background: var(--gradient-01-hover) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
.card.mini-card:not(.no-results):hover .bookmark-btn, .card.mini-card:focus .bookmark-btn {
  opacity: 1;
}
.card.mini-card:not(.no-results):hover .card-title, .card.mini-card:focus .card-title {
  text-decoration: underline;
}
.card .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex: none;
  background-color: var(--bg-level2);
  color: var(--text-muted);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.card .avatar span {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.card .avatar img {
  width: 100%;
}
.card .avatar.Aplus img {
  transform: scale(1.25);
}

.card-carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.card-carousel .prev-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  z-index: 11;
  display: none;
}
.card-carousel .next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  z-index: 11;
  display: none;
}
.card-carousel .card-container {
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.card-carousel .card-container > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: var(--column-gap);
  transition: transform 0.3s ease;
}
.card-carousel .card-container > ul > li {
  scroll-snap-align: start;
  flex-shrink: 0;
  flex: none;
}
@media (min-width: 1025px) {
  .card-carousel:not(.firstSlide):before {
    content: "";
    position: absolute;
    width: 80px;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, var(--color-light-bg), rgba(255, 255, 255, 0));
    z-index: 1;
  }
  .card-carousel:not(.lastSlide):after {
    content: "";
    position: absolute;
    width: 80px;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to left, var(--color-light-bg), rgba(255, 255, 255, 0));
    z-index: 1;
  }
  .card-carousel .prev-btn, .card-carousel .next-btn {
    display: block;
  }
  .card-carousel .prev-btn:disabled, .card-carousel .next-btn:disabled {
    opacity: 0;
  }
  .card-carousel .card-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .card-carousel .card-container ul {
    width: -moz-max-content;
    width: max-content;
  }
  .card-carousel .card-container ul li .card {
    height: 100%;
  }
  .card-carousel.dark-blue:not(.firstSlide):before {
    background: linear-gradient(to right, var(--color-dark-blue), rgba(255, 255, 255, 0));
  }
  .card-carousel.dark-blue:not(.lastSlide):after {
    background: linear-gradient(to left, var(--color-dark-blue), rgba(255, 255, 255, 0));
  }
  .card-carousel.dark-blue .prev-btn, .card-carousel.dark-blue .next-btn {
    color: var(--color-black);
  }
}

.card.skills .card-row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.split-card {
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-level0);
  border: 1px solid var(--color-border);
  border-radius: var(--card-corner-default);
}
.split-card h3 {
  margin: 0 0 24px 0;
}
.split-card .recommended-side {
  flex: 1;
  padding: var(--card-padding);
  border-top-left-radius: var(--card-corner-default);
  border-bottom-left-radius: var(--card-corner-default);
}
.split-card .recommended-side .card-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}
.split-card .recommended-side .card {
  max-width: 100%;
  flex: 1 1 calc(50% - 16px);
  min-width: 180px;
}
.split-card .recommended-side .card .image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  max-height: 90px;
}
.split-card .recommended-side .card .image img {
  height: 90px;
}
.split-card .recommended-side .card .card-info {
  min-width: 0;
}
.split-card .recommended-side .card .card-title {
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-width: 0;
}
.split-card .recommended-side .card .card-meta {
  display: none;
}
.split-card .resume-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  border-radius: 0;
  border-bottom-left-radius: var(--card-corner-default);
  border-bottom-right-radius: var(--card-corner-default);
  background: var(--bg-level2);
}
.split-card .resume-side .card.large {
  background-color: var(--bg-level1);
  border: 1px solid var(--color-border);
  flex: none;
  max-width: 100%;
  border-radius: var(--card-corner-default);
}
.split-card .resume-side .card.large .image {
  max-height: 200px;
}
.split-card .resume-side .card.large .image img {
  height: 200px;
}
.split-card .resume-side .card.large .progress-bar-container {
  padding: 8px 16px;
}
.split-card .resume-side .card.large .progress-bar-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  color: var(--text-muted);
}
.split-card .resume-side .card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding-bottom: 24px;
}
@media (max-width: 567px) {
  .split-card .card.skill-card .avatar {
    width: 24px;
    height: 24px;
  }
  .split-card .card.skill-card .card-title {
    display: -webkit-box;
    font-size: 18px;
    -webkit-hyphens: auto;
            hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    max-height: 50px; /* two lines*/
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}
@media (min-width: 414px) {
  .split-card .recommended-side .card-row .card .image {
    max-height: 60px;
  }
  .split-card .recommended-side .card-row .card .image img {
    height: 60px;
  }
}
@media (min-width: 568px) {
  .split-card {
    flex-direction: row;
  }
  .split-card .recommended-side .card-row {
    grid-gap: 16px;
  }
  .split-card .recommended-side .card-row .card .image {
    max-height: 90px;
  }
  .split-card .recommended-side .card-row .card .image img {
    height: 90px;
  }
  .split-card .resume-side {
    border-radius: 0;
    border-top-right-radius: var(--card-corner-default);
    border-bottom-right-radius: var(--card-corner-default);
  }
}

.card .jobs-carousel {
  margin-top: 20px;
}

.card-row {
  display: flex;
  flex-direction: column;
  gap: var(--column-gap);
  margin-top: 20px;
  padding-bottom: 12px;
  margin-bottom: -12px;
}
.card-row.gap-24 {
  gap: 24px;
}
.card-row.overflow-scroll {
  max-width: 100%;
  overflow-x: auto;
}
.card-row.overflow-wrap {
  flex-wrap: wrap;
  padding-bottom: 0px;
}
.card-row.study-group {
  flex-wrap: wrap;
  margin-top: 0;
}
.card-row.study-group .card {
  flex: 1 1 360px;
}
.card-row .card {
  flex: 1;
}
@media (min-width: 808px) {
  .card-row {
    flex-direction: row;
  }
  .card-row.one-two .card {
    height: auto;
  }
  .card-row .three-col {
    flex: 3;
  }
  .card-row .one-col {
    flex: 1;
  }
  .card-row .two-col {
    flex: 2;
  }
}

.card.dark .avatar {
  background-color: var(--color-black);
  color: var(--bg-level0);
}
.card.dark-gradient, .card.dark.action-card {
  background: linear-gradient(209deg, rgba(118, 14, 1, 0.4) 0%, rgba(109, 1, 118, 0) 71.12%), linear-gradient(0deg, rgba(37, 23, 78, 0.6) 0%, rgba(37, 23, 78, 0.6) 100%), #000;
  border-color: transparent;
}
.card.dark-gradient .avatar, .card.dark.action-card .avatar {
  background-color: var(--color-black);
  color: var(--bg-level0);
}
.card.dark-gradient.edited, .card.dark.action-card.edited {
  background: linear-gradient(209deg, rgba(118, 14, 1, 0.4) 0%, rgba(109, 1, 118, 0) 71.12%), linear-gradient(0deg, rgba(61, 74, 194, 0.6) 0%, rgba(61, 74, 194, 0.6) 100%), #000;
}
.card.dark-gradient .card, .card.dark.action-card .card {
  border-color: transparent;
  background: rgba(49, 20, 85, 0.8);
  color: var(--color-white);
}
.card.dark-gradient .card .card-title, .card.dark.action-card .card .card-title {
  color: var(--color-white);
}
.card.dark-gradient .card .job-company, .card.dark.action-card .card .job-company {
  color: #E0E7F0;
}
.card.dark-gradient .card p.meta, .card.dark.action-card .card p.meta {
  color: #A7BAD0;
}
.card.dark-gradient .card .bookmark-btn, .card.dark.action-card .card .bookmark-btn {
  color: var(--color-white);
}
.card.action-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  min-height: 360px;
}
.card.action-card .card-header {
  margin-bottom: 0;
}
.card.action-card .card-header .card-title, .card.action-card .card-header .link-btn {
  margin-bottom: 16px;
}
.card.action-card .card-header .link-btn {
  font-size: 22px;
  line-height: 24px;
}
.card.action-card.video-card {
  padding: var(--card-padding);
  max-width: 100%;
  color: var(--color-white);
}
.card.action-card.video-card .card-info {
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
}
.card.action-card.video-card .card-subtitle.author-name {
  color: inherit;
}
.card.action-card.video-card .progress-bar .bar {
  background: var(--gradient-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
}
.card.action-card.docked-image .card-body .image {
  max-width: 350px;
  width: 100%;
}
.card.action-card.docked-image .card-body .image img {
  display: block;
}
.card.action-card.docked-image.wide .card-body .image {
  max-width: 100%;
}
.card.action-card.wide .card-body .image {
  max-width: 800px;
}
.card.action-card.full-width .card-body {
  max-width: 100%;
}
.card.action-card.full-width .card-body .image {
  max-width: 100%;
}
.card.action-card .overflow-scroll {
  padding-bottom: 8px;
  scrollbar-color: #B070FF rgba(49, 20, 85, 0.8);
}
.card.action-card .card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
}
.card.action-card .card-body .image {
  max-width: 100%;
  width: 100%;
}
.card.action-card .card-body .image img {
  display: block;
}
.card.action-card .card-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: auto;
  margin: 32px auto 0 auto;
}
.card.action-card .card-footer button {
  width: 100%;
}
.card .progress-bar-container {
  margin: 0 0 16px 0;
  max-width: 600px;
}
.card .card-header .card-title, .card .card-header .link-btn {
  margin-bottom: 12px;
}

.stacked-card {
  display: flex;
  flex-direction: column;
}
.stacked-card .card-body {
  flex: 1;
}
.stacked-card.one-col .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stacked-card.one-col .card-body .image {
  max-width: 160px;
  margin: 0 auto;
}

.career-card {
  height: auto;
}
.career-card .card-header {
  margin-bottom: 24px;
}
.career-card h4 {
  margin: 0;
}
.career-card > .card-body {
  gap: 24px;
}
.career-card .one-col .card-header {
  margin-bottom: 24px;
}
.career-card .image {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 28px;
  border-radius: 8px;
}
.career-card .image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.career-card .outlook-item {
  display: block;
}
.career-card .outlook-item h5 {
  margin-top: 16px;
  margin-bottom: 4px;
}
.career-card .outlook-item h5:first-of-type {
  margin-top: 0;
}
.career-card .outlook-item p {
  margin: 0;
}
@media (min-width: 808px) {
  .career-card > .card-body {
    display: flex;
    align-items: stretch;
  }
  .career-card > .card-body .image {
    height: 100%;
    margin-bottom: 0;
  }
  .career-card .career-info {
    display: flex;
    flex-direction: column;
  }
  .career-card .career-info .card-body {
    flex: 1;
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
}
.flex-row .image img {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 808px) {
  .card .card-header .link-btn {
    white-space: nowrap;
  }
  .card.action-card .card-body .flex-row {
    flex-direction: row;
    align-items: flex-end;
    gap: var(--column-gap);
    margin-top: auto;
  }
  .card.action-card .card-body .flex-row .image {
    flex: 1;
  }
  .card.action-card .card-body .flex-row button {
    white-space: nowrap;
  }
  .intro .card.dark .card {
    min-width: 343px;
    max-width: 343px;
  }
}
@media (max-width: 567px) {
  .card.horizontal-card {
    flex-direction: column;
    align-items: center;
  }
  .card.horizontal-card .card-body {
    flex-direction: column;
  }
  .card.horizontal-card .card-text * {
    text-align: center;
  }
  .career-switcher.tab-container .tab-list {
    overflow-x: unset;
  }
  .career-fast-facts .facts-row {
    flex-direction: column;
  }
  .career-fast-facts .facts-row h6 {
    text-align: left;
  }
  .intro > .card-row {
    display: flex;
    flex-direction: column;
  }
  .card.skill-card {
    max-width: 100%;
  }
  .card.skill-card .card-title {
    padding-right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    min-width: 0;
    word-break: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}
@media (min-width: 568px) and (max-width: 807px) {
  .card.horizontal-card {
    flex-direction: column;
    align-items: center;
  }
  .card.horizontal-card .card-text * {
    text-align: center;
  }
  .career-switcher.tab-container .tab-list {
    overflow-x: unset;
  }
  .career-fast-facts .facts-row {
    flex-direction: column;
  }
  .career-fast-facts .facts-row h6 {
    text-align: left;
  }
  .intro > .card-row {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-gap: var(--column-gap);
  }
  .card.video-card .image, .card.skill-card .image {
    max-height: 100px;
  }
  .card.video-card .image img, .card.skill-card .image img {
    height: 100px;
  }
  .card.action-card .card-footer {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  .card.action-card .card-footer button {
    width: auto;
  }
  .jobs-group.card-row, .card-row.skills-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: var(--column-gap);
    overflow: hidden;
  }
  .jobs-group.card-row .card:nth-child(n+7), .card-row.skills-group .card:nth-child(n+7) {
    display: none;
  }
}
@media (min-width: 808px) {
  .jobs-group.card-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--column-gap);
    overflow: hidden;
    grid-template-rows: auto auto;
    grid-auto-rows: 0px;
    margin-bottom: calc(-1 * var(--column-gap));
  }
  .jobs-group.card-row.single-row {
    grid-template-rows: auto;
  }
  .card-row.skills-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--column-gap);
    overflow: hidden;
    grid-template-rows: auto;
    grid-auto-rows: 0px;
  }
  .card-row.skills-group.show-all {
    grid-auto-rows: auto;
  }
  .card-row.skills-group.show-all .card {
    height: 100%;
  }
  .card.action-card {
    padding-bottom: calc(var(--card-padding) + 48px + 32px);
  }
  .card.action-card.padding-even {
    padding: var(--card-padding);
  }
  .card.action-card.docked-image .card-body {
    justify-content: flex-end;
    align-items: center;
    max-width: 50%;
    margin-bottom: calc(-1 * (var(--card-padding) + 48px + 32px));
  }
  .card.action-card.docked-image.wide .card-body {
    align-items: flex-start;
    max-width: 75%;
    margin-bottom: calc(-1 * (var(--card-padding) + 48px));
  }
  .card.action-card.docked-image.wide .card-body .image {
    max-width: 1200px;
  }
  .card.action-card.docked-image.full-width .card-body {
    max-width: calc(100% + var(--card-padding) + var(--card-padding));
    width: calc(100% + var(--card-padding) + var(--card-padding));
    margin-left: calc(-1 * var(--card-padding));
    margin-right: calc(-1 * var(--card-padding));
  }
  .card.action-card .card-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: absolute;
    bottom: var(--card-padding);
    right: var(--card-padding);
    width: calc(100% - 2 * var(--card-padding));
  }
  .card.action-card .card-footer button {
    width: auto;
  }
}
@media (min-width: 808px) and (max-width: 1199px) {
  .card-row.skills-group:not(.show-all) .card {
    max-width: 100%;
  }
  .card-row.skills-group:not(.show-all) .card:nth-child(n+4) {
    display: none;
  }
}
@media (min-width: 1200px) {
  .jobs-group.card-row, .card-row.skills-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--column-gap);
    margin-bottom: 0;
  }
  .card.dark .card-header .flex-row {
    display: flex;
    gap: 24px;
  }
}
.sandbox section > .card {
  margin-bottom: 40px;
}

.image.skeleton {
  max-width: 455px !important;
  height: 175px;
  border-radius: 8px;
  opacity: 0.8;
  background: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-default);
  font-size: 2em;
  flex: none;
}
.image.skeleton span {
  text-align: center;
}

.chip-label {
  background: rgba(2, 9, 23, 0.0784313725);
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--text-default);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.24px;
  text-align: left;
  display: inline-block;
  transition: background-color 0.3s ease;
}
.chip-label.dark {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: #FEFEFE;
}
.chip-label.dark:hover {
  background: var(--Swatch-Light-300, #DDE3EE);
  color: var(--text-default);
}
.chip-label.dark.selected {
  color: var(--text-default);
  background-color: #FEFEFE;
}
.chip-label.light {
  color: var(--text-default);
  background-color: #FEFEFE;
}

.card.job-result-card:not(.active) .chip-label {
  background: rgba(2, 9, 23, 0.7215686275);
  color: var(--color-white);
}

/* starting ENTER animation */
.ctaCard-enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 11;
}
.ctaCard-enter * {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 200ms;
}
.ctaCard-enter.with-delay {
  transition-delay: 800ms;
}

/* ending ENTER animation */
.ctaCard-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}
.ctaCard-enter-active * {
  opacity: 1;
}

/* starting EXIT animation */
.ctaCard-exit {
  opacity: 1;
}

/* ending EXIT animation */
.ctaCard-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

/* starting ENTER animation */
.jobsCard-enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 11;
}

/* ending ENTER animation */
.jobsCard-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 250ms;
}

/* starting EXIT animation */
.jobsCard-exit {
  opacity: 1;
}

/* ending EXIT animation */
.jobsCard-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  transition-delay: 250ms;
}

.card:not(.dark) .card-footer .button.filled.light {
  outline: 1px solid var(--border-dark-theme-border, #393F4A);
}

.progress-bar-container {
  padding: 0 8px;
}
.progress-bar-container .progress-bar {
  position: relative;
  width: 100%;
  height: 9px;
  border-radius: 30px;
  background: #969696;
  margin-bottom: 8px;
}
.progress-bar-container .progress-bar .bar {
  height: 100%;
  background: linear-gradient(175deg, #9dd6ff 0%, rgba(141, 65, 238, 0) 50%), linear-gradient(218deg, #7041ee, #8d41ee, #d641ee), #8d41ee;
  border-radius: 30px;
}
.progress-bar-container .progress-bar-label {
  display: block;
  font-weight: 500;
  margin-top: 6px;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-weight: normal;
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

input,
textarea {
  font: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
}

.gr-input {
  display: block;
  margin: 4px 0;
  padding: 0 12px;
  height: 44px;
  color: var(--color-text-light);
  border: 1px solid var(--color-border);
  background: var(--color-white);
  border-radius: var(--input-corner, 4px);
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
}

.gr-input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-input:disabled {
  color: var(--color-border);
  background-color: var(--color-light-grey);
}

.gr-input:-moz-read-only {
  border: 0;
}

.gr-input:read-only {
  border: 0;
}

.gr-input + .error-state, .gr-textarea + .error-state, .gr-checkbox + .error-state, .gr-radio + .error-state, .gr-select-container + .error-state, .gr-select-container + .error-state {
  display: none;
}

.gr-input.error, .gr-textarea.error, .gr-checkbox.error, .gr-radio.error, .gr-select-container.error, .gr-select-container.error {
  display: flex;
  align-items: center;
  border-color: #DB0020;
}

.gr-input.error + .error-state, .gr-textarea.error + .error-state, .gr-checkbox.error + .error-state, .gr-radio.error + .error-state, .gr-select-container.error + .error-state, .gr-select-container.error + .error-state {
  display: flex;
  align-items: center;
}

.gr-input.error + .error-state svg, .gr-textarea.error + .error-state svg, .gr-checkbox.error + .error-state svg, .gr-radio.error + .error-state svg, .gr-select-container.error + .error-state svg, .gr-select-container.error + .error-state svg {
  fill: #DB0020;
}

.gr-input.error + .error-state span, .gr-textarea.error + .error-state span, .gr-checkbox.error + .error-state span, .gr-radio.error + .error-state span, .gr-select-container.error + .error-state span, .gr-select-container.error + .error-state span {
  margin-left: 8px;
  color: #DB0020;
}

.gr-textarea {
  display: block;
  margin: 4px 0;
  padding: 12px;
  color: var(--color-text-light);
  border: 1px solid var(--color-border);
  background: var(--color-white);
  border-radius: var(--input-corner);
  font-size: 14px;
  width: 100%;
  max-width: 100%;
  min-height: 80px;
  line-height: 1.5;
}

.gr-textarea:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-textarea:disabled {
  color: var(--color-border);
  background-color: #E9E9E9;
}

.gr-textarea:-moz-read-only {
  border: 0;
}

.gr-textarea:read-only {
  border: 0;
}

.gr-checkbox {
  margin-bottom: 14px;
  min-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.gr-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.gr-checkbox input[type=checkbox] ~ label {
  display: inline-block;
  line-height: 1.5;
  min-height: 24px;
  padding-left: 2.5em;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-size: 14px;
}

.gr-checkbox input[type=checkbox] ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid var(--color-border);
  background: var(--color-white);
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.gr-checkbox input[type=checkbox]:disabled ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid var(--color-border);
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: #E9E9E9;
}

.gr-checkbox input[type=checkbox]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-checkbox input[type=checkbox] ~ span svg {
  height: 24px;
  opacity: 0;
  width: 24px;
  top: -3px;
  position: relative;
  left: -3px;
  fill: #007FA3;
}

.gr-checkbox input[type=checkbox]:disabled ~ span svg {
  fill: var(--color-border);
}

.gr-checkbox input[type=checkbox]:checked ~ span svg {
  opacity: 1;
}

.gr-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.gr-fieldset legend, .legend {
  padding: 0px;
  position: relative;
  color: rgb(2, 9, 23);
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}

.recommended {
  margin-top: 8px;
}

.recommended .secondary-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.24px;
  color: var(--text-muted, #60646D);
  margin-bottom: 8px;
}

.chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}
.chip-list .chip-btn {
  margin: 0 4px;
}

.chip-btn {
  display: inline-flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  color: var(--text-default);
  margin: 0 4px;
  text-decoration: none;
}
.chip-btnbutton {
  cursor: pointer;
}
.chip-btn.search-filter-chip {
  padding: 4px 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #60646D;
  gap: 4px;
  border-radius: 4px;
}
.chip-btn.search-filter-chip.with-remove {
  padding: 4px 4px 4px 8px;
}
.chip-btn.search-filter-chip .chip-btn-toggle-text {
  background: transparent;
  border: none;
  padding: 0 4px;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #60646D;
  border-radius: 4px;
}
.chip-btn.search-filter-chip .chip-btn-toggle-text:hover {
  text-decoration: underline;
}
.chip-btn.search-filter-chip .icon-btn {
  min-width: 24px;
  min-height: 24px;
  color: #60646D;
}
.chip-btn.search-filter-chip .icon-btn:hover, .chip-btn.search-filter-chip .icon-btn:focus {
  background: rgba(2, 9, 23, 0.0784313725);
}
.chip-btn.search-filter-chip.selected {
  background-color: #05112A;
  border-color: #05112A;
  color: var(--color-white);
  font-weight: 700;
}
.chip-btn.search-filter-chip.selected .chip-btn-toggle-text {
  color: var(--color-white);
  font-weight: 700;
}
.chip-btn.search-filter-chip.selected .icon-btn {
  color: var(--color-white);
}
.chip-btn.search-filter-chip.selected .icon-btn:hover, .chip-btn.search-filter-chip.selected .icon-btn:focus {
  background: rgba(254, 254, 254, 0.2);
}
.chip-btn.dark {
  background: rgba(255, 255, 255, 0.1);
  color: #FEFEFE;
}
.chip-btn.dark button, .chip-btn.dark span {
  color: #FEFEFE;
}
.chip-btn.dark svg {
  vertical-align: text-bottom;
}
.chip-btn.dark:hover, .chip-btn.dark:focus {
  background: #DDE3EE;
  color: #020917;
}
.chip-btn.dark:hover span, .chip-btn.dark:focus span {
  color: #020917;
}
.chip-btn:hover, .chip-btn:focus {
  background: #DDE3EE;
  color: #020917;
}
.chip-btn:hover span, .chip-btn:focus span {
  color: #020917;
}
.chip-btn[aria-pressed=true], .chip-btn.selected {
  background: #FEFEFE;
  color: #020917;
}

.gr-radio {
  margin-bottom: 14px;
  min-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.gr-radio input[type=radio] {
  opacity: 0;
  position: absolute;
}

.gr-radio input[type=radio]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-radio input[type=radio] + label {
  display: inline-block;
  line-height: 18px;
  padding-left: 28px;
  font-size: 14px;
  cursor: pointer;
}

.gr-radio input[type=radio] ~ span {
  -webkit-box-sizing: content-box;
  border: 2px solid var(--checkbox-border);
  background: var(--color-white);
  border-radius: 50%;
  box-sizing: content-box;
  color: var(--checkbox-checked);
  display: block;
  height: 5px;
  left: 0;
  padding: 3px 6px 6px 3px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 5px;
}

.gr-radio input[type=radio] ~ span svg {
  height: 18px;
  opacity: 0;
  width: 18px;
}

.gr-radio input[type=radio]:checked ~ span svg {
  opacity: 1;
  top: -5px;
  position: relative;
  left: -5px;
  fill: var(--checkbox-checked);
}

.gr-radio input[type=radio]:disabled ~ span svg {
  opacity: 1;
  fill: var(--color-border);
  top: -5px;
  left: -5px;
  position: relative;
}

.radio-option {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 16px;
  border-radius: 8px;
  text-align: left;
}
.radio-option:first-of-type {
  margin-top: 0;
}
.radio-option input[type=radio] {
  opacity: 0;
  position: absolute;
}
.radio-option input[type=radio] ~ span {
  border: 2px solid rgba(255, 255, 255, 0.4);
  background: transparent;
  border-radius: 50%;
  color: #E5067C;
  display: block;
  height: 18px;
  width: 18px;
  pointer-events: none;
  position: absolute;
  top: 18px;
  left: 16px;
}
.radio-option input[type=radio] ~ span svg {
  position: absolute;
  left: -2px;
  top: -2px;
  fill: currentColor;
  opacity: 0;
}
.radio-option input[type=radio]:focus ~ span {
  outline: 1px solid var(--color-white);
  outline-offset: 2px;
}
.radio-option input[type=radio]:checked ~ span {
  border-color: #E5067C;
}
.radio-option input[type=radio]:checked ~ span svg {
  opacity: 1;
}
.radio-option input[type=radio]:hover ~ span:after {
  content: "";
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.radio-option label {
  font-size: 18px;
  line-height: 24px;
  padding: 16px;
  padding-left: 48px;
  cursor: pointer;
  width: 100%;
  display: block;
}

.gr-select-container {
  position: relative;
}

.gr-select-container svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 9px);
  fill: var(--color-text-light);
}

.gr-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  margin: 4px 0;
  padding: 0 12px;
  padding-right: 42px;
  height: 44px;
  color: var(--color-text-light);
  border: 1px solid var(--color-border);
  background: var(--color-white);
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
}

.gr-select:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px #1977D4;
}

.gr-select:disabled {
  color: var(--color-border);
  background-color: #E9E9E9;
}

.gr-select:disabled + svg {
  fill: var(--color-border);
}

.gr-select[multiple] {
  height: auto;
}

.gr-select[multiple] option {
  cursor: pointer;
}

/* HED styles */
/* check-box-list.component.less */
.checkbox-group {
  margin-left: 8px;
}

.checkbox {
  padding: 6px 0;
}

.check-box-list label,
.checkbox label {
  display: flex;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.24px;
  color: var(--text-default);
  align-items: baseline;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}

.check-box-list label:not([class*=-inline]) + label,
.checkbox label:not([class*=-inline]) + label {
  margin-top: 1rem;
}

.check-box-list label[class*=-inline],
.checkbox label[class*=-inline] {
  display: inline-flex;
  margin: 0 0.625rem 0.625rem 0;
}

.check-box-list label[class*=-inline]:only-of-type,
.checkbox label[class*=-inline]:only-of-type,
.check-box-list label[class*=-inline]:last-of-type,
.checkbox label[class*=-inline]:last-of-type {
  margin-bottom: 0;
}

.small-controls.check-box-list label,
.small-controls.checkbox label {
  font-size: var(--typesize-minor);
}

.form-inverse .check-box-list label,
.form-inverse .checkbox label {
  color: var(--color-white);
}

.check-box-list [type=checkbox],
.checkbox [type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  aspect-ratio: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  outline-width: 0.125em;
  outline-style: solid;
  outline-color: var(--checkbox-border);
  margin: 0 0.8125rem 0 0;
  position: relative;
  transition: all 0.125s ease-in-out;
  border-radius: 0.125em;
  transform: translate(2px, 2px);
}

.check-box-list [type=checkbox]::before,
.checkbox [type=checkbox]::before {
  display: block;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background-color: var(--color-light-300);
  flex: none;
  margin: auto;
  position: absolute;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: all 0.25s ease-in-out;
  outline-color: transparent;
  outline-style: solid;
  outline-width: 0.1875em;
  outline-offset: -0.1875em;
}

.small-controls.check-box-list [type=checkbox]::before,
.small-controls.checkbox [type=checkbox]::before {
  width: 2em;
  height: 2em;
}

.form-inverse .check-box-list [type=checkbox]::before,
.form-inverse .checkbox [type=checkbox]::before {
  background-color: var(--color--ui-04);
}

.check-box-list [type=checkbox]:hover:not([disabled]),
.checkbox [type=checkbox]:hover:not([disabled]) {
  outline-color: var(--text-default);
}

.check-box-list [type=checkbox]:hover:not([disabled])::before,
.checkbox [type=checkbox]:hover:not([disabled])::before {
  transform-origin: center;
  transform: scale(1);
  opacity: 1;
}

.check-box-list [type=checkbox]:focus:not([disabled]),
.checkbox [type=checkbox]:focus:not([disabled]) {
  outline-color: var(--checkbox-border);
}

.check-box-list [type=checkbox]:focus:not([disabled])::before,
.checkbox [type=checkbox]:focus:not([disabled])::before {
  background-color: transparent;
  transform-origin: center;
  transform: scale(1);
  opacity: 1;
  outline-color: var(--color--interactive-focus-field);
}

.small-controls.check-box-list [type=checkbox],
.small-controls.checkbox [type=checkbox] {
  width: 1rem;
  height: 1rem;
  outline-width: 0.125rem;
  outline-offset: -0.125rem;
}

.small-controls.check-box-list [type=checkbox]:focus,
.small-controls.checkbox [type=checkbox]:focus,
.small-controls.check-box-list [type=checkbox]:focus-visible,
.small-controls.checkbox [type=checkbox]:focus-visible {
  outline-offset: -0.125rem;
}

.check-box-list [type=checkbox]:checked,
.checkbox [type=checkbox]:checked {
  outline-color: var(--checkbox-checked);
}

.check-box-list [type=checkbox]:checked:hover,
.checkbox [type=checkbox]:checked:hover {
  outline-color: var(--checkbox-border);
}

.check-box-list [type=checkbox]:focus,
.checkbox [type=checkbox]:focus,
.check-box-list [type=checkbox]:focus-visible,
.checkbox [type=checkbox]:focus-visible {
  outline-color: var(--color--interactive-focus-field);
  outline-offset: -0.0525em;
}

.check-box-list [type=checkbox][required][aria-checked=false][aria-invalid=false],
.checkbox [type=checkbox][required][aria-checked=false][aria-invalid=false],
.has-error.check-box-list [type=checkbox],
.has-error.checkbox [type=checkbox],
.has-error .check-box-list [type=checkbox],
.has-error .checkbox [type=checkbox] {
  outline-color: var(--color--alert-error);
}

.check-box-list [type=checkbox]:focus,
.checkbox [type=checkbox]:focus {
  outline-offset: unset;
}

.check-box-list [type=checkbox]:checked:not([disabled]),
.checkbox [type=checkbox]:checked:not([disabled]) {
  background: var(--checkbox-checked);
}

.has-error.check-box-list [type=checkbox]:checked:not([disabled]),
.has-error.checkbox [type=checkbox]:checked:not([disabled]),
.has-error .check-box-list [type=checkbox]:checked:not([disabled]),
.has-error .checkbox [type=checkbox]:checked:not([disabled]) {
  background: var(--color--alert-error);
}

.form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]),
.form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]),
.form-inverse .has-error .check-box-list [type=checkbox]:checked:not([disabled]),
.form-inverse .has-error .checkbox [type=checkbox]:checked:not([disabled]) {
  background: var(--color--alert-error-inverse);
}

.check-box-list [type=checkbox]:checked:not([disabled]):focus,
.checkbox [type=checkbox]:checked:not([disabled]):focus {
  outline-color: var(--checkbox-checked);
}

.has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
.has-error.checkbox [type=checkbox]:checked:not([disabled]):focus,
.has-error .check-box-list [type=checkbox]:checked:not([disabled]):focus,
.has-error .checkbox [type=checkbox]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error);
}

.form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
.form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]):focus,
.form-inverse .has-error .check-box-list [type=checkbox]:checked:not([disabled]):focus,
.form-inverse .has-error .checkbox [type=checkbox]:checked:not([disabled]):focus {
  outline-color: var(--color--alert-error-inverse);
}

.check-box-list [type=checkbox]:checked::after,
.checkbox [type=checkbox]:checked::after {
  display: inline-block;
  line-height: inherit;
  content: "";
  flex: none;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 14px;
  aspect-ratio: 1;
  -webkit-mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  mask-image: url("../../assets/icons/sprite-controls.svg#icon-check");
  width: 0.875em;
  background: var(--color-white);
  margin: auto;
  position: absolute;
}

.check-box-list [type=checkbox][disabled],
.checkbox [type=checkbox][disabled] {
  color: var(--color--interactive-disabled-02);
  background-color: var(--color--ui-01);
  outline-color: var(--color--interactive-disabled-02);
  pointer-events: none;
}

.check-box-list [type=checkbox][disabled]::-moz-placeholder, .checkbox [type=checkbox][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-02);
}

.check-box-list [type=checkbox][disabled]::placeholder,
.checkbox [type=checkbox][disabled]::placeholder {
  color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled],
.form-inverse .checkbox [type=checkbox][disabled] {
  color: var(--color--interactive-disabled-01);
  background-color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled]::-moz-placeholder, .form-inverse .checkbox [type=checkbox][disabled]::-moz-placeholder {
  color: var(--color--interactive-disabled-01);
}

.form-inverse .check-box-list [type=checkbox][disabled]::placeholder,
.form-inverse .checkbox [type=checkbox][disabled]::placeholder {
  color: var(--color--interactive-disabled-01);
}

.check-box-list [type=checkbox][disabled]:checked,
.checkbox [type=checkbox][disabled]:checked {
  outline-color: var(--color--ui-01);
}

.check-box-list [type=checkbox][disabled]:checked::after,
.checkbox [type=checkbox][disabled]:checked::after {
  background-color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled],
.form-inverse .checkbox [type=checkbox][disabled] {
  outline-color: var(--color--interactive-disabled-03);
}

.form-inverse .check-box-list [type=checkbox][disabled]:checked,
.form-inverse .checkbox [type=checkbox][disabled]:checked {
  outline-color: var(--color--interactive-disabled-02);
}

.form-inverse .check-box-list [type=checkbox][disabled]:checked::after,
.form-inverse .checkbox [type=checkbox][disabled]:checked::after {
  background: var(--color--interactive-disabled-03);
}

.small-controls.check-box-list [type=checkbox]:checked::after,
.small-controls.checkbox [type=checkbox]:checked::after {
  width: 0.75rem;
  height: 0.75rem;
}

@supports selector(:focus-visible) {
  .check-box-list [type=checkbox],
  .checkbox [type=checkbox] {
    box-sizing: content-box;
    outline-color: var(--checkbox-border);
  }
  .check-box-list [type=checkbox]:hover:not([disabled]),
  .checkbox [type=checkbox]:hover:not([disabled]) {
    outline-color: var(--text-default);
  }
  .check-box-list [type=checkbox]:focus:not([disabled]),
  .checkbox [type=checkbox]:focus:not([disabled]) {
    outline-color: var(--color--border-02);
  }
  .check-box-list [type=checkbox]:focus:not([disabled])::before,
  .checkbox [type=checkbox]:focus:not([disabled])::before {
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
  }
  .check-box-list [type=checkbox]:checked:not([disabled]),
  .checkbox [type=checkbox]:checked:not([disabled]) {
    outline-color: var(--checkbox-checked);
  }
  .has-error.check-box-list [type=checkbox]:checked:not([disabled]),
  .has-error.checkbox [type=checkbox]:checked:not([disabled]) {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]),
  .form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]) {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type=checkbox]:checked:not([disabled]):focus,
  .checkbox [type=checkbox]:checked:not([disabled]):focus {
    outline-color: var(--checkbox-checked);
  }
  .has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
  .has-error.checkbox [type=checkbox]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error);
  }
  .form-inverse .has-error.check-box-list [type=checkbox]:checked:not([disabled]):focus,
  .form-inverse .has-error.checkbox [type=checkbox]:checked:not([disabled]):focus {
    outline-color: var(--color--alert-error-inverse);
  }
  .check-box-list [type=checkbox]:checked:not([disabled]):hover,
  .checkbox [type=checkbox]:checked:not([disabled]):hover {
    outline-color: var(--color--interactive-primary);
  }
  .has-error.check-box-list [type=checkbox]:checked:not([disabled]):hover,
  .has-error.checkbox [type=checkbox]:checked:not([disabled]):hover,
  .has-error .check-box-list [type=checkbox]:checked:not([disabled]):hover,
  .has-error .checkbox [type=checkbox]:checked:not([disabled]):hover {
    outline-color: var(--color--alert-error);
  }
  .check-box-list [type=checkbox][disabled],
  .checkbox [type=checkbox][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-02);
  }
  .form-inverse .check-box-list [type=checkbox][disabled],
  .form-inverse .checkbox [type=checkbox][disabled] {
    outline-color: transparent;
    border-color: var(--color--interactive-disabled-03);
  }
  .small-controls.check-box-list [type=checkbox],
  .small-controls.checkbox [type=checkbox] {
    width: 1rem;
    height: 1rem;
    outline: unset;
    box-sizing: border-box;
    border-width: 0.14285714em;
  }
  .small-controls.check-box-list [type=checkbox]:focus,
  .small-controls.checkbox [type=checkbox]:focus,
  .small-controls.check-box-list [type=checkbox]:focus-visible,
  .small-controls.checkbox [type=checkbox]:focus-visible {
    outline: unset;
  }
  .small-controls.check-box-list [type=checkbox]:checked::after,
  .small-controls.checkbox [type=checkbox]:checked::after {
    transform: scale(1.15);
  }
  .check-box-list [type=checkbox][required][aria-checked=false][aria-invalid=false],
  .checkbox [type=checkbox][required][aria-checked=false][aria-invalid=false],
  .has-error.check-box-list [type=checkbox],
  .has-error.checkbox [type=checkbox],
  .has-error .check-box-list [type=checkbox],
  .has-error .checkbox [type=checkbox] {
    border-color: var(--color--alert-error);
  }
}
.filter-menu {
  position: relative;
  z-index: 12;
}
.filter-menu button {
  border-radius: 8px;
  padding: 4px 12px;
}
.filter-menu button[aria-expanded=true] {
  background-color: black;
  color: white;
}
.filter-menu .menu {
  position: absolute;
  z-index: 1111;
  right: 0;
  top: calc(100% + 4px);
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 200px;
}
.filter-menu .menu .form-input {
  margin: 1em 0;
}
.filter-menu.career-fields-dropdown {
  width: 100%;
  text-align: right;
}
.filter-menu.career-fields-dropdown > .button {
  margin-left: auto;
}
.filter-menu.career-fields-dropdown .menu {
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  max-width: calc(100vw - 80px);
}
.filter-menu.career-fields-dropdown .menu button {
  text-align: left;
}

.dropdown {
  position: relative;
  z-index: 12;
}
.dropdown.open {
  z-index: 13;
}
.dropdown .menu {
  position: absolute;
  z-index: 1111;
  left: 0;
  top: calc(100% + 6px);
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1215686275), 0px 2px 2px 0px rgba(0, 0, 0, 0.1411764706), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  color: var(--text-default);
  font-size: 18px;
}
.dropdown .menu span, .dropdown .menu label {
  font-size: 1rem;
}
.dropdown .menu .form-input {
  margin: 1em 0;
}
.dropdown .menu .menu-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
}
.dropdown .menu .menu-footer button {
  white-space: nowrap;
}

.search-container, .search-input {
  position: relative;
  width: 100%;
}
.search-container .gr-input, .search-input .gr-input {
  border: 1px solid var(--color-border);
  color: var(--text-default);
  border-radius: 24px;
  padding: 8px 16px 8px 44px;
  max-width: 490px;
  height: 44px;
}
.search-container .gr-input::-moz-placeholder, .search-input .gr-input::-moz-placeholder {
  color: var(--text-muted);
}
.search-container .gr-input::placeholder, .search-input .gr-input::placeholder {
  color: var(--text-muted);
}
.search-container .search-icon, .search-input .search-icon {
  position: absolute;
  color: var(--text-default);
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}
.search-container .clear-icon, .search-input .clear-icon {
  position: absolute;
  color: var(--text-default);
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}

.autocomplete-container {
  position: relative;
}
.autocomplete-container .gr-input {
  max-width: 100%;
  text-overflow: ellipsis;
  padding-right: 48px;
}

.autocomplete-list {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  z-index: 111;
  max-height: 280px;
  overflow-y: auto;
}
.autocomplete-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.autocomplete-list ul button {
  padding: 16px;
  text-align: left;
  text-transform: capitalize;
  background: transparent;
  border: none;
  width: 100%;
  color: var(--text-default);
}
.autocomplete-list ul button:hover, .autocomplete-list ul button:focus {
  background: var(--bg-level2);
}
.autocomplete-list ul li:not(:last-of-type) {
  border-bottom: 1px solid var(--color-border);
}

.company-list {
  max-width: 570px;
  width: 100%;
}
.company-list .autocomplete-list button {
  text-transform: uppercase;
}

.form-input.password {
  position: relative;
}
.form-input.password .show-password {
  position: absolute;
  right: 12px;
  bottom: 14px;
}
.form-input.password input {
  padding-right: 40px;
}

.slider-container {
  position: relative;
  padding-bottom: 0.5em;
  width: 100%;
}
.slider-container label {
  display: block;
  font-size: 1.125rem !important;
  margin-bottom: 4px;
}
.slider-container .slider {
  -webkit-appearance: none; /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  height: 4px; /* Specified height */
  background: #B2B6BF; /* Grey background */
  outline: none; /* Remove outline */
  border-radius: 4px;
}
.slider-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 16px; /* Set a specific slider handle width */
  height: 16px; /* Slider handle height */
  border-radius: 24px;
  border: 1px solid var(--Swatch-Light-600, #BCC1CB);
  background: var(--background-light-theme-bg-level-0, #FEFEFE);
  cursor: pointer; /* Cursor on hover */
}
.slider-container .slider::-moz-range-thumb {
  width: 16px; /* Set a specific slider handle width */
  height: 16px; /* Slider handle height */
  border-radius: 24px;
  border: 1px solid var(--Swatch-Light-600, #BCC1CB);
  background: var(--background-light-theme-bg-level-0, #FEFEFE);
  cursor: pointer; /* Cursor on hover */
}
.slider-container .min {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1rem;
}
.slider-container .max {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1rem;
}

.horizontal-navigation {
  z-index: 1111;
  width: 100%;
  color: var(--color-white);
}
.horizontal-navigation .tab-container .tab-list {
  overflow-x: auto;
  display: flex;
  margin-bottom: 0;
}

.toggle button {
  font-size: 24px;
  position: relative;
  display: block;
  width: 64px;
  height: 32px;
  padding: 0;
  border: 0;
  border-radius: 32px;
  margin: 0;
  background-color: var(--color-light-300);
  color: var(--color-dark-600);
  cursor: pointer;
  transition: background-color 0.3s;
  overflow: visible;
  min-width: 64px;
}
.toggle button:before {
  content: "";
  background: var(--color-white);
  display: block;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  transition: left 0.3s;
  border-radius: 100%;
  z-index: 2;
}
.toggle button .off {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  display: flex;
  transition: opacity 0.15s ease;
}
.toggle button .on {
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  display: flex;
  opacity: 0;
  transition: opacity 0.15s ease;
}
.toggle button[aria-checked=true]:before {
  left: calc(50% - 2px);
}
.toggle button[aria-checked=true] .off {
  opacity: 0;
}
.toggle button[aria-checked=true] .on {
  opacity: 1;
}

.mode-toggle-container {
  display: flex;
  align-items: center;
  gap: 16px;
}
.mode-toggle-container .mode-toggle svg {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: 1;
}
.mode-toggle-container .mode-toggle svg.sun {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.mode-toggle-container .mode-toggle svg.moon {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.mode-toggle-container .toggle-label {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--text-muted);
  font-weight: 400;
}
.mode-toggle-container .toggle-label.right {
  text-align: left;
  min-width: 50px;
}
.mode-toggle-container .toggle-label.left {
  text-align: right;
  min-width: 50px;
}
.mode-toggle-container .toggle-label.selected {
  font-weight: 600;
  color: var(--color-default);
}

.dark-mode .toggle button {
  background: var(--background-dark-theme-bg-level-1, #161C29);
}
.dark-mode .toggle button:before {
  background-color: #393F4A;
}

body {
  background-color: var(--bg-level2);
  margin: 0;
  padding: 0;
}

.page-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1111;
}

.page-wrapper {
  --chatWidth: 469px;
}
.page-wrapper main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  background: var(--purple-gradient, linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0) 55.93%), #05112A);
}
.page-wrapper main .content-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.page-wrapper main .content-container .floating-nav {
  position: absolute;
  left: 24px;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
  z-index: 111;
  width: 100%;
  padding-right: 160px;
}
.page-wrapper main .content-container .floating-nav .game-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  position: relative;
}
.page-wrapper main .content-container .floating-nav .game-actions .nav-indicator {
  position: absolute;
  top: -12px;
  transition: all 0.3s ease-in-out;
  left: 25%;
  width: 15px;
  height: 9px;
  z-index: 3;
}
.page-wrapper main .content-container .floating-nav .game-actions .nav-indicator img {
  display: block;
}
.page-wrapper main .content-container .floating-nav .game-actions .nav-indicator.profile {
  left: 25%;
  transform: translateX(-10px);
}
.page-wrapper main .content-container .floating-nav .game-actions .nav-indicator.map {
  left: 75%;
  transform: translateX(-5px);
}
.page-wrapper main .content-container .achievements-nav-container {
  position: relative;
  border-radius: 16px;
  background: var(--Transparent-40, rgba(0, 0, 0, 0.4));
  padding: 12px;
  display: flex;
  flex-direction: row;
  color: var(--color-white);
  min-height: 114px;
}
.page-wrapper main .content-container .achievements-nav-container h3 {
  margin: 0 0 8px 0;
  text-align: center;
}
.page-wrapper main .content-container .achievements-nav-container .achievements-btn h3 {
  margin-bottom: 12px;
}
.page-wrapper main .content-container .achievements-nav-container .quest-container {
  border-left: 1px solid var(--color-white);
  padding-left: 12px;
  margin-left: 12px;
  min-width: 88px;
}
.page-wrapper main .content-container .achievements-nav-container .quest-container .quest-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.page-wrapper main .content-container .achievements-nav-container .tooltip-container {
  position: absolute;
  top: -56px;
  right: 0;
}
.page-wrapper main .content-container .achievements-nav-container .tooltip-container .tooltip {
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  background: var(--Transparent-fill, rgba(0, 0, 0, 0.25));
  padding: 12px 24px;
  /* frosted-glass */
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.24px;
  color: var(--color-white);
  width: -moz-max-content;
  width: max-content;
}
.page-wrapper main .content-container .tab-panel {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.page-wrapper main .content-container .tab-panel h1 {
  margin-top: 0;
}
.page-wrapper main .content-container .tab-panel.hidden {
  display: none;
}
.page-wrapper main .content-container .map {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.page-wrapper main .content-container .floating-tabs {
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 111;
}
.page-wrapper main .content-container .floating-tabs .tab-list-container {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}
.page-wrapper main .content-container .floating-tabs .tab-list-container button {
  height: 36px;
  padding: 8px 24px;
  background: white;
  border: 1px solid var(--color-dark-600);
}
.page-wrapper main .content-container .floating-tabs .tab-list-container li:first-of-type button {
  border-radius: 15px 0 0 15px;
}
.page-wrapper main .content-container .floating-tabs .tab-list-container li:last-of-type button {
  border-radius: 0 15px 15px 0;
}
.page-wrapper main .chatbot-container {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10000;
}
.page-wrapper main .group {
  margin-top: 24px;
}
.page-wrapper main .group .group-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.24px;
}
.page-wrapper.chat-open .floating-nav {
  padding-right: calc(56px + var(--chatWidth)) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation: 0.4s ease-in-out fadeIn;
}

.chatbot-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 70px - 32px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: var(--purple-gradient, linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0) 55.93%), #05112A);
  color: var(--color-white);
  max-width: var(--chatWidth);
  width: 100%;
  animation: fadeIn 0.2s ease-out forwards;
  z-index: 12000;
}
.chatbot-container .chat-area {
  width: 100%;
  flex: 1;
  overflow: scroll;
  padding-bottom: 40px;
  line-height: 1.5;
}
.chatbot-container .message-type-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-height: 48px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #09112a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* subtle shadow */
  backdrop-filter: blur(15px); /* optional blur effect */
  -webkit-backdrop-filter: blur(3px); /* For Safari compatibility */
  padding: 16px;
}
.chatbot-container .hidden {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  position: absolute;
  top: 2px;
  left: 28px;
}
.chatbot-container .fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
  position: absolute;
  top: 2px;
  left: 28px;
}
.chatbot-container .message-controller {
  animation: fadeIn 0.2s ease-out forwards;
}
.chatbot-container .message-controller .lottie-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatbot-container .gr-input {
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  border: 0;
  border: 1px solid transparent;
  width: 100%;
  resize: none;
  height: 40px;
  border-bottom-left-radius: 12px;
}
.chatbot-container .gr-input:focus {
  border: 1px solid rgba(255, 255, 255, 0.15);
}

/* Message alignment adjustments */
.message-controller .user-message {
  text-align: right;
  line-height: 1.5;
}

.message-controller .assistant-message {
  text-align: left;
  line-height: 1.5;
}

/* Ensure loading icon container aligns with your chatbot styling */
.message-controller .loading-icon-container {
  display: flex;
  justify-content: center; /* Centers the loading icon horizontally */
  height: 24px; /* Fixed height to stabilize layout */
}

.about-me-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("../images/bg-highschool.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-columns: minmax(200px, 469px) minmax(100px, 1fr) 469px;
  padding: 86px 64px 24px 16px;
}
.about-me-container.warehouse {
  background-image: url("../images/bg-warehouse.jpg");
}
.about-me-container.manufacturing {
  background-image: url("../images/bg-manufacturing.jpg");
}
.about-me-container.business {
  background-image: url("../images/bg-business.jpg");
}
.about-me-container .card.about-me {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 84px);
  margin-bottom: 70px;
  overflow: hidden;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.2);
  /* glass2 */
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: var(--color-white);
  padding: 0;
}
.about-me-container .card.about-me > .card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  margin: 0;
  padding: 24px 24px 22px 24px;
}
.about-me-container .card.about-me > .card-header h1 {
  margin: 0;
}
.about-me-container .card.about-me .card-body {
  padding: 24px;
  overflow: auto;
  min-height: 0;
  height: 100%;
}
.about-me-container .card.about-me .card-body .group:first-of-type {
  margin-top: 0;
}
.about-me-container .card.about-me .card {
  border-radius: 16px;
  color: var(--color-white);
  max-width: 130px;
}
.about-me-container .card.about-me .card.badge-card {
  text-align: center;
  padding: 16px;
  flex: 1 0 0;
  min-width: 100px;
  min-height: 120px;
}
.about-me-container .card.about-me .card.badge-card .image, .about-me-container .card.about-me .card.badge-card p {
  display: none;
}
.about-me-container .card.about-me .card.badge-card .image {
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin: auto;
  margin-bottom: 8px;
}
.about-me-container .card.about-me .card.assessment-card {
  text-align: center;
  padding: 16px;
  flex: 1 0 0;
  min-width: 100px;
  min-height: 120px;
}
.about-me-container .card.about-me .card.assessment-card.locked .image {
  filter: grayscale(1);
}
.about-me-container .card.about-me .card.assessment-card.locked p {
  opacity: 0.5;
}
.about-me-container .card.about-me .card.assessment-card .image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 8px;
  position: relative;
}
.about-me-container .card.about-me .card.assessment-card .image .progress-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 50%;
  border: 8px solid white;
  border-color: transparent currentColor currentColor transparent;
  opacity: 0.5;
  transform: rotate(-45deg);
}
.about-me-container .card.about-me .card.assessment-card .card-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}
.about-me-container .card.about-me .career-cluster {
  display: block;
  position: relative;
  background: transparent;
  border: none;
  color: var(--color-white);
  padding: 0;
  border-radius: 16px;
  text-decoration: none;
}
.about-me-container .card.about-me .career-cluster .image {
  width: 80px;
  height: 80px;
  display: block;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  margin: auto;
  margin-bottom: 8px;
  overflow: hidden;
}
.about-me-container .card.about-me .career-cluster .image img {
  display: block;
  width: 60px;
  margin: 10px auto 0 auto;
}
.about-me-container .card.about-me .career-cluster .image.blank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  text-align: center;
}
.about-me-container .card.about-me .career-cluster .image.blank + .card-title {
  max-width: unset;
}
.about-me-container .card.about-me .career-cluster .image.CC1, .about-me-container .card.about-me .career-cluster .image.CC5, .about-me-container .card.about-me .career-cluster .image.CC9, .about-me-container .card.about-me .career-cluster .image.CC14 {
  background: var(--grad---blue, linear-gradient(149deg, #0CD6CB 20.97%, #597FFF 81.59%));
}
.about-me-container .card.about-me .career-cluster .image.CC4, .about-me-container .card.about-me .career-cluster .image.CC8, .about-me-container .card.about-me .career-cluster .image.CC12, .about-me-container .card.about-me .career-cluster .image.CC16 {
  background: var(--grad-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
}
.about-me-container .card.about-me .career-cluster .image.CC2, .about-me-container .card.about-me .career-cluster .image.CC6, .about-me-container .card.about-me .career-cluster .image.CC10, .about-me-container .card.about-me .career-cluster .image.CC13, .about-me-container .card.about-me .career-cluster .image.CC17 {
  background: var(--grad---pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));
}
.about-me-container .card.about-me .career-cluster .image.CC3, .about-me-container .card.about-me .career-cluster .image.CC7, .about-me-container .card.about-me .career-cluster .image.CC11, .about-me-container .card.about-me .career-cluster .image.CC15, .about-me-container .card.about-me .career-cluster .image.CC18 {
  background: var(--grad---yellow, linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%));
}
.about-me-container .card.about-me .career-cluster .card-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-white);
  text-overflow: ellipsis;
  max-width: 100px;
}
.about-me-container .card.about-me .chip-list, .about-me-container .card.about-me .badges-group, .about-me-container .card.about-me .assessment-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}
.about-me-container .card.about-me .chip-list.cluster-list, .about-me-container .card.about-me .badges-group.cluster-list, .about-me-container .card.about-me .assessment-group.cluster-list {
  gap: 16px;
  align-items: flex-start;
}
.about-me-container .card.about-me .group-header {
  margin-bottom: 24px;
  margin-top: 40px;
}
.about-me-container .card.about-me .group-header .link-btn {
  color: var(--color-white);
}
.about-me-container .avatar {
  margin: auto;
  min-height: 0;
  height: 100%;
  overflow: hidden;
}
.about-me-container .avatar img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: auto;
}
.about-me-container .avatar button {
  border-radius: 40px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  line-height: 35px;
  color: #fff;
  font-weight: bold;
  padding: 0 20px;
  position: absolute;
  top: 25%;
  left: 48%;
  z-index: 1;
  visibility: hidden;
}
.about-me-container .avatar:hover img {
  filter: drop-shadow(2px 2px 0 #D90E75) drop-shadow(-2px -2px 0 #D90E75) drop-shadow(0px 3px 0 #D90E75) drop-shadow(0px -2px 0 #D90E75);
}
.about-me-container .avatar:hover button {
  visibility: visible;
}

.progress-container {
  color: var(--color-white);
}
.progress-container .checklist {
  list-style: none;
}
.progress-container .checklist li {
  display: flex;
  align-items: center;
}
.progress-container .checklist li:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 2px;
  margin-right: 8px;
  text-align: center;
}
.progress-container .checklist .checked:before {
  content: "✓";
}
.progress-container .group {
  margin-bottom: 64px;
}
.progress-container .card .image {
  background: rgba(255, 255, 255, 0.1);
  min-height: 120px;
}
.progress-container .goals-card {
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.2);
  max-width: 420px;
  /* glass2 */
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  padding: 16px;
}

.skills-list {
  margin: 0;
  margin-top: 4px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.skills-list .skills-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  flex: 1 1 calc(50% - 4px);
}
.skills-list .skills-item .icon {
  width: 24px;
  height: 24px;
  flex: none;
  display: inline-block;
  margin-right: 8px;
  border-radius: 4px;
  vertical-align: middle;
}
.skills-list .skills-item .icon svg {
  width: 100%;
}

.tab-container .tab-list-container {
  position: relative;
  margin-bottom: -8px;
}
.tab-container .tab-list {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0 0 8px 0;
  overflow-x: auto;
}
.tab-container .tab-list li {
  display: inline-block;
}
.tab-container .tab-action {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: var(--color-black);
  cursor: pointer;
  margin: 0px 18px;
  padding: 6px 0;
  text-decoration: none;
  background: none;
  border: 0;
  border-bottom: 4px solid transparent;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.tab-container .tab-action .hidden-bold {
  font-weight: 700;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
@media speech {
  .tab-container .tab-action .hidden-bold {
    display: none;
  }
}
.tab-container .tab-action[aria-selected=true] {
  font-weight: 700;
  border-bottom: 4px solid transparent;
}
.tab-container .tab-action:hover {
  font-weight: 700;
}
@media (min-width: 808px) {
  .tab-container .tab-action {
    margin: 0px 36px;
  }
}
.tab-container .tab-item:first-of-type .tab-action {
  margin-left: 0;
}
.tab-container .tab-slider {
  position: absolute;
  height: 4px;
  width: auto;
  bottom: 8px;
  background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
  transition: left 0.3s ease, width 0.2s ease;
}
.tab-container.main-nav .tab-action {
  color: var(--color-white);
}
.tab-container.channels {
  background-color: #13203B;
}
.tab-container.channels .tab-list {
  border-bottom: 1px solid #535E74;
}
.tab-container.channels .tab-action {
  color: var(--color--ui-02);
}
.tab-container.channels .tab-slider {
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
.tab-container.pill .tab-slider {
  display: none;
}
.tab-container.pill .tab-list {
  background-color: rgba(83, 94, 116, 0.3019607843);
  border-radius: 30px;
  padding: 4px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
.tab-container.pill .tab-item button {
  margin: 0;
  padding: 5px 20px;
  font-size: 16px;
}
.tab-container.pill .tab-item.active button {
  background: #000;
  color: #fff;
  border-radius: 100px;
}

.course .tab-container.pill .tab-list {
  background: #182849;
  margin: 30px auto;
}
.course .tab-container.pill .tab-action {
  color: #fff;
}
.course .tab-container.pill .tab-item.active .tab-action {
  background: #fff;
  color: #000;
}

.footer-container {
  position: relative;
  margin-top: 32px;
}
.footer-container .footer-curve {
  bottom: 90%;
}
.footer-container .footer-curve.with-puppy {
  height: 350px;
}
.footer-container .footer-curve .puppy {
  position: absolute;
  width: 412px;
  height: 306px;
  bottom: 0px;
  right: 150px;
  background-image: url("https://www.pearson.com/jobmatch/assets/images/puppy.png");
}
@media (min-width: 834px) and (max-width: 1280px) and (orientation: landscape) {
  .footer-container .footer-curve .puppy {
    right: 112px;
  }
}
@media (max-width: 833px) {
  .footer-container .footer-curve .puppy {
    right: 10px;
  }
}

.page-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  font-size: 16px;
  line-height: 150%;
  padding: 40px calc(0.5 * var(--outside-padding));
  background: url("../images/D-footer-1280.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: right bottom;
  background-color: #05112A;
}
.page-footer ul li a {
  color: var(--color-white);
  text-decoration: none;
  font-weight: normal;
}
.page-footer ul {
  margin: 0;
  padding: 0;
}
.page-footer .css-1904l99 {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 21px;
  font-weight: normal;
  color: var(--color-white);
}
.page-footer .copyright {
  color: #9BA1AD;
}

.css-bxmrmw {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  margin-left: 8px;
}

.css-15pzk {
  display: inline-block;
  width: 52px;
  height: 52px;
  background: url("https://www.pearson.com/jobmatch/assets/images/app-icon.svg");
  vertical-align: middle;
}

.css-1c9jac0 {
  display: flex;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  flex-direction: row;
  justify-content: flex-start;
}

.css-o9b79t {
  list-style: none;
}

@media (min-width: 568px) {
  .page-footer {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .page-footer .css-1c9jac0 {
    justify-content: center;
  }
}
@media (min-width: 808px) {
  .page-footer {
    flex-direction: row;
    justify-content: center;
    gap: 50px;
  }
  .page-footer .css-1c9jac0 {
    gap: 50px;
  }
}
.accordion-container {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: calc(0.5 * var(--card-corner-default));
  margin-bottom: 1em;
  transition: box-shadow 0.125s ease-in-out;
}
.accordion-container .accordion-body {
  display: none;
  padding: 0 32px 32px 32px;
  text-align: left;
  font-size: 24px;
}
.accordion-container .accordion-btn[aria-expanded=true] {
  font-weight: bold;
}
.accordion-container .accordion-btn[aria-expanded=true] + .accordion-body {
  display: block;
}
.accordion-container .accordion-btn[aria-expanded=true] .icon-24 {
  transform: rotate(180deg);
}
.accordion-container.filter-accordion {
  box-shadow: none;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 0;
  border-radius: 0;
}
.accordion-container.filter-accordion:last-child {
  border-bottom: 0;
}
.accordion-container.filter-accordion .accordion-btn {
  font-size: 20px;
  padding: 8px 24px;
}
.accordion-container.filter-accordion .filter-amount {
  background: rgba(2, 9, 23, 0.1607843137);
  font-size: 0.825em;
  font-weight: 500;
  padding: 4px 8px;
  margin-left: 8px;
  border-radius: 4px;
}

.accordion-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 26px 32px 29.5px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  background: transparent;
  border: none;
  color: var(--color-black);
  text-align: left;
}
.accordion-btn .icon-24 {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  transition: transform 0.3s ease;
  text-align: center;
  flex: none;
}
.accordion-btn .icon-24 svg {
  vertical-align: middle;
}
.accordion-btn:hover .icon-24 {
  background: var(--color-light-200);
}

.update-container .accordion {
  font-size: 22px;
  line-height: 24px;
  padding: 8px 24px;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  margin: 10px 0;
}
.update-container .accordion .icon-18 {
  float: right;
  position: relative;
  top: 7px;
}
.update-container .right.label {
  text-align: right;
  color: var(--color-text-muted);
  margin-bottom: 8px;
}

.scroll-carousel {
  position: relative;
}
.scroll-carousel .carousel-nav .slider-nav-button {
  position: absolute;
  z-index: 11;
}
.scroll-carousel .carousel-nav .slider-nav-button:disabled {
  opacity: 0;
}
.scroll-carousel .carousel-nav .slider-nav-button.prev {
  left: -28px;
  top: 50%;
  transform: translateY(calc(-50% - 14px));
}
.scroll-carousel .carousel-nav .slider-nav-button.next {
  right: -28px;
  top: 50%;
  transform: translateY(calc(-50% - 14px));
}
.scroll-carousel .carousel-body {
  width: calc(100% + 32px);
  margin-right: -16px;
  margin-left: -16px;
  padding: 0 16px 8px 16px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  display: flex;
  gap: 8px;
}
.scroll-carousel .carousel-body .carousel-tile {
  scroll-snap-align: center;
  border-radius: 16px;
  padding: 24px;
  width: calc(100% - 0px);
  max-width: 368px;
  flex: none;
  border: 0;
  position: relative;
}
.scroll-carousel .carousel-body .carousel-tile .bookmark-btn {
  position: absolute;
  right: 16px;
  top: 16px;
}
.scroll-carousel .carousel-body .carousel-tile a {
  margin-bottom: 1em;
  text-decoration: none;
}
.scroll-carousel .carousel-body .carousel-tile .company-name {
  display: flex;
  align-items: center;
}
.scroll-carousel .carousel-body .carousel-tile .avatar {
  width: 40px;
  height: 40px;
  border: 1px solid #fefefe;
  background-color: #60646D;
  margin-right: 12px;
  display: inline-block;
  border-radius: 50%;
}
.scroll-carousel .carousel-body .carousel-tile h4 {
  margin: 1em 0;
}
.scroll-carousel .carousel-body .carousel-tile h4 + p {
  margin-top: 0;
  margin-bottom: 0;
}
.scroll-carousel .carousel-body .carousel-tile ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.scroll-carousel .carousel-body .carousel-tile ul p {
  margin: 0;
}
.scroll-carousel .carousel-body .carousel-tile .card-footer {
  border-top: 1px solid var(--color-border);
  padding-top: 16px;
  margin-top: 16px;
}
.scroll-carousel .carousel-body .mini-card {
  min-width: 343px;
  max-width: 343px;
}
.scroll-carousel .carousel-footer {
  margin-top: 16px;
}
.scroll-carousel .carousel-footer .dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.scroll-carousel .carousel-footer .dots .dot {
  display: block;
  width: 6px;
  height: 6px;
  background-color: #60646D;
  border-radius: 50%;
  margin: 1px;
}
.scroll-carousel .carousel-footer .dots .dot.active {
  width: 8px;
  height: 8px;
  background-color: var(--color-white);
  margin: 0;
}
@media (max-width: 457px) {
  .scroll-carousel .carousel-body .mini-card {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
}
@media (min-width: 769px) {
  .scroll-carousel .carousel-body {
    margin: 0;
    gap: 24px;
    width: 100%;
    padding: 0;
  }
}

.skill-details {
  text-align: left;
}
.skill-details > .tab-container {
  margin: 0px auto;
  padding: 16px;
}
.skill-details > .tab-container section {
  padding: 0;
}
.skill-details > .tab-container .tab-list {
  white-space: nowrap;
}
.skill-details .tab-header {
  margin-bottom: 16px;
}
.skill-details .tab-header .page-nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 8px;
}
.skill-details .tab-header .page-nav a {
  flex: none;
  color: inherit;
  height: 28px;
}
.skill-details .chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
}
.skill-details .chip-list.condensed {
  max-height: 35px;
  overflow: hidden;
}
.skill-details .chip-list + .link-btn {
  white-space: nowrap;
  float: right;
}
.skill-details .chip-btn {
  display: inline-block;
  border: 1px solid #dde3ee;
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-default);
  margin: 0;
  transition: background-color 0.15s ease-in-out;
}
.skill-details .chip-btn:hover {
  background: #CAA0FF;
}
.skill-details .chip-btn svg {
  vertical-align: text-bottom;
  margin-right: 4px;
}
.skill-details .chip-btn[aria-pressed=true] {
  padding-left: 14px;
  background: #CAA0FF;
}
.skill-details .chip-btn.removeable {
  display: inline-flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
}
.skill-details .chip-btn.removeable svg {
  margin: 0;
}
.skill-details .chip-btn.removeable .button:hover {
  background: rgba(255, 255, 255, 0.8);
}
@media (min-width: 769px) {
  .skill-details {
    background: transparent;
    color: var(--color-black);
  }
  .skill-details > .tab-container {
    padding: 0 var(--outside-padding);
  }
  .skill-details > .tab-container section {
    padding: 0;
  }
  .skill-details .tab-header {
    margin-bottom: 32px;
  }
}

.tabs-card {
  background: var(--bg-level1);
  border: 1px solid var(--color-border);
  border-radius: var(--card-corner-default);
  padding: 0;
  height: auto;
}
.tabs-card .tab-list-container {
  background: var(--bg-level0);
  border-top-left-radius: var(--card-corner-default);
  border-top-right-radius: var(--card-corner-default);
  padding: 16px 0 0 0;
  border-bottom: 1px solid var(--color-border);
  overflow-x: auto;
}
.tabs-card .tab-list-container .tab-list {
  margin-bottom: 0;
  padding-bottom: 0;
}
.tabs-card .tab-list-container .tab-action {
  margin: 0 16px;
  font-size: 18px;
}
@media (max-width: 807px) {
  .tabs-card .tab-list-container .tab-action.active:after {
    content: "";
    height: 4px;
    width: 100%;
    background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.tabs-card .tab-list-container .tab-slider {
  bottom: 0;
}
@media (max-width: 807px) {
  .tabs-card .tab-list-container .tab-slider {
    display: none;
  }
}
.tabs-card .tab-content {
  padding: var(--card-padding);
}
.tabs-card .tab-content .card-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 8px;
}
.tabs-card .tab-content .card {
  background: var(--bg-level0);
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.45);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1132;
}
.modal-overlay.hide {
  display: none;
}
.modal-overlay.transparent {
  background: transparent;
  pointer-events: none;
}
.modal-overlay.transparent .modal {
  pointer-events: auto;
}
.modal-overlay.transparent .career-modal {
  left: 0;
  top: 70px;
  transform: none;
}

.modal {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: #fff;
  margin: 0px auto;
  padding: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}
.modal.career-modal {
  max-width: 800px;
  width: calc(100% - 48px);
  margin: 24px;
  max-height: calc(100vh - 70px - 48px - 130px);
  padding: 24px 24px 24px 24px;
}
.modal.career-modal p {
  color: rgba(255, 255, 255, 0.8);
}
.modal.career-modal .right-col {
  padding-right: 40px;
}
.modal.light-bg {
  background-color: var(--bg-level0, #fefefe);
}
.modal .close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  color: #fff;
  background-color: transparent;
}
.modal h1 {
  text-align: left;
  margin: 0 0 8px 0;
}
.modal h2, .modal h3 {
  text-align: left;
  margin: 1rem 0 0 0;
}
.modal .cluster-image {
  display: flex;
  width: 250px;
  min-height: 250px;
  max-height: 500px;
  padding: 40px 24px 0 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 16px;
  height: 100%;
  position: relative;
}
.modal .cluster-image img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  height: 100%;
  width: calc(100% - 48px);
  position: absolute;
}
.modal .cluster-image.CC13 {
  padding-right: 0;
}
.modal .cluster-image.CC13 img {
  -o-object-position: top left;
     object-position: top left;
  width: calc(100% - 24px);
}
.modal p, .modal .desktop-h6 {
  margin: 0;
}
.modal .desktop-h6 {
  font-weight: 700;
}
.modal .modal-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.modal .modal-body .flex-row {
  gap: 16px;
}
.modal .modal-body h1, .modal .modal-body h2 {
  margin: 0 0 4px 0;
}
.modal .modal-body ul {
  margin: 0;
}
.modal .modal-body p + .desktop-h6 {
  margin-top: 16px;
}
.modal .modal-body .benefits {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  margin-top: 16px;
}
.modal .modal-body .benefits p + .desktop-h6 {
  margin-top: 24px;
}
.modal .modal-body .benefits .desktop-h6 {
  margin-bottom: 4px;
}
.modal .modal-body .right-col {
  display: flex;
  flex-direction: column;
}
.modal .course-description {
  padding-bottom: 32px;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 32px;
}
.modal .course-description .avatar {
  flex: none;
  width: 60px;
  height: 60px;
}
.modal .course-description .avatar img {
  width: 100%;
}
.modal .desc-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}
.modal .form-group + .form-group {
  margin-top: 1rem;
}

.chat-open .modal.career-modal {
  width: calc(100vw - 469px - 48px - 16px);
  margin-right: 485px;
  max-width: 800px;
  left: calc(50% - 252px);
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-header {
  padding-right: 24px;
  margin-bottom: 24px;
}
.modal-header.border-bottom {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 16px;
}
.modal-header.border-bottom h1 {
  margin-bottom: 0;
}
.modal-header h1.text-left {
  text-align: left;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.benefits h2 {
  margin-bottom: 0;
}
.benefits .icon {
  margin-right: 8px;
}

.benefits-row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
}
.benefits-row h3 {
  margin: 0;
}
.benefits-row .icon {
  color: var(--text-dark-theme-link);
}

.achievements-modal section {
  padding: 16px 0;
  margin-bottom: 0;
}

section {
  margin-bottom: 40px;
}
section:last-of-type {
  margin-bottom: 0;
}
section h2 .icon {
  vertical-align: middle;
  margin-right: 12px;
  display: inline-block;
  width: 31px;
  height: 31px;
}
section h2 .icon img {
  width: 100%;
}
section .card-row .card {
  min-width: 240px;
}
section .card-row .card.achievement-card {
  min-width: 130px;
  height: auto;
}
section .card.video-card .card-title {
  max-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

@media (min-width: 768px) {
  .modal {
    max-width: 600px;
  }
}
@media (max-width: 768px) {
  .modal {
    max-width: 440px;
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .modal {
    width: calc(100% - 40px);
  }
}
.toast {
  position: fixed;
  left: 50%;
  bottom: -400px;
  transform: translateX(calc(-50% - 24px));
  background: var(--background-dark-theme-bg-level-0, #020917);
  padding: 16px;
  border-radius: 16px;
  max-width: 480px;
  min-height: 56px;
  width: 100%;
  margin: 24px;
  color: var(--color-white);
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
  z-index: 11111;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 540px) {
  .toast {
    width: calc(100% - 32px);
  }
}
.toast > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding-right: 40px;
}
.toast > div > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  gap: 8px;
  justify-content: space-between;
}
.toast .icon {
  color: var(--color-success-500);
  height: 24px;
}
.toast .icon.info {
  color: var(--color-white);
}
.toast .text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.toast button.close {
  color: var(--color-white);
  position: absolute;
  right: 4px;
  top: 4px;
}
.toast .link-btn {
  color: var(--text-dark-theme-link, #9B81E2);
  line-height: 24px;
}
.toast.fadeIn {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  animation: fadein 0.5s;
  animation-fill-mode: forwards;
}
.toast.fadeOut {
  visibility: visible; /* Show the snackbar */
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

.tooltip-container.fadeIn {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  animation: fadein 0.5s;
  animation-fill-mode: forwards;
}
.tooltip-container.fadeOut {
  visibility: visible; /* Show the snackbar */
  animation: fadeout 0.5s;
  animation-fill-mode: forwards;
}

/* Animations to fade the snackbar in and out */
@keyframes fadein {
  from {
    bottom: -400px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 0px;
    opacity: 1;
  }
  to {
    bottom: -400px;
    opacity: 0;
  }
}
.start-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.start-page img {
  width: 100%;
  height: auto;
}

.seadragon-viewer {
  background-color: var(--color-dark-blue);
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-container .toolbar {
  position: absolute;
  z-index: 11;
  top: 86px;
  left: 16px;
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}
.map-container .toolbar button {
  width: 48px;
  height: 48px;
  padding: 12px;
  color: var(--color-white);
  background-color: rgba(254, 254, 254, 0.24);
  border-radius: 50%;
  opacity: 0.8;
}
.map-container .toolbar button:hover, .map-container .toolbar button:focus {
  background-color: rgba(254, 254, 254, 0.8);
  color: var(--text-default);
}

.drawer-btn {
  background: transparent;
  border: none;
  position: relative;
}
.drawer-btn img {
  width: 100%;
}
.drawer-btn:after {
  position: absolute;
  content: attr(aria-label);
  color: #fefefe;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 8px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
}
.drawer-btn.recommended {
  filter: drop-shadow(7px 4px 16px #FE42FF) drop-shadow(-6px -6px 13.9px #FD38FF);
}
.drawer-btn.recommended:after {
  color: var(--text-default);
  background-color: var(--color-white);
}
.drawer-btn[data-rating=interested] {
  filter: drop-shadow(7px 4px 16px #FE42FF) drop-shadow(-6px -6px 13.9px #FD38FF);
}
.drawer-btn[data-rating=interested].recommended:after {
  color: #fefefe;
  background-color: rgba(0, 0, 0, 0.4);
}
.drawer-btn[data-rating=notinterested] {
  filter: none;
}
.drawer-btn[data-rating=notinterested]:after {
  background-color: #020917;
  color: #989BA3;
}
.drawer-btn:hover img {
  filter: drop-shadow(0 2px 10px #FD38FF);
}
.drawer-btn:hover:after {
  color: #2E3192;
  background-color: rgba(255, 255, 255, 0.8);
}
.drawer-btn.cluster1:after {
  min-width: 120px;
  top: calc(100% - 0px);
}
.drawer-btn.cluster2:after {
  min-width: 110px;
}
.drawer-btn.cluster4:after {
  min-width: 110px;
}
.drawer-btn.cluster15:after {
  min-width: 130px;
}
.drawer-btn.cluster18:after {
  min-width: 110px;
}
.drawer-btn.cluster19:after {
  min-width: 140px;
}
.chatbot-container {
  position: relative;
}
.chatbot-container .close-btn {
  color: var(--color-white);
}
.chatbot-container .intro {
  display: flex;
}

.from-bot {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 24px;
  margin: 24px 60px 24px 24px;
}
.from-bot p {
  margin: 0;
}
.from-bot p + p {
  margin-top: 1em;
}
.from-bot p:not(:first-child) {
  margin-top: 1em;
}
.from-bot .assistant-message + .assistant-message {
  margin-top: 16px;
}

.from-you {
  background: #fefefe;
  color: var(--text-default);
  border-radius: 16px;
  padding: 24px;
  margin: 24px 24px 24px auto;
  width: -moz-fit-content;
  width: fit-content;
  max-width: calc(100% - 60px);
}
.from-you p:only-child {
  margin: 0;
}

main.onboarding {
  grid-template-columns: 1fr;
  overflow: auto;
  padding-top: 80px;
}

.onboarding-container {
  background: var(--purple-gradient, linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0) 55.93%), #05112A);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.onboarding-container.with-character::after {
  content: "";
  height: calc(100% - 100px);
  width: 70%;
  background-image: url("../images/onboarding_character.png");
  background-size: 45vw;
  position: absolute;
  right: 0;
  bottom: 0;
  background-position: right bottom;
  background-repeat: no-repeat;
}
.onboarding-container .onboarding-content {
  max-width: 760px;
  width: 100%;
  margin: 20px;
  color: var(--color-white);
  z-index: 1;
  text-align: center;
}
.onboarding-container .onboarding-content .onboarding-assessment-header {
  margin-bottom: 24px;
}
.onboarding-container .onboarding-content .onboarding-assessment-header h1 {
  margin: 0 auto;
}
.onboarding-container .onboarding-content .onboarding-assessment-header .subtitle {
  color: #989BA3;
}
.onboarding-container .onboarding-content fieldset {
  max-width: 640px;
  margin: 0 auto;
  color: var(--color-white);
}
.onboarding-container .onboarding-content fieldset legend {
  margin-bottom: 24px;
}
.onboarding-container .onboarding-content fieldset legend span {
  display: block;
  text-align: center;
  color: var(--color-white);
}
.onboarding-container .onboarding-content fieldset legend span + span {
  margin-top: 4px;
}
.onboarding-container .onboarding-content h1, .onboarding-container .onboarding-content h2 {
  margin: 0 auto 8px auto;
  text-align: center;
  max-width: 440px;
  width: 100%;
}
.onboarding-container .onboarding-content p {
  margin: 0 auto;
  width: 100%;
  max-width: 440px;
  font-size: 1.125rem;
  line-height: 1.3;
  text-align: center;
}
.onboarding-container .onboarding-content a {
  text-align: center;
  color: var(--text-dark-theme-link);
  font-size: 1.125rem;
  font-weight: normal;
}
.onboarding-container .onboarding-content .assessment-reminder {
  margin: 60px auto;
}
.onboarding-container .onboarding-content .school-id {
  margin: auto;
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 440px;
  color: var(--text-default);
  border-radius: 24px;
  background: linear-gradient(71deg, #DEEAFF 0.45%, #F1E8FF 99.05%);
  text-align: left;
  /* frosted-glass */
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
}
.onboarding-container .onboarding-content .school-id .image {
  max-width: 135px;
}
.onboarding-container .onboarding-content .school-id .image img {
  width: 100%;
  height: auto;
}
.onboarding-container .onboarding-content .school-id h2, .onboarding-container .onboarding-content .school-id p, .onboarding-container .onboarding-content .school-id h3 {
  margin: 0 0 4px 0;
  text-align: left;
}
.onboarding-container .onboarding-content .school-id .group {
  margin-top: 12px;
}
.onboarding-container .onboarding-content .school-id .group-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.24px;
}
.onboarding-container .onboarding-content .school-id p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.24px;
}
.onboarding-container .onboarding-content .cluster-row {
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  margin: 48px auto;
}
.onboarding-container .onboarding-content .career-cluster {
  display: block;
  position: relative;
  background: transparent;
  border: none;
  color: var(--color-white);
  padding: 0;
  border-radius: 16px;
  width: 200px;
}
.onboarding-container .onboarding-content .career-cluster .image {
  width: 200px;
  height: 200px;
  display: block;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 28px;
  margin: auto;
  margin-bottom: 12px;
  overflow: hidden;
}
.onboarding-container .onboarding-content .career-cluster .image img {
  display: block;
  width: 180px;
  margin: 10px auto 0 auto;
}
.onboarding-container .onboarding-content .career-cluster .image.CC1, .onboarding-container .onboarding-content .career-cluster .image.CC5, .onboarding-container .onboarding-content .career-cluster .image.CC9, .onboarding-container .onboarding-content .career-cluster .image.CC14 {
  background: var(--grad---blue, linear-gradient(149deg, #0CD6CB 20.97%, #597FFF 81.59%));
}
.onboarding-container .onboarding-content .career-cluster .image.CC4, .onboarding-container .onboarding-content .career-cluster .image.CC8, .onboarding-container .onboarding-content .career-cluster .image.CC12, .onboarding-container .onboarding-content .career-cluster .image.CC16 {
  background: var(--grad-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
}
.onboarding-container .onboarding-content .career-cluster .image.CC2, .onboarding-container .onboarding-content .career-cluster .image.CC6, .onboarding-container .onboarding-content .career-cluster .image.CC10, .onboarding-container .onboarding-content .career-cluster .image.CC13, .onboarding-container .onboarding-content .career-cluster .image.CC17 {
  background: var(--grad---pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));
}
.onboarding-container .onboarding-content .career-cluster .image.CC3, .onboarding-container .onboarding-content .career-cluster .image.CC7, .onboarding-container .onboarding-content .career-cluster .image.CC11, .onboarding-container .onboarding-content .career-cluster .image.CC15, .onboarding-container .onboarding-content .career-cluster .image.CC18 {
  background: var(--grad---yellow, linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%));
}
.onboarding-container .onboarding-content .career-cluster.interested:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/rating-interested.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 12px;
  top: 12px;
}
.onboarding-container .onboarding-content .career-cluster.notinterested:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/rating-notinterested.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 12px;
  top: 12px;
}
.onboarding-container .onboarding-content .career-cluster .card-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-white);
}
.onboarding-container .onboarding-content .progress-dots {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
}
.onboarding-container .onboarding-content .progress-dots .dot {
  width: 6px;
  height: 6px;
  background-color: #989BA3;
  border-radius: 50%;
}
.onboarding-container .onboarding-content .progress-dots .dot.filled {
  background-color: #EA0B7E;
  outline: 1px solid #EA0B7E;
}
.onboarding-container .onboarding-content .chip-list {
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 40px;
}
.onboarding-container .onboarding-content .chip-list .chip-btn.toggle-chip {
  font-size: 1rem;
  margin: 4px;
}
.onboarding-container .onboarding-content .action-row {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.onboarding-container .onboarding-content .action-row button.outline {
  color: var(--color-white);
  outline-color: rgba(254, 254, 254, 0.2509803922);
}
.onboarding-container .onboarding-content .action-row button.outline:not(:disabled):hover {
  background: var(--color-white, #fefefe);
  color: var(--swatch-dark-700, #393F4A);
}
.onboarding-container .onboarding-content .action-row button.outline:not(:disabled):hover.outline {
  outline: 2px solid var(--color-white, #fefefe);
}
.onboarding-container .onboarding-content .bot-image {
  display: block;
  width: 140px;
  height: 140px;
  margin: auto;
  margin-bottom: 24px;
  position: relative;
}

.bot-image {
  display: block;
  margin: auto;
  position: relative;
}
.bot-image img {
  width: 100%;
}
.bot-image .loading {
  display: block;
  width: 130px;
  height: 130px;
  position: absolute;
  left: 2px;
  top: 2px;
}
.bot-image .loading:after {
  content: " ";
  display: block;
  width: 104px;
  height: 104px;
  margin: 8px;
  border-radius: 50%;
  opacity: 0.5;
  mix-blend-mode: luminosity;
  filter: blur(2px);
  border: 8px solid currentColor;
  border-color: currentColor transparent transparent transparent;
  animation: lds-dual-ring 3s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

.icon-16 {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.icon-18 {
  width: 18px;
  height: 18px;
  fill: currentColor;
}

.icon-24 {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

:root {
  --card-padding: 32px;
  --card-gap: 32px;
  --column-gap: 24px;
  --outside-padding: 16px;
  --vertical-space: 32px;
  --card-padding-skills: 4px;
  --card-padding-mini: 12px 20px;
}

@media (max-width: 413px) {
  :root {
    --outside-padding: 16px;
    --card-padding-skills: 4px;
  }
}
@media (min-width: 808px) {
  :root {
    --outside-padding: 32px;
    --card-padding-skills: 8px;
  }
}
@media (min-width: 1200px) {
  :root {
    --outside-padding: 64px;
  }
}/*# sourceMappingURL=styles.css.map */