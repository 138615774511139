body {
    background-color: var(--bg-level2);
    // background-color: var(--bg-level1);
    margin:0;
    padding:0;
}
.page-header {
    position:fixed;
    left:0;
    right:0;
    top:0;
    z-index: 1111;
}
.page-wrapper {
    --chatWidth: 469px;
    main {
        width:100%;
        height:calc(100vh);
        overflow:hidden;
        display:grid;
        grid-template-columns:  1fr;
        background: var(--purple-gradient, linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0.00) 55.93%), #05112A);

        .content-container {
            position:relative;
            width:100%;
            height:100%;
            overflow:hidden;

            .floating-nav {
                position:absolute;
                left:24px;
                bottom:24px;
                display:flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                gap:12px;
                z-index: 111;
                width:100%;
                padding-right:160px;

                .game-actions {
                    display:flex;
                    flex-direction: row;
                    align-items: center;
                    gap:12px;
                    position:relative;
                    
                    .nav-indicator {
                        position:absolute;
                        top:-12px;
                        transition:all 0.3s ease-in-out;
                        left:25%;
                        
                        width:15px;
                        height:9px;
                        z-index: 3;
                        img {display:block;}
                        &.profile {
                            left:25%;
                            transform:translateX(-10px);
                            // transform:translateX(25%);
                        }
                        &.map {
                            left:75%;
                            transform:translateX(-5px);
                            // transform:translateX(75%);
                        }
                    }
                }
            }
            .achievements-nav-container {
                position: relative;
                border-radius: 16px;
                background: var(--Transparent-40, rgba(0, 0, 0, 0.40));
                padding: 12px;
                display:flex;
                flex-direction: row;
                color:var(--color-white);
                min-height: 114px;
                h3 {
                    margin:0 0 8px 0;
                    text-align: center;
                }
                .achievements-btn {
                    h3 {
                        margin-bottom:12px;
                    }
                }
                .quest-container {
                    border-left:1px solid var(--color-white);
                    padding-left: 12px;
                    margin-left:12px;
                    min-width: 88px;

                    .quest-list {
                        list-style: none;
                        margin:0;
                        padding:0;
                        display:flex;
                        flex-direction: row;
                        justify-content: center;
                        
                    }
                }
                .tooltip-container {
                    position:absolute;
                    top:-56px;
                    right:0;

                    .tooltip {
                        border-radius: 16px;
                        border: 2px solid rgba(255, 255, 255, 0.25);
                        background: var(--Transparent-fill, rgba(0, 0, 0, 0.25));
                        padding: 12px 24px;
                        /* frosted-glass */
                        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
                        backdrop-filter: blur(50px);
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 20px; /* 125% */
                        letter-spacing: 0.24px;
                        color:var(--color-white);
                        width: max-content;
                    }
                }
            }

            .tab-panel {
                width:100%;
                height:100%;
                overflow-y:auto;
                h1 {
                    margin-top:0;
                }

                &.hidden {
                    display:none;
                }
                &:not(.map) {
                    // padding: 70px 64px 24px 64px;
                }
            }
            .map {
                width:100%;
            height:100%;
            overflow:hidden;
            }
            .floating-tabs {
                position:absolute;
                left:24px;
                top:24px;
                z-index: 111;

                .tab-list-container {
                    display:flex;
                    flex-direction:row;
                    list-style: none;
                    margin:0;
                    padding:0;
                    button {
                        height:36px;
                        padding: 8px 24px;
                        background:white;
                        border:1px solid var(--color-dark-600);

                        
                    }
                    li {
                        &:first-of-type button{
                            border-radius: 15px 0 0 15px;
                        }
                        &:last-of-type button {
                            border-radius: 0 15px 15px 0;
                        }
                    }
                }
            }
        }
        .chatbot-container {
            position:absolute;
            right:16px;
            bottom:16px;
            z-index: 10000;
        }
        .group {
            margin-top:24px;
            .group-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
                letter-spacing: 0.24px;
            }
        }

        
       
    }
   &.chat-open {
    .floating-nav {
        padding-right:calc(56px + var(--chatWidth)) !important;
    }
   }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.fade-in {
    animation:0.4s ease-in-out fadeIn;
}

.chatbot-container {
    overflow: hidden;
    display:flex;
    flex-direction:column;
    height:100%;
    max-height:calc(100vh - 70px - 32px);
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: var(--purple-gradient, linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0.00) 55.93%), #05112A);
    color:var(--color-white);
    max-width:var(--chatWidth);
    width:100%;
    animation: fadeIn 0.2s ease-out forwards;
    z-index: 12000;
    .chat-area {
        width:100%;
        flex:1;
        overflow:scroll;
        padding-bottom:40px;
        line-height:1.5;
    }
    .message-type-area {
        display:flex;
        flex-direction: row;
        align-items: center;
        gap:8px;
        min-height:48px;
        border-top:1px solid rgba(255, 255, 255, 0.25);
        position: absolute;
        bottom:0;
        right:0;
        width:100%;
        background-color:#09112a;
        box-shadow: 0 4px 6px rgba(0,0,0,0.2); /* subtle shadow */
        backdrop-filter: blur(15px); /* optional blur effect */
        -webkit-backdrop-filter: blur(3px); /* For Safari compatibility */
        padding:16px;
        // button {
        //     display:flex;
        //     align-items: center;
        //     justify-content: center;
        //     padding:0 15px;
        // }
    }
    .hidden {
        opacity: 0;
        transition: opacity 0.5s ease-out;
        position: absolute;
        top: 2px;
        left: 28px;
    }

    .fade-in {
        opacity: 1;
        transition: opacity 0.5s ease-in;
        position: absolute;
        top: 2px;
        left: 28px;
    }

    .message-controller {
        animation: fadeIn 0.2s ease-out forwards;
        .lottie-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            > div {
                //width: 220px;
                //height: 20px;
                //justify-self: flex-end;
                //right: 0;
                //position:absolute;
            }
        }
    }

    .gr-input {
        // background: transparent;
        background: rgba(0,0,0,0.5);
        margin: 0;
        border: 0;
        // padding-left: 30px;
        // padding-top:5px;
        // border-radius: 0 0 0 24px;
        border:1px solid transparent;
        width: 100%;
        resize:none;
        height:40px;
        // height: 100%;
        border-bottom-left-radius: 12px;
        &:focus {
            border:1px solid rgba(255,255,255,0.15);
            // border-radius: 0 0 0 24px;
            // box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* stronger shadow on focus for better contrast */
        }
    }
}

/* Message alignment adjustments */
.message-controller .user-message {
    text-align: right;
    // padding: 0 40px; /* Ensures padding within the flex container */
    line-height:1.5;
}

.message-controller .assistant-message {
    text-align: left;
    // padding: 0 40px; /* Ensures padding within the flex container */
    line-height:1.5;
}

/* Ensure loading icon container aligns with your chatbot styling */
.message-controller .loading-icon-container {
    display: flex;
    justify-content: center; /* Centers the loading icon horizontally */
    height: 24px; /* Fixed height to stabilize layout */
}


.about-me-container {
    width: 100%;
    height:100%;
    position: relative;
    background-image:url('../images/bg-highschool.jpg');
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    display: grid;
    grid-template-columns: minmax(200px, 469px) minmax(100px,1fr) 469px;
    padding: 86px 64px 24px 16px;

    &.warehouse {
        background-image:url('../images/bg-warehouse.jpg');
    }
    &.manufacturing {
        background-image:url('../images/bg-manufacturing.jpg');
    }
    &.business {
        background-image:url('../images/bg-business.jpg');
    }

    .card.about-me {
        display:flex;
        flex-direction: column;
        max-height:calc(100% - 84px);
        margin-bottom:70px;
        overflow:hidden;
        border-radius: 24px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        background: rgba(0, 0, 0, 0.20);

        /* glass2 */
        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
        backdrop-filter: blur(50px);
        color:var(--color-white);
        padding:0;
        > .card-header {
            border-bottom:1px solid rgba(255, 255, 255, 0.25);
            // padding-top:24px;
            // padding-bottom:8px;
            margin:0;
            padding: 24px 24px 22px 24px;
            // position:sticky;
            // top:0;
            h1 {margin:0;}
        }
        .card-body {
            padding:24px;
            overflow:auto;
            min-height: 0;
            height:100%;
            .group {
                &:first-of-type {
                    margin-top:0;
                }
            }
        }
        .card {
            border-radius: 16px;
            // border: 1px solid #000;
            // background: rgba(255, 255, 255, 0.10);
            color:var(--color-white);
            max-width:130px;

            // .card-title {
            //     font-size: 14px;
            //     font-style: normal;
            //     font-weight: 400;
            //     line-height: 16px;
            //     color:var(--color-white);
            //     text-overflow: ellipsis;
            //     max-width: 70px;
            //     white-space: nowrap;
            //     overflow: auto;
            // }
            
            &.badge-card {
                text-align:center;
                padding:16px;
                flex:1 0 0;
                min-width:100px;
                min-height:120px;
                .image, p {display:none}

                .image {
                    width:60px;
                    height:60px;
                    background: rgba(255, 255, 255, 0.10); //placeholder
                    border-radius:50%;
                    margin:auto;
                    margin-bottom:8px;
                }
            }
            &.assessment-card {
                text-align:center;
                padding:16px;
                flex:1 0 0;
                min-width:100px;
                min-height:120px;
                // .image, p {display:none}
                &.locked {
                    .image {
                        filter: grayscale(1);
                    }
                    p {opacity: 0.5;}
                }
                .image {
                    width:64px;
                    height:64px;
                    // background: rgba(255, 255, 255, 0.10); //placeholder
                    border-radius:50%;
                    margin:auto;
                    margin-bottom:8px;
                    position:relative;

                    .progress-circle {
                        width:100%;
                        height:100%;
                        position:absolute;
                        left:0;
                        right:0;
                        border-radius:50%;
                        border: 8px solid white;
                        border-color: transparent currentColor currentColor transparent;
                        opacity: 0.5;
                        transform: rotate(-45deg);
                    }
                }
                .card-title {
                    font-size:14px;
                    line-height:16px;
                    font-weight:normal;
                }
            }
            
        }
        .career-cluster {
            display:block;
            position: relative;
            background:transparent;
            border:none;
            color:var(--color-white);
            padding:0;
            border-radius: 16px;
            text-decoration:none;
            .image {
                width:80px;
                height:80px;
                display:block;
                background: rgba(255, 255, 255, 0.15); 
                border-radius: 12px;
                margin:auto;
                margin-bottom:8px;
                overflow:hidden;
                img {
                    display:block;
                    width: 60px;
                    margin:10px auto 0 auto;
                }
                &.blank {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding:4px;
                    text-align:center;
                    // background: var(--grad-pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));

                    + .card-title {
                        max-width:unset;
                    }
                }

                &.CC1, &.CC5, &.CC9, &.CC14 {
                    background: var(--grad---blue, linear-gradient(149deg, #0CD6CB 20.97%, #597FFF 81.59%));
                }
                &.CC4, &.CC8, &.CC12, &.CC16 {
                    background: var(--grad-teal, linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%));
                }
                &.CC2, &.CC6, &.CC10, &.CC13, &.CC17 {
                    background: var(--grad---pink, linear-gradient(90deg, #EC008C 0%, #FC6767 100%));
                }
                &.CC3, &.CC7, &.CC11, &.CC15, &.CC18 {
                    background: var(--grad---yellow, linear-gradient(135deg, #FBDA61 2.88%, #F76B1C 98.13%));
                }
                &.CC13 {
                    //manufacturing
                    img {

                    }
                }
               
                
            }
            .card-title {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                color:var(--color-white);
                text-overflow: ellipsis;
                max-width: 100px;
                // white-space: nowrap;
                // overflow: auto;
            }
        }
        .chip-list, .badges-group, .assessment-group {
            display:flex;
            flex-direction: row;
            flex-wrap:wrap;
            gap:8px;
            margin-top:8px;
            &.cluster-list {
                gap:16px;
                align-items:flex-start
            }
           
        }
        .group-header {
            margin-bottom:24px;
            margin-top:40px;

            .link-btn{
                color:var(--color-white);
            }
        }
    }
    .avatar {
        margin:auto;
        min-height:0;
        height:100%;
        overflow: hidden;
        img {
            object-fit: contain;
            height: 100%;
            width:auto;
        }
        button {
            border-radius: 40px;
            border: 2px solid rgba(255, 255, 255, 0.25);
            background: rgba(0, 0, 0, 0.25);
            box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
            backdrop-filter: blur(5px);
            line-height: 35px;
            color: #fff;
            font-weight: bold;
            padding: 0 20px;
            position: absolute;
            top: 25%;
            left: 48%;
            z-index: 1;
            visibility: hidden;
        }
        &:hover {
            img {
                filter: drop-shadow(2px 2px 0 #D90E75) drop-shadow(-2px -2px 0 #D90E75) drop-shadow(0px 3px 0 #D90E75) drop-shadow(0px -2px 0 #D90E75);
            }
            button {
                visibility: visible;
            }
        }
    }
    
}
.progress-container {
    // padding: 94px 64px 24px 64px;
    color: var(--color-white);

    .checklist {
        list-style: none;
        li {
            display:flex;
            align-items: center;
            &:before {
                content:'';
                width:18px;
                height:18px;
                border:1px solid rgba(255,255,255,0.7);
                border-radius:2px;
                margin-right:8px;
                text-align:center;
            }
        }
        .checked {
            &:before {
                content: '✓';
                
              }
        }
    }
    .group {
        margin-bottom:64px;
    }

    //placehoder until images go in
    .card {
        
        .image {
            background: rgba(255, 255, 255, 0.10); 
            min-height:120px;
        }
    }

    .goals-card {
        border-radius: 24px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        background: rgba(0, 0, 0, 0.20);
        max-width: 420px;
        /* glass2 */
        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
        backdrop-filter: blur(50px);
        padding:16px;
    }
}
.skills-list {
    // columns: 2;
    margin:0;
    margin-top:4px;
    padding:0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:8px;
    .skills-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height:24px;
        font-size: 16px;
        font-weight: 500;
        // width:40%;
        flex: 1 1 calc(50% - 4px);
        // padding: 4px 0;
        // white-space: nowrap;
        .icon {
            width:24px;
            height:24px;
            flex:none;
            display:inline-block;
            margin-right:8px;
            border-radius:4px;
            vertical-align: middle;
            svg {
                width:100%;
            }
        }
    }
}