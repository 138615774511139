.chatbot-container {
  position:relative;
  .close-btn {

    color:var(--color-white);
  }
  .intro {
    display:flex;
  }
}

.from-bot {
  background:rgba(255,255,255,0.05);
  border-radius:16px;
  padding:24px;
  margin: 24px 60px 24px 24px;

  p {
    margin:0;
    + p {
      margin-top:1em;
    }
  }
  p:not(:first-child) {
    margin-top:1em;
  }
  .assistant-message {

    + .assistant-message {
      margin-top:16px;
    }
  }
}
.from-you {
  background:#fefefe;
  color:var(--text-default);
  border-radius:16px;
  padding:24px;
  margin: 24px 24px 24px auto;
  width:fit-content;
  max-width:calc(100% - 60px);
  .user-message {
    
  }
  p:only-child {
    margin:0;
  }
}